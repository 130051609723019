import { Box, Checkbox, FormControl, InputBase, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tabs, Tooltip, alpha, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { DateFormatChange, StyledTableCell, StyledTableRow, getComparator, stableSort } from "../../Constants/Utils";
import { EventListType, useGetEventsQuery, useGetFormTemplateByIdQuery } from "../../Redux/Services/Events";
import "../RegisterInfo/Info.css";
import "../Events/Event.css"
import { Link, useLocation, useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useGetRolesQuery, useGetUsersQuery } from "../../Redux/Services/Usermanagement";
import DeleteIcon from '@mui/icons-material/Delete';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}



function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const headCells = [
    {
        id: "s_no",
        label: "S.No",
    },
    {
        id: "first_name",
        label: "First Name",
    },
    {
        id: "last_name",
        label: "Last Name",
    },
    {
        id: "email",
        label: "Email",
    },
    {
        id: "phone_number",
        label: "Phone Number",
    },
    {
        id: "role",
        label: "Role",
    },
    {
        id: "action",
        label: "Action",
    },
];


const headCellsRoles = [
    {
        id: "s_no",
        label: "S.No",
    },
    {
        id: "role_name",
        label: "Role Name",
    },
    {
        id: "capabilities",
        label: "Capabilities",
    },
    {
        id: "display_fields",
        label: "Display Fields",
    },
    {
        id: "action",
        label: "Action",
    },
];

function EnhancedTableHead(props: any, currentTab: any) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: string) => (event: any) => {
        onRequestSort(event, property);
    };

    const [header, setHeader] = useState(headCells);
    console.log("mmmmmmmmmmmmmmmmccccc", currentTab);


    return (
        <TableHead className="infoTableHead">
            <StyledTableRow>
                {header?.map((headCell: any, index: any) => {
                    return (
                        <StyledTableCell
                            key={index}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                <p className="infotable-headText">{headCell.label}</p>
                            </TableSortLabel>
                        </StyledTableCell>
                    );
                })}
            </StyledTableRow>
        </TableHead>
    );
}
function EnhancedTableHeadRoles(props: any, currentTab: any) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: string) => (event: any) => {
        onRequestSort(event, property);
    };

    const [header, setHeader] = useState(headCellsRoles);
    console.log("mmmmmmmmmmmmmmmmccccc", currentTab);


    return (
        <TableHead className="infoTableHead">
            <StyledTableRow>
                {header?.map((headCell: any, index: any) => {
                    return (
                        <StyledTableCell
                            key={index}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                <p className="infotable-headText">{headCell.label}</p>
                            </TableSortLabel>
                        </StyledTableCell>
                    );
                })}
            </StyledTableRow>
        </TableHead>
    );
}




const UserManagement = () => {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("first_name");
    const [searchArray, setSearchArray] = useState<EventListType[]>([]);
    const [searchArrayUser, setSearchArrayUser] = useState([]);
    const handleRequestSort = (
        event: any,
        property: React.SetStateAction<string>
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const navigate = useNavigate();
    const location = useLocation();
    const [value, setValue] = React.useState(location?.state?.value ?? 0);
    const formId = location?.pathname?.split("/")[2] ?? ""
    const { data: getRoles } = useGetRolesQuery(formId, {
        skip: value === 1
    })

    const { data: getUsers } = useGetUsersQuery(formId, {
        skip: value === 0
    })



    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);

    };

    useEffect(() => {
        setSearchArray(getRoles)
    }, [getRoles])

    useEffect(() => {
        setSearchArrayUser(getUsers)
    }, [getUsers])



    return (
        <div className="">
            <div className="container table">
                {/* <button className="createBtn mt-3" onClick={() => navigate("/create-event")}>Create Event +</button> */}

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Manage Roles" {...a11yProps(0)} />
                        <Tab label="Manage Users" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={1}>
                        <button className="createBtn mt-3 me-2" onClick={() => navigate(`/user-management/${formId}/add`, { state: { value: value }})}>Add user +</button>

                        <TableContainer className="">
                            <Table
                                className={"mt-4 table"}
                                aria-labelledby="tableTitle"
                                size={"medium"}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={""}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={searchArrayUser?.length}
                                    currentTab={value}
                                />
                                <TableBody>
                                    {
                                        searchArrayUser?.map((row: any, index: any) => {

                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <StyledTableRow hover key={index}>
                                                    <StyledTableCell
                                                        component="th"
                                                        id={labelId}
                                                        key={index}
                                                        scope="row"
                                                    >
                                                        {index + 1}
                                                    </StyledTableCell>
                                                    <StyledTableCell className="cursor-pointer">{row?.first_name}</StyledTableCell>
                                                    <StyledTableCell className="cursor-pointer">{row?.last_name}</StyledTableCell>
                                                    <StyledTableCell className="cursor-pointer">{row?.email}</StyledTableCell>
                                                    <StyledTableCell className="cursor-pointer">{row?.phone_number}</StyledTableCell>
                                                    <StyledTableCell className="cursor-pointer">{row?.role}</StyledTableCell>

                                                    <StyledTableCell>
                                                        <Tooltip title="Edit">
                                                            <EditIcon onClick={() => navigate(`/edit-event/${row?.id}`, { state: { row } })} className="updatebutton" />
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <DeleteIcon className="updatebutton" />
                                                        </Tooltip>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={0}>
                        <button className="createBtn mt-3 me-2" onClick={() => navigate(`/user-management/${formId}/addRoles`, { state: { value: value } })}>Add Roles +</button>

                        <TableContainer className="">
                            <Table
                                className={"mt-4 table"}
                                aria-labelledby="tableTitle"
                                size={"medium"}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHeadRoles
                                    classes={""}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={searchArray?.length}
                                    currentTab={value}

                                />
                                <TableBody>
                                    {
                                        searchArray?.map((row: any, index: any) => {

                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <StyledTableRow hover key={index}>
                                                    <StyledTableCell
                                                        component="th"
                                                        id={labelId}
                                                        key={index}
                                                        scope="row"
                                                    >
                                                        {index + 1}
                                                    </StyledTableCell>
                                                    <StyledTableCell className="cursor-pointer">{row?.role_name}</StyledTableCell>
                                                    <StyledTableCell className="cursor-pointer">
                                                        <div className="d-flex gap-3">
                                                            <label>Checkin : {row?.checkin ? "Yes" : "No"}</label> {" | "}
                                                            <label>Print : {row?.print ? "Yes" : "No"}</label> {" | "}
                                                            <label>Spot Register : {row?.spot_register ? "Yes" : "No"}</label>
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell className="cursor-pointer">{row?.fields?.map((res: any) => res)},</StyledTableCell>


                                                    <StyledTableCell>
                                                        <Tooltip title="Edit">
                                                            <EditIcon onClick={() => navigate(`/user-management/${formId}/editRoles`, {
                                                                state:
                                                                {
                                                                    value: value,
                                                                    edit: true
                                                                }
                                                            })} className="updatebutton" />
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <DeleteIcon className="updatebutton" />
                                                        </Tooltip>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CustomTabPanel>
                </Box>



            </div>

        </div>
    );
};

export default UserManagement;