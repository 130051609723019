import { useMemo, useEffect, useState, useCallback, Fragment } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
} from "react-table";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Icon from "@/Components/ui/Icon";
import Card from '@/Components/ui/Card';
import { Dialog, Transition } from "@headlessui/react";
import Button from "@/Components/ui/Button";

import { EventListType, useGetEventsQuery, useGetFormTemplateByIdQuery, useDeleteEventsByIdMutation } from "@/Redux/Services/Events";
import { Tooltip } from "@mui/material";



const Events = () => {

  const COLUMNS = [
    {
      Header: "Event Name",
      accessor: "name",
      Cell: (row: any) => {
        return <Tooltip arrow title={row?.cell?.value || '-'}><div style={{ maxWidth: '200px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{row?.cell?.value || '-'}</div></Tooltip>;
      },
      disableSortBy: false

    },
    {
      Header: "Description",
      accessor: "description",
      Cell: (row: any) => {
        return <Tooltip arrow title={row?.cell?.value.replace(/<[^>]+>/g, '') || '-'}><div style={{ maxWidth: '200px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{row?.cell?.value.replace(/<[^>]+>/g, '') || '-'}</div></Tooltip>;
      },
      disableSortBy: true
    },
    {
      Header: "Time",
      accessor: "start_time",
      Cell: (row: any) => {
        let formattedDate: any = ""
        if (row?.cell?.value) {
          let date = new Date(row?.cell?.value)
          formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          }) + ' - ' + date.toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          });
        }
        return <span>{formattedDate || '-'}</span>;
      },
      sortType: (rowA, rowB, columnId, desc) => {
        const dateA = rowA.values.start_time;
        const dateB = rowB.values.start_time;

        if (dateA > dateB)
          return 1
        if (dateB > dateA)
          return -1

        return 0
      },
      disableSortBy: false
    },
    {
      Header: "Actions",
      accessor: "id",
      id: "actionId",
      Cell: (row: any) => {
        return <Button className="btn-danger h-10 w-10 inline-flex items-center justify-center text-white" icon="icon-park-outline:delete" text={undefined} isLoading={undefined} disabled={undefined} link={undefined} onClick={(e) => {
          e.stopPropagation();
          console.log('Delete Event iD', row.cell.value)

          openModal(row.cell.value)

        }} div={undefined} children={undefined} />;
      },
      disableSortBy: true
    },
    {
      Header: "Registration Link",
      accessor: "id",
      Cell: (row: any) => {
        return <Button className="btn-secondary h-10 w-10 inline-flex items-center justify-center text-white" icon="icon-park-outline:copy-link" text={undefined} isLoading={undefined} disabled={undefined} link={undefined} onClick={(e) => {
          e.stopPropagation();
          const registrationLinkURL = `${window.location.protocol}//${window.location.hostname}/user/registration/${row?.cell?.value}`;
          navigator.clipboard.writeText(registrationLinkURL);
          toast.success("Registration link copied to clipboard successfully");
        }} div={undefined} children={undefined} />;
      },
      disableSortBy: true
    },
    {
      Header: "Registration QR",
      accessor: "qr_code",
      Cell: (row: any) => {
        return (row?.cell?.value && <Button className="" icon={undefined} text={undefined} isLoading={undefined} disabled={undefined} link={undefined} onClick={(e) => {
          // window.open(row?.cell?.value, "_blank");
          e.stopPropagation();
          openQrModal(row?.cell?.value)
        }} div={undefined} children={(<img height="45px" width="45px" src={row.cell.value} />)} />)
      },
      disableSortBy: true
    },
    {
      Header: 'Created At',
      accessor: 'created_at.$date',
      disableSortBy: false,
      Cell: ({ value }) => {
        const date = new Date(value);
        return date.toLocaleDateString();
      },
    },

  ];
  const { data, error, isLoading } = useGetEventsQuery();
  const navigate = useNavigate();
  const [searchArray, setSearchArray] = useState<EventListType[]>([]);
  const columns: any = useMemo(() => COLUMNS, []);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [isQrDownloadImageOpen, setIsQrDownloadModalOpen] = useState(false);
  const [selectedQrImageLink, setSelectedImageQrLink] = useState<any>(null);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  useEffect(() => {
    if (data) {
      setSearchArray(data)
    }
  }, [data]);

  const handleRowClick = useCallback((row: any) => {
    navigate(`/edit-event/${row?.original?.id}`)
  }, [navigate])



  const [deleteEvent] = useDeleteEventsByIdMutation();

  const openModal = (eventId) => {
    setSelectedEvent(eventId);
    setIsDeleteConfirmModalOpen(true);
  };


  const closeModal = () => {
    setSelectedEvent(null);
    setIsDeleteConfirmModalOpen(false);
  };


  const closeQrModal = () => {
    setIsQrDownloadModalOpen(false)
    // setSelectedImageQrLink(null)

    // setSelectedEvent(null);
    // setIsDeleteConfirmModalOpen(false);
  };

  const openQrModal = (imageLink) => {
    setSelectedImageQrLink(imageLink);
    setIsQrDownloadModalOpen(true)

  }

  const resetModalQRImage = () => {
    setSelectedImageQrLink(null);
    // setIsQrDownloadModalOpen(true)

  }

  const handleDownloadQR = async () => {
    //Pending error in image
    const link = document.createElement("a");

    console.log("seleceted Image QR link--", selectedQrImageLink)


    // const imageBlob = await fetch(selectedQrImageLink).then((response) => response.blob());

    try {
      const imageBlob = await fetch(selectedQrImageLink)
        .then((response) => response.arrayBuffer())
        .then((buffer) => new Blob([buffer], { type: "image/png" }))

      link.href = URL.createObjectURL(imageBlob);
      link.download = "qr_code.png";
      link.click();
      // link.download = "qr_code.png";
    } catch (error) {
      console.log("download failed--", error)
      toast.error("Failed to save  QR")
    }
    closeQrModal()
  };


  const confirmDelete = async () => {
    closeModal();
    await deleteEvent(selectedEvent);
  }


  const tableInstance = useTable(
    {
      columns,
      data: searchArray,
      initialState: {
        hiddenColumns: ['created_at.$date'],
        sortBy: [
          {
            id: 'created_at.$date',
            desc: true, 
          },
        ]
      }
    },
    useSortBy,
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = tableInstance;
  const { pageIndex, pageSize } = state;
  return (
    <>
      <Card title="Events List" subtitle={undefined} headerslot={<Button className="btn-dark mb-3 text-white" text="Create" isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} onClick={() => navigate("/dynamic-create-events")} div={undefined} ></Button>} noborder={undefined} className="min-w-full" bodyClass={undefined}>
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden ">
              <table
                className="min-w-full divide-y divide-slate-100 table-auto dark:divide-slate-700"
                {...getTableProps}
              >
                <thead className="bg-slate-200 dark:bg-slate-700">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          scope="col"
                          className=" table-th "
                          style={{
                            textAlign: 'center'
                          }}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  className="bg-white divide-y divide-slate-100 dark:bg-slate-800 dark:divide-slate-700"
                  {...getTableBodyProps}
                >
                  {page.map((row: any) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} onClick={() => handleRowClick(row)}>
                        {row.cells.map((cell: any) => {
                          //TO prevent time from breaking and break description
                          const isDescription = cell.column.id === "description";
                          const isTime = cell.column.id === "time";

                          const alignLeft = !!(cell.column.id === "description" || cell.column.id === "name")



                          return (
                            <td {...cell.getCellProps()} className="table-td min-w-full cursor-pointer"
                              style={{
                                maxWidth: isDescription ? "200px" : undefined,
                                wordBreak: isDescription ? "break-word" : undefined,
                                whiteSpace: isDescription ? "normal" : "nowrap",
                                minWidth: isTime ? "80px" : undefined,
                                textAlign: alignLeft ? "left" : "center",
                              }}
                            >

                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="md:flex md:space-y-0 space-y-5 justify-between mt-6 items-center">
          <div className=" flex items-center space-x-3 rtl:space-x-reverse">
            <select
              className="form-control py-2 w-max"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 25, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            <span className="text-sm font-medium text-slate-600 dark:text-slate-300">
              Page{" "}
              <span>
                {pageIndex + 1} of {pageOptions.length}
              </span>
            </span>
          </div>
          <ul className="flex items-center  space-x-3  rtl:space-x-reverse">
            <li className="text-xl leading-4 text-slate-900 dark:text-white rtl:rotate-180">
              <button
                className={` ${!canPreviousPage ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <Icon icon="heroicons:chevron-double-left-solid" className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined} />
              </button>
            </li>
            <li className="text-sm leading-4 text-slate-900 dark:text-white rtl:rotate-180">
              <button
                className={` ${!canPreviousPage ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Prev
              </button>
            </li>
            {pageOptions.map((page, pageIdx) => (
              <li key={pageIdx}>
                <button
                  aria-current="page"
                  className={` ${pageIdx === pageIndex
                    ? "bg-slate-900 dark:bg-slate-600  dark:text-slate-200 text-white font-medium "
                    : "bg-slate-100 dark:bg-slate-700 dark:text-slate-400 text-slate-900  font-normal  "
                    }    text-sm rounded leading-[16px] flex h-6 w-6 items-center justify-center transition-all duration-150`}
                  onClick={() => gotoPage(pageIdx)}
                >
                  {page + 1}
                </button>
              </li>
            ))}
            <li className="text-sm leading-4 text-slate-900 dark:text-white rtl:rotate-180">
              <button
                className={` ${!canNextPage ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
            </li>
            <li className="text-xl leading-4 text-slate-900 dark:text-white rtl:rotate-180">
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className={` ${!canNextPage ? "opacity-50 cursor-not-allowed" : ""
                  }`}
              >
                <Icon icon="heroicons:chevron-double-right-solid" className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined} />
              </button>
            </li>
          </ul>
        </div>
      </Card>
      {/* Confirmation Modal */}
      <Dialog open={isDeleteConfirmModalOpen} onClose={closeModal} className="relative z-10">
        <div className="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true"></div>
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-md rounded bg-white p-6 shadow-lg">
            <Dialog.Title className="text-lg font-semibold">Confirm Delete</Dialog.Title>
            <Dialog.Description className="mt-2 text-gray-600">
              Are you sure you want to delete the event ?
            </Dialog.Description>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                onClick={confirmDelete}
              >
                Delete
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Modal Component */}
      {/* <Dialog open={isQrDownloadImageOpen}  onClose={() => { console.log("Image modal Open") }} className="relative z-10">
        <div className="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true"></div>
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-md bg-white p-6 shadow-lg rounded flex flex-col">
            <Dialog.Title className="text-lg font-semibold mb-4">QR Code</Dialog.Title>

            <div className="flex-grow mb-4 overflow-hidden">
              <img
                src={selectedQrImageLink}
                alt="Modal"
                className="max-w-full max-h-96 object-contain mx-auto"
              />
            </div>

            <div className="flex justify-end space-x-2 mt-4">
              
              <Button className="btn-secondary mb-3 text-white" text="Close" isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} onClick={closeQrModal} div={undefined} ></Button>
              <Button className="btn-dark mb-3 text-white" text="Download QR" isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} onClick={handleDownloadQR} div={undefined} ></Button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog> */}

      <Transition show={isQrDownloadImageOpen} afterLeave={resetModalQRImage} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeQrModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md bg-white p-6 shadow-lg rounded flex flex-col">
                <Dialog.Title className="text-lg font-semibold mb-4">QR Code</Dialog.Title>

                <div className="flex-grow mb-4 overflow-hidden">
                  <img
                    src={selectedQrImageLink}
                    alt="Modal"
                    className="max-w-full max-h-96 object-contain mx-auto"
                  />
                </div>

                <div className="flex justify-end space-x-2 mt-4">
                  <Button className="btn-secondary mb-3 text-white" text="Close" isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} onClick={closeQrModal} div={undefined} ></Button>
                  <Button className="btn-dark mb-3 text-white" text="Download QR" isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} onClick={handleDownloadQR} div={undefined} ></Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Events;
