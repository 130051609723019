import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '@/Components/ui/Button';
import { useTable, useSortBy } from 'react-table';
import { useGetAllMailersQuery, usePostMailersIsolatedMutation } from '@/Redux/Services/BaseQueryIsolated';
import { useNavigate, useParams } from 'react-router-dom';

const PickMailerModal = ({ isOpen, onClose }) => {
  // Fetch data
  const { data: mailers, isLoading, error } = useGetAllMailersQuery(null, {
    skip: !isOpen, // Fetch only when modal is open
  });

  let params = useParams();
  const navigate = useNavigate();


  console.log("Parsms are --", params)

  // Memoize columns and data
  const columns = React.useMemo(
    () => [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Event', accessor: 'event.name' },
    ],
    []
  );
//   const data = React.useMemo(() => mailers || [], [mailers]);

  const data = React.useMemo(() => {
    console.log('mailData',mailers)
    if (!mailers) return [];
    return mailers.filter((row) => row.eventId !== params.id);
  }, [mailers, params.id]);

  console.log("Ddd",data)

  const [addMailers] = usePostMailersIsolatedMutation();



  const handleRowClick = (row) => {
        console.log("row--",row)

        let newName = prompt(
            "Enter a name for the mailer. If duplicate, a timestamp will be appended:",
            row.original.name
        );
    
        if (newName === null) {
            // User canceled the prompt
            return;
        }

        if (newName === row.original.name) {
            const timestamp = new Date().toISOString().replace(/[-:.TZ]/g, '');
            newName = `${newName}_${timestamp}`;
        }


        let payload = {
            id : params.id,
            values : {
                body : row.original.body,
                filter: {},
                name : newName,
                on_registration: false,
                subject : row.original.subject
            }
        }

        addMailers(payload)
                .unwrap()
                .then((res: any) => {
                    if (res) {
                        if(res.id) {
                            console.log("naviagtion")
                            window.location.reload()
                            // navigate(`/mailers/${params.id}`)
                        }
                        console.log("Create response --",res)
                        // navigate(`/mailers/${formId}`)
                    }
                })
                .catch((error) => {
                    alert("Failed to Import Mailer")
                })

  }



  // Initialize table instance
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows, // All rows (no pagination)
    prepareRow,
  } = tableInstance;

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {/* <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                  Import Template
                </Dialog.Title> */}

                <div className="mt-4 overflow-x-auto">
                  <div className="inline-block min-w-full align-middle">
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : error ? (
                      <p className="text-red-500">Error loading mailers</p>
                    ) : (
                      <div className="overflow-hidden">
                        <table
                          className="min-w-full divide-y divide-slate-100 table-fixed dark:divide-slate-700"
                          {...getTableProps()}
                        >
                          <thead className="bg-slate-200 dark:bg-slate-700">
                            {headerGroups.map((headerGroup) => (
                              <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                  <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    scope="col"
                                    className="table-th px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-300"
                                  >
                                    {column.render('Header')}
                                    <span>
                                      {column.isSorted
                                        ? column.isSortedDesc
                                          ? ' 🔽'
                                          : ' 🔼'
                                        : ''}
                                    </span>
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody
                            className="bg-white divide-y divide-slate-100 dark:bg-slate-800 dark:divide-slate-700"
                            {...getTableBodyProps()}
                          >
                            {rows.map((row) => {
                              prepareRow(row);
                              return (
                                <tr {...row.getRowProps()} onClick={() => handleRowClick(row)}>
                                  {row.cells.map((cell) => (
                                    <td
                                      {...cell.getCellProps()}
                                      className="table-td px-4 py-2 text-sm text-gray-700 dark:text-gray-300"
                                    >
                                      {cell.render('Cell')}
                                    </td>
                                  ))}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>

                {/* Modal Footer */}
                <div className="mt-4 flex justify-end">
                <Button  className = "btn-dark mb-3 text-white" isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} div={undefined} text="Cancel" onClick={onClose} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PickMailerModal;
