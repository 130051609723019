import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { baseApi } from './Services/BaseQuery';
import { baseQueryIsolated } from './Services/BaseQueryIsolated';
import StaticReducer, { loader } from './Services/StaticReducer';
import auth, { setToken, logOut } from './Services/auth';
import layout, { handleMobileMenu } from './Services/layout';

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [baseQueryIsolated.reducerPath]: baseQueryIsolated.reducer,
    static: StaticReducer,
    auth,
    layout
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(baseApi.middleware,baseQueryIsolated.middleware),
})

setupListeners(store.dispatch);
export { setToken, logOut, loader, handleMobileMenu };
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;