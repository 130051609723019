import { useWatch } from "react-hook-form";
import Textinput from "@/Components/ui/Textinput";
import ControlledTextField from "@/Components/ui/ControlledComponents/ControlledTextField";

const DropdownValues = ({parentIndex, control, register, namePrefix = "" }) => {
  const type = useWatch({ control, name: `${namePrefix}[${parentIndex}].type` });

  return (type === "dropdown") ? (
    <ControlledTextField
                name={`${namePrefix}[${parentIndex}].dropDownValue`}
                control={control}
                label="Dropdown Values Comma Separated"
                rules={{ required: "First name is required" }}
              />
    // <Textinput
    //   label="Dropdown Values"
    //   type="text"
    //   id={`dropDownValue${parentIndex}`}
    //   placeholder=" Enter dropdown values comma separated"
    //   register={register}
    //   name={`${namePrefix}[${parentIndex}].dropDownValue`} 
    //   error={undefined}
    //   readonly={undefined} value={undefined}  icon={undefined} disabled={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} onChange={undefined} options={undefined} onFocus={undefined} defaultValue={undefined}              />
    ) 
      : 
      <></>
};

export default DropdownValues;