import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { Table, ScrollArea, Loader, Text, Box, Paper } from '@mantine/core';
import { useGetWhatsappStatusQuery } from '../../Redux/Services/Events';
import { useState } from 'react';
import { Flex } from '@mantine/core';
import { TextInput } from '@mantine/core';
import { Select } from '@mantine/core';

function WhatsappStatus() {
    const { data, error, isLoading } = useGetWhatsappStatusQuery();
    const [filters, setFilters] = useState({
        name: '',
        qrCode: '',
        number: '',
        status: '',
    });

    const filteredData = data?.filter((item) => {
        console.log(item, filters);
        const matchesName = item.name.toLowerCase().includes(filters.name.toLowerCase());
        const matchesQrCode = item.qr_code.toLowerCase().includes(filters.qrCode.toLowerCase());
        const matchesNumber = item.number.toString().includes(filters.number);
        const matchesStatus =
            filters.status === '' || item.whatsappStatus.toLowerCase().includes(filters.status.toLowerCase());
        return matchesName && matchesQrCode && matchesNumber && matchesStatus;
    });
    const statusOptions = [
        { value: '', label: 'All' },
        { value: 'Delivered', label: 'Delivered' },
        { value: 'Failed', label: 'Failed' },
        { value: 'Pending', label: 'Pending' },
    ];



    const columns = [
        { accessor: 'name', label: 'Name' },
        { accessor: 'qr_code', label: 'QR Code' },
        { accessor: 'number', label: 'Number' },
        { accessor: 'whatsappStatus', label: 'Status' },
        { accessor: 'error', label: 'Error' },
    ];

    if (isLoading) {
        return (
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Loader size="lg" />
            </Box>
        );
    }

    if (error) {
        return (
            <Box style={{ textAlign: 'center', marginTop: '20px' }}>
                <Text color="red" size="lg">
                    Error fetching data!
                </Text>
            </Box>
        );
    }

    return (
        <Paper shadow="sm" p="lg" radius="md" style={{ maxWidth: '80%', margin: 'auto', marginTop: '20px' }}>
            {/* Filter Inputs */}
            <Flex
                direction="row"
                gap="md" // Adds spacing between the inputs
                justify="flex-start"
                align="center"
                wrap="nowrap" // Prevents inputs from wrapping to the next line
                style={{
                    marginBottom: '20px',
                    overflowX: 'auto', // Adds horizontal scroll if content overflows
                }}
            >
                <TextInput
                    placeholder="Filter by Name"
                    value={filters.name}
                    onChange={(e) => setFilters((prev) => ({ ...prev, name: e.target.value }))}
                />
                <TextInput
                    placeholder="Filter by QR Code"
                    value={filters.qrCode}
                    onChange={(e) => setFilters((prev) => ({ ...prev, qrCode: e.target.value }))}
                />
                <TextInput
                    placeholder="Filter by Number"
                    value={filters.number}
                    onChange={(e) => setFilters((prev) => ({ ...prev, number: e.target.value }))}
                />
                <TextInput
                    placeholder="Filter by Status"
                    value={filters.status}
                    onChange={(e) => setFilters((prev) => ({ ...prev, status: e.target.value }))}
                />
            </Flex>

            {/* Table */}
            <ScrollArea>
                <Table
                    striped
                    highlightOnHover
                    style={{
                        border: '1px solid #e0e0e0', // Outer table border
                        borderCollapse: 'collapse',
                        margin: '20px 0', // Spacing above and below the table
                    }}
                >
                    <thead>
                        <tr>
                            {columns.map((col) => (
                                <th
                                    key={col.accessor}
                                    style={{
                                        border: '1px solid #e0e0e0', // Header cell border
                                        padding: '10px', // Padding inside the header cells
                                        textAlign: 'left',
                                    }}
                                >
                                    {col.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData?.length > 0 ? (
                            filteredData.map((item) => (
                                <tr key={item._id}>
                                    <td
                                        style={{
                                            border: '1px solid #e0e0e0', // Row cell border
                                            padding: '10px', // Padding inside the cells
                                        }}
                                    >
                                        {item.name}
                                    </td>
                                    <td
                                        style={{
                                            border: '1px solid #e0e0e0',
                                            padding: '10px',
                                        }}
                                    >
                                        {item.qr_code}
                                    </td>
                                    <td
                                        style={{
                                            border: '1px solid #e0e0e0',
                                            padding: '10px',
                                        }}
                                    >
                                        {item.number}
                                    </td>
                                    <td
                                        style={{
                                            border: '1px solid #e0e0e0',
                                            padding: '10px',
                                        }}
                                    >
                                        {item.whatsappStatus}
                                    </td>
                                    <td
                                        style={{
                                            border: '1px solid #e0e0e0',
                                            padding: '10px',
                                        }}
                                    >
                                        {item.error}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan={columns.length}
                                    style={{
                                        textAlign: 'center',
                                        padding: '10px',
                                        border: '1px solid #e0e0e0',
                                    }}
                                >
                                    No records found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </ScrollArea>

        </Paper>
    );
}

export default WhatsappStatus;