import { createRef, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import Fileinput from "@/Components/ui/Fileinput";
import { storage } from "@/utils/firebase";
import { ALLOWED_IMAGE_EXTENSIONS } from '../createEventWizard.constants';
import { useGetEmailVerificationStatusQuery } from '@/Redux/Services/BaseQueryIsolated';
import { Cropper, ReactCropperElement } from 'react-cropper';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import "cropperjs/dist/cropper.css";
import { toast } from 'react-toastify';
import Button from '@/Components/ui/Button';

type ImageProps = {
  errors: any,
  register: Function,
  control: any,
  getValues: any,
  setValue: any,
}

const Image = ({ errors, register, control, getValues, setValue }: ImageProps) => {
  const [bannerImagePreview, setBannerImagePreview] = useState(getValues('bannerImage'));
  const [logoImagePreview, setLogoImagePreview] = useState(getValues('logo'));


  const [cropperData, setCropperData] = useState<any>({ file: null, type: '' });
  const cropperRef = createRef<ReactCropperElement>();
  const [isCropperDialogOpen, setIsCropperDialogOpen] = useState(false);






  const bannerImage = useWatch({
    control,
    name: "bannerImage",
  });
  const logo = useWatch({
    control,
    name: "logo",
  });


  useEffect(() => {

    if (bannerImage != bannerImagePreview && bannerImage instanceof FileList && bannerImage.length > 0) {
      const file = bannerImage[0];

      if (file instanceof File) {
        const fileExtension = file.name.split('.').pop()?.toUpperCase();
        if (fileExtension && ALLOWED_IMAGE_EXTENSIONS.indexOf(fileExtension) > -1) {
          // onBannerImageFileChange(file);
          handleFileToCrop(file, 'bannerImage');
        }
      }
    } else if (typeof bannerImage === 'string') {
      setBannerImagePreview(bannerImage)
    }
  }, [bannerImage]);

  useEffect(() => {

    if (logo != logoImagePreview && logo instanceof FileList && logo.length > 0) {
      const file = logo[0];
      if (file instanceof File) {
        const fileExtension = file.name.split('.').pop()?.toUpperCase();
        if (fileExtension && ALLOWED_IMAGE_EXTENSIONS.indexOf(fileExtension) > -1) {

          handleFileToCrop(file, 'logo');
        }
      }
    } else if (typeof logo === 'string') {

      setLogoImagePreview(logo)
    }

  }, [logo]);


  const handleFileToCrop = (file: File, type: string) => {
    const fileExtension = file.name.split('.').pop()?.toUpperCase();
    if (fileExtension && ALLOWED_IMAGE_EXTENSIONS.indexOf(fileExtension) > -1) {
      setIsCropperDialogOpen(true)
      setCropperData({ file, type });
    }
  };




  const uploader = (file: File, onComplete: (url: string) => void) => {
    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: string) => {
          onComplete(downloadURL)
        });
      }
    );
  }


  const getCroppedImage = async (cropper: Cropper, fileName: string): Promise<File | null> => {
    return new Promise((resolve) => {
      cropper.getCroppedCanvas().toBlob((blob) => {
        if (blob) {
          resolve(new File([blob], fileName));
        } else {
          resolve(null);
        }
      });
    });
  };


  const handleCrop = async () => {
    try {
      let croppedImg: any = null;
      if (typeof cropperRef.current?.cropper !== "undefined") {
        croppedImg = await getCroppedImage(cropperRef.current.cropper, cropperData.file.name);
      }

      if (croppedImg && cropperData.type === 'logo') {
        uploader(croppedImg, (url: string) => {
          setLogoImagePreview(url);
          setValue('logo', url);
        });
      } else if (croppedImg && cropperData.type === 'bannerImage') {
        uploader(croppedImg, (url: string) => {
          setBannerImagePreview(url);
          setValue('bannerImage', url);
        });
      }
    }catch(error){
      toast.error("Error occured while cropping image")
    }
    setIsCropperDialogOpen(false)
  };


  // const onLogoFileChange = async (file) => {
  //   uploader(file, (url: string) => {
  //     setLogoImagePreview(url);
  //     setValue('logo', url)
  //   })
  // }

  // const onBannerImageFileChange = async (file) => {
  //   uploader(file, (url: string) => {
  //     setBannerImagePreview(url);
  //     setValue('bannerImage', url)
  //   })
  // }

  // const handlePreviewCrop = () => {
  //     if (typeof cropperRef.current?.cropper !== "undefined") {
  //         const canvas = cropperRef.current?.cropper.getCroppedCanvas();
  //         canvas.toBlob((blob) => setCroppedImage(new File([blob!], cropperData.file.name)));
  //   }
  // }


  return (<div>
    <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
      <div className="md:col-span-2 col-span-1">
        <h4 className="text-base text-slate-800 dark:text-slate-300 mb-3 md:mb-6">
          Select Logo and Banner Image
        </h4>
      </div>
      <div>
        <label className="form-label">
          Logo - Recommended size 50px X 50px
        </label>
        <Fileinput
          previewHeight='200px'
          previewWidth='200px'
          accept="image/jpeg, image/png, image/jpg"
          placeholder="Choose a logo image"
          name="logo"
          error={errors?.logo}
          register={register}
          preview
          selectedFile={logoImagePreview}
          multiple={false} id={undefined} badge={undefined} selectedFiles={undefined} onChange={undefined} />
      </div>
      <div>
        <label className="form-label">
          Banner Image - Recommended size 500px(width) X 100px(height)
        </label>
        <Fileinput
          name="bannerImage"
          previewHeight='100px'
          previewWidth='500px'
          accept="image/jpeg, image/png, image/jpg"
          placeholder="Choose a banner image"
          error={errors?.bannerImage}
          register={register}
          preview
          selectedFile={bannerImagePreview}
          multiple={false} id={undefined} badge={undefined} selectedFiles={undefined} onChange={undefined} />
      </div>
    </div>






    {/* {showCropper && cropperData.file && (
        <div className="cropper-modal">
          <Cropper
            src={URL.createObjectURL(cropperData.file)}
            style={{ height: 400, width: '100%' }}
            initialAspectRatio={cropperData.type === 'logo' ? 1 : 5}
            aspectRatio={cropperData.type === 'logo' ? 1 : 5}
            guides={true}
            crop={(event:any) => {
              const canvas = event.target.cropper.getCroppedCanvas();
              canvas.toBlob((blob) => setCroppedImage(new File([blob!], cropperData.file.name)));
            }}
          />
          <button onClick={handleCrop} className="btn btn-primary mt-4">
            Confirm Crop
          </button>
        </div>
      )} */}


    {/* Headless UI Dialog for Cropper */}
    <Transition appear show={isCropperDialogOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setIsCropperDialogOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}

              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Crop Image
                </Dialog.Title>
                <div className="mt-4">
                  <Cropper
                    ref={cropperRef}
                    src={cropperData.file && URL.createObjectURL(cropperData.file)}
                    style={{ height: 300, width: '100%' }}
                    initialAspectRatio={cropperData.type === 'logo' ? 1 : 5}
                    aspectRatio={cropperData.type === 'logo' ? 1 : 5}
                    guides={true}
                  />
                </div>
                <div className="mt-4 flex justify-end space-x-2">
                <Button className="btn-secondary mb-3 text-white" text="Close" isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} onClick={() => setIsCropperDialogOpen(false)} div={undefined} ></Button>
                <Button className="btn-dark mb-3 text-white" text="Crop" isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} onClick={handleCrop} div={undefined} ></Button>
                 
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>

    </Transition>
  </div>


  )
}
export default Image;