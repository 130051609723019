import { useSelector, useDispatch } from "react-redux";
import { handleMobileMenu, RootState } from "@/Redux/Store";

const useMobileMenu = () : [boolean, (T: boolean) => {}] => {
  const dispatch = useDispatch();
  const mobileMenu = useSelector((state: RootState) => state.layout.mobileMenu);

  const setMobileMenu = (val: boolean)=> dispatch(handleMobileMenu(val));

  return [mobileMenu, setMobileMenu];
};

export default useMobileMenu;
