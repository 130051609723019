import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { createUserWithEmailAndPassword } from 'firebase/auth';

import { setToken } from '@/Redux/Store';
import { auth } from '@/utils/firebase';
import Textinput from "@/Components/ui/Textinput";
import Button from "@/Components/ui/Button";
import Checkbox from "@/Components/ui/Checkbox";
const schema = yup
  .object({
    email: yup.string().email("Invalid email").required("Email is Required"),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .required("Please enter password"),
    // tnc: yup.bool().required("Please accept TnC and Privacy Policy")
  })
  .required();

const RegForm = () => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );

      localStorage.setItem(
        "event-register-app-is-auth",
        "true"
      );
      dispatch(setToken(res?.user?.stsTokenManager?.accessToken));
      reset();
      setLoading(false);
      navigate("/");
      toast.success("Account Created Successfully");

    } catch (err) {
      setLoading(false);
      let message = err.message;
      if (err.code == 'auth/email-already-exists' || err.code === 'auth/email-already-in-use') {
        message = 'Email Id already exists.'
      }
      toast.error(message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-5 ">
      <Textinput
        name="email"
        label="email"
        type="email"
        placeholder=" Enter your email"
        register={register}
        error={errors.email}
        className="h-[48px]"
      />
      <Textinput
        hasicon
        name="password"
        label="passwrod"
        type="password"
        placeholder=" Enter your password"
        register={register}
        error={errors.password}
        className="h-[48px]"
      />
      <Checkbox
        name="tnc"
        // register={register}
        // error={errors.tnc}
        label="You accept our Terms and Conditions and Privacy Policy"
        value={checked}
        onChange={() => setChecked(!checked)}
      />
      <Button
        type="submit"
        text="Create an account"
        className="btn btn-dark block w-full text-center"
        isLoading={isLoading}
      />
    </form>
  );
};

export default RegForm;
