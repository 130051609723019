import { useEffect, useMemo, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useFilters,
} from "react-table";
import {
  useGetEventsByIdQuery,
  useGetFormTemplateByIdQuery,
} from "@/Redux/Services/Events";
import Card from "@/Components/ui/Card";
import Icon from "@/Components/ui/Icon";
import Loader from "@/Components/Loader";
import Button from "@/Components/ui/Button";
import useWidth from "@/hooks/useWidth";

import GlobalFilter from "./GlobalFilter";
import { fetchUsers } from "./registerDetails.actions";
import ColumnFilter from "@/Components/RegisterDetails/ColumnFilter";
import { useExportRegisteredUsersDetailMutation } from "@/Redux/Services/BaseQueryIsolated";
import { toast } from "react-toastify";
import { ApprovalStatusFilter } from "@/Components/RegisterDetails/ApprovalStatusFilter";

type columns = {
  Header: string;
  accessor: string;
};

const RegisterDetails3 = () => {


  const approvalStatusFilter = (rows, columnIds, filterValue) => {
    return rows.filter((row) => {
      const { approvalRequired, approval_status } = row.original;
  
      const derivedStatus = approval_status === "rejected"
        ? "rejected"
        : approvalRequired && !approval_status
        ? "pending"
        : "approved";
  
      return !filterValue || derivedStatus === filterValue;
    });
  };



  const defaultColumn = useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  );

  const FIXED_COLUMNS = [
    { Header: "select", accessor: "", disableSortBy: true },
    { Header: "Code", accessor: "_code" },
  ];

  const params: any = useParams();
  const navigate = useNavigate();

  const [usersArray, setUsersArray] = useState([]);
  const [columns, setColumns] = useState<columns[]>([]);
  const [isUsersLoading, setUsersLoading] = useState(true);
  const [firebaseError, setFirebaseError] = useState(false);
  const [shouldDownload, setShouldDownload] = useState(false);
  const [selectedRowMap, setSelectedRowMap] = useState<{ [key: string]: any }>(
    {}
  );
  const [filterFields, setFilterFields] = useState<any>([]);
  const [filterOptions, setFilterOptions] = useState<any>([]); // For react-select options
  // const [selectedFilter, setSelectedFilter] = useState<{ label: string; value: string } | null>(null);
  const [checkInCount, setCheckinCount] = useState({
    checkedIn: 0,
    total: 0,
  });

  const { width, breakpoints } = useWidth();

  const { data: templateData, error }: any = useGetEventsByIdQuery(params.id, {
    skip: !params.id,
  });

  const { data: templateDataExcel, isFetching } = useGetFormTemplateByIdQuery(
    params.id,
    {
      skip: !shouldDownload,
    }
  );

  const [exportSelected] = useExportRegisteredUsersDetailMutation();


  useEffect(() => {
    if (!params.id) return;

    (async () => {
      setUsersLoading(true);
      const resp = await fetchUsers(params.id);
      setFirebaseError(resp.error);
      setUsersArray(resp.usersArray);
      console.log("resp.usersArray--", resp.usersArray);

      const checkedInCount = resp.usersArray?.filter((ele) => {
        return ele.checkedin?.default == true;
      }).length;

      setCheckinCount({
        checkedIn: checkedInCount,
        total: resp.usersArray?.length,
      });
      setUsersLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (usersArray.length) {
      const groupedOptions = columns.reduce((acc: any[], col) => {
        if (col.accessor) {
          const uniqueValues = Array.from(
            new Set(usersArray.map((row: any) => row[col.accessor]))
          );
          acc.push({
            label: col.Header,
            options: uniqueValues.map((value) => ({
              label: value || "N/A",
              value,
            })),
          });
        }
        return acc;
      }, []);
      setFilterOptions(groupedOptions); // State to store grouped options
    }
  }, [usersArray, columns]);

  useEffect(() => {
    if (templateData) {
      let filtereableFields: any = [];
      if (templateData?.fields) {
        for (let i = 0; i < templateData?.fields.length; i++) {
          let field = templateData?.fields[i];
          if (field.type == "dropdown") {
            let filterObj: any = {};
            filterObj.label = field.label;
            filterObj.options = [];
            let filterOptionsArr = field.dropDownValue.split(",");
            for (let j = 0; j < filterOptionsArr.length; j++) {
              let filterOption = filterOptionsArr[j];
              let option = { value: filterOption, label: filterOption };
              filterObj.options.push(option);
            }
            filtereableFields.push(filterObj);
          }
        }
        setFilterFields(filtereableFields);
      }
      const visibleColumns = templateData?.fields.filter(
        ({ display }) => display
      );
      setColumns([
        ...FIXED_COLUMNS,
        ...visibleColumns.map(({ label }) => ({
          Header: label,
          accessor: label,
        })),
        {
          Header: "Check In Status",
          accessor: "checkedin.default",
      
          Filter: ColumnFilter, // Custom filter for this column
          filter: "equals",
          disableSortBy: true,
          Cell: (row: any) => {
            return (
              <div
                className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                  row.cell.value
                    ? "bg-green-100 text-green-700" // Styles for true
                    : "bg-red-100 text-red-700" // Styles for false
                }`}
              >
                {row.cell.value ? "Checked In" : "Not Checked In"}
              </div>
            );
          },
        },
        {
          Header: "Approval Status",
          accessor: "approval_status",
          Filter: ApprovalStatusFilter, 
          filter: "approvalStatus",
          disableSortBy: true,
          Cell: (row: any) => {
            const rowData = row.row.original;
            // You can derive the status dynamically or show a static value
            const status = templateData?.approvalRequired
              ? "Pending"
              : "Approved"; // Example logic
            return (
              <div
                className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium 
    ${
      row.row.original?.approval_status === "rejected"
        ? "bg-red-700 text-white" // Dark red for "Rejected"
        : row.row.original?.approvalRequired &&
          !row.row.original?.approval_status
        ? "bg-red-100 text-red-700" // Light red for "Pending"
        : "bg-green-100 text-green-700" // Green for "Approved"
    }`}
              >
                {row.row.original?.approval_status === "rejected"
                  ? "Rejected"
                  : row.row.original?.approvalRequired &&
                    !row.row.original?.approval_status
                  ? "Pending"
                  : "Approved"}
              </div>
            );
          },
        },
        {
          Header: "Action",
          accessor: "key",
          disableSortBy: true,
          Cell: (row: any) => {
            const rowData = row.row.original;
            return (
              <div>
                <Button
                  className="btn-secondary h-10 w-10 inline-flex items-center justify-center text-white"
                  icon="material-symbols:edit"
                  text={undefined}
                  isLoading={undefined}
                  disabled={undefined}
                  link={`/registered-details/${rowData._eventId}/${rowData.key}`}
                  onClick={undefined}
                  div={undefined}
                  children={undefined}
                />
                {/* <button className='btn btn-secondary' onClick={()=> handleEditClick(rowData)}><Icon icon="heroicons:chevron-double-left-solid" className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined} /></button> */}
              </div>
            );
          },
        },
      ]);
    }
  }, [templateData]);

  const filterTypes = useMemo(
    () => ({
      approvalStatus: (rows, columnIds, filterValue) => {
        return rows.filter((row) => {
          const { approvalRequired, approval_status } = row.original;
  
          const derivedStatus =
            approval_status === "rejected"
              ? "rejected"
              : approvalRequired && !approval_status
              ? "pending"
              : "approved";
  
          return !filterValue || derivedStatus === filterValue;
        });
      },
    }),
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: usersArray,
      defaultColumn: { Filter: () => null },
      filterTypes,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    setGlobalFilter,
    setAllFilters,
    prepareRow,
  } = tableInstance;
  const { globalFilter, pageIndex, pageSize } = state;

  useEffect(() => {
    if (templateDataExcel && shouldDownload) {
      // Assuming 'excelData' is the binary data received from your API response
      const url = URL.createObjectURL(templateDataExcel);

      // Create a temporary <a> element to initiate the download
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "template.xlsx"; // Specify the file name
      document.body.appendChild(a);

      // Simulate click to trigger the download
      a.click();

      // Clean up
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setShouldDownload(false);
    }
  }, [templateDataExcel, shouldDownload]);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      const allSelectedRows = tableInstance.rows.reduce(
        (acc: { [key: string]: any }, row) => {
          acc[(row.original as any).key] = row.original;
          return acc;
        },
        {}
      );
      setSelectedRowMap(allSelectedRows);
    } else {
      setSelectedRowMap({});
    }
  };

  const handleRowSelect = (row: any) => {
    const newSelectedRowMap = { ...selectedRowMap };

    if (newSelectedRowMap[row.original.key]) {
      delete newSelectedRowMap[row.original.key];
    } else {
      newSelectedRowMap[row.original.key] = row.original;
    }

    setSelectedRowMap(newSelectedRowMap);
  };

  const handleEmailSelected = () => {
    const selectedRows = Object.keys(selectedRowMap);
    if (selectedRows.length == 0) {
      toast.warn("Please select guests to send email");
      return;
    }

    navigate(`/mailers/${params.id}`, {
      state: { users: selectedRows },
    });
  };

  const handleExportSelected = async () => {
    const selectedRows = Object.keys(selectedRowMap);
    if (selectedRows.length == 0) {
      toast.warn("Please select guests to export");
      return;
    }

    const exportPayload = {
      id: params.id,
      values: {
        ids: selectedRows,
      },
    };

    await exportSelected(exportPayload)
      .unwrap()
      .then(async (res: any) => {
        if (res?.url) {
          const response = await fetch(res.url);

          if (!response.ok) {
            throw new Error(`Failed to fetch file: ${response.statusText}`);
          }

          // Step 3: Convert the response into a Blob
          const blob = await response.blob();

          const fileURL = window.URL.createObjectURL(blob);

          // Step 5: Create a downloadable link element
          const link = document.createElement("a");
          link.href = fileURL;
          const eventName = templateData?.name;
          let downloadFileName = "guests.xlsx";
          if (eventName) {
            downloadFileName = eventName + "_" + downloadFileName;
          }
          link.download = downloadFileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        toast.error("Failed to export");
      });
  };

  const handleApproveSelected = () => {
    const selectedRows = Object.keys(selectedRowMap);

    if (selectedRows.length == 0) {
      toast.warn("Please select guests to approve");
      return;
    }

    navigate(`/registered-details/${params.id}/${selectedRows[0]}`, {
      state: { users: selectedRows },
    });
  };

  return (
    <Card
      title={
        <div className="flex gap-2">
          <div>Registered Users</div>
          <div>
            <div className="flex items-center gap-2">
              <div className="rounded-full bg-blue-100 text-blue-700 px-3 py-1 text-sm font-semibold">
                Checked In - {checkInCount.checkedIn} / {checkInCount.total}
              </div>
              {/* <span className="text-gray-500 text-sm">
        {((checkInCount.checkedIn / checkInCount.total) * 100).toFixed(0)}% checked in
      </span> */}
            </div>
          </div>
        </div>
      }
      subtitle={undefined}
      noborder={undefined}
      className="min-w-full"
      bodyClass={undefined}
      headerslot={
        <div>
          <Link to={`/import-excel/${params.id}`}>
            <Button
              icon="material-symbols:upload"
              text={width <= breakpoints.sm ? "" : "Upload Template"}
              className="btn-dark mr-4"
              isLoading={undefined}
              disabled={undefined}
              children={undefined}
              link={undefined}
              onClick={undefined}
              div={undefined}
            />
          </Link>
          <Button
            icon="icon-park-outline:excel"
            text={width <= breakpoints.sm ? "" : "Download Excel"}
            className="btn-dark"
            isLoading={isFetching}
            disabled={undefined}
            children={undefined}
            link={undefined}
            onClick={(e) => {
              setShouldDownload(true);
            }}
            div={undefined}
          />
        </div>
      }
    >
      {error || firebaseError ? (
        <>Oh no, there was an error</>
      ) : isUsersLoading ? (
        <Loader />
      ) : usersArray.length ? (
        <>
          <div className="flex gap-2 justify-between flex-wrap">
            <div className="flex gap-2 flex-wrap">
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
              <Select isClearable options={filterFields} placeholder="Filter" />
            </div>
            <div className="flex gap-2 flex-wrap">
              <div>
                <Button
                  text="Approve Selected"
                  className="btn-dark "
                  onClick={handleApproveSelected}
                  isLoading={undefined}
                  disabled={undefined}
                  children={undefined}
                  icon={undefined}
                  link={undefined}
                  div={undefined}
                />
              </div>
              <div>
                <Button
                  text="Export Selected"
                  className="btn-dark "
                  onClick={handleExportSelected}
                  isLoading={undefined}
                  disabled={undefined}
                  children={undefined}
                  icon={undefined}
                  link={undefined}
                  div={undefined}
                />
              </div>
              <div>
                <Button
                  text="Email Selected"
                  className="btn-dark "
                  onClick={handleEmailSelected}
                  isLoading={undefined}
                  disabled={undefined}
                  children={undefined}
                  icon={undefined}
                  link={undefined}
                  div={undefined}
                />
              </div>
              <div>
                <Button
                  text="Whatsapp Selected"
                  className="btn-dark "
                  onClick={() => {}}
                  isLoading={undefined}
                  disabled={undefined}
                  children={undefined}
                  icon={undefined}
                  link={undefined}
                  div={undefined}
                />
              </div>
            </div>
          </div>
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden ">
                <table
                  className="min-w-full divide-y divide-slate-100 table-fixed dark:divide-slate-700"
                  {...getTableProps}
                >
                  <thead className="bg-slate-200 dark:bg-slate-700">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            scope="col"
                            className=" table-th "
                          >
                            {column.id === "select" ? (
                              <div className="flex items-center gap-2">
                                <input
                                  type="checkbox"
                                  onChange={handleSelectAll}
                                  checked={
                                    Object.keys(selectedRowMap).length ===
                                    tableInstance.rows.length
                                  }
                                />
                                {Object.keys(selectedRowMap).length > 0 && (
                                  <span className="text-xs font-medium text-gray-500">
                                    ({Object.keys(selectedRowMap).length})
                                  </span>
                                )}
                              </div>
                            ) : (
                              column.render("Header")
                            )}
                            {/* {column.render("Header")} */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                            <div>
                              {column.canFilter
                                ? column.render("Filter")
                                : null}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    className="bg-white divide-y divide-slate-100 dark:bg-slate-800 dark:divide-slate-700"
                    {...getTableBodyProps}
                  >
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()} className="table-td">
                                {/* {cell.render("Cell")} */}
                                {cell.column.id === "select" ? (
                                  <input
                                    type="checkbox"
                                    checked={
                                      !!selectedRowMap[
                                        (row.original as any).key
                                      ]
                                    }
                                    onChange={() => handleRowSelect(row)}
                                  />
                                ) : (
                                  cell.render("Cell")
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="md:flex md:space-y-0 space-y-5 justify-between mt-6 items-center">
            <div className=" flex items-center space-x-3 rtl:space-x-reverse">
              <select
                className="form-control py-2 w-max"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 25, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
              <span className="text-sm font-medium text-slate-600 dark:text-slate-300">
                Page{" "}
                <span>
                  {pageIndex + 1} of {pageOptions.length}
                </span>
              </span>
            </div>
            <ul className="flex items-center  space-x-3  rtl:space-x-reverse">
              <li className="text-xl leading-4 text-slate-900 dark:text-white rtl:rotate-180">
                <button
                  className={` ${
                    !canPreviousPage ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <Icon
                    icon="heroicons:chevron-double-left-solid"
                    className={undefined}
                    width={undefined}
                    rotate={undefined}
                    hFlip={undefined}
                    vFlip={undefined}
                  />
                </button>
              </li>
              <li className="text-sm leading-4 text-slate-900 dark:text-white rtl:rotate-180">
                <button
                  className={` ${
                    !canPreviousPage ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Prev
                </button>
              </li>
              {pageOptions.length > 1 && (
                <>
                  {/* <li>
                                        <button
                                            className={` ${pageIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
                                                }`}
                                            onClick={() => gotoPage(0)}
                                            disabled={pageIndex === 0}
                                        >
                                            1
                                        </button>
                                    </li> */}
                  {pageIndex > 2 && <li>...</li>}
                  {pageOptions
                    .slice(
                      Math.max(0, pageIndex - 2),
                      Math.min(pageOptions.length, pageIndex + 3)
                    )
                    .map((page) => (
                      <li key={page}>
                        <button
                          className={` ${
                            page === pageIndex
                              ? "bg-slate-900 dark:bg-slate-600  dark:text-slate-200 text-white font-medium "
                              : "bg-slate-100 dark:bg-slate-700 dark:text-slate-400 text-slate-900  font-normal  "
                          } text-sm rounded leading-[16px] flex h-6 w-6 items-center justify-center transition-all duration-150`}
                          onClick={() => gotoPage(page)}
                        >
                          {page + 1}
                        </button>
                      </li>
                    ))}
                  {pageIndex < pageOptions.length - 3 && <li>...</li>}
                  {/* {pageOptions.length > 1 && (
                                        <li>
                                            <button
                                                className={` ${pageIndex === pageOptions.length - 1
                                                        ? "opacity-50 cursor-not-allowed"
                                                        : ""
                                                    }`}
                                                onClick={() => gotoPage(pageOptions.length - 1)}
                                                disabled={pageIndex === pageOptions.length - 1}
                                            >
                                                {pageOptions.length}
                                            </button>
                                        </li>
                                    )} */}
                </>
              )}
              <li className="text-sm leading-4 text-slate-900 dark:text-white rtl:rotate-180">
                <button
                  className={` ${
                    !canNextPage ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
              </li>
              {/* <li className="text-xl leading-4 text-slate-900 dark:text-white rtl:rotate-180">
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              className={` ${
                !canNextPage ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <Icon icon="heroicons:chevron-double-right-solid" className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined} />
            </button>
          </li> */}
            </ul>
          </div>
        </>
      ) : (
        <div>No Registrations! Please Refresh!</div>
      )}
    </Card>
  );
};

export default RegisterDetails3;
