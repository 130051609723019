import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Formik,
  Form,
  ErrorMessage,
  FieldArray,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Tooltip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  usePostMailersMutation,
  usePostScanStationMutation,
  useUpdateMailersMutation,
} from "../../Redux/Services/Usermanagement";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailTemplateEditor from "./EmailTemplateEditor";
import Card from "@/Components/ui/Card";
import { fetchUsers } from "../RegisterDetails/registerDetails.actions";
import { useGetEventsByIdQuery } from "@/Redux/Services/Events";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { EmailEditor } from "@/Components/Mailers/EmailEditor";
import { usePostMailersIsolatedMutation, useSendMailerIsolatedMutation, useUpdateMailersIsolatedMutation } from "@/Redux/Services/BaseQueryIsolated";

type FilterableField = {
  display: boolean;
  type: string;
  dropDownValue: string;
  search: boolean;
  onSpotRegister: boolean;
  mandatory: boolean;
  label: string;
};

function AddMailers() {
  // const initialValues: any = {
  //     name: "",
  //     subject: "",
  //     body: "",
  //     filter: [],
  //     on_registration: false
  // }

    const [sendMailerIsolated] = useSendMailerIsolatedMutation();
  
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    subject: "",
    body: "",
    filter: [],
    on_registration: false,
  });

  const [rValue, setRValue] = useState("");

  const [sFields, setSFields] = useState<any>([]);

  const [showCompanyLogo, setShowCompanyLogo] = useState(false);
  const [generatedHtmlContent, setGeneratedHtmlContent] = useState("");
  const [placeHolderMap, setPlaceHolderMap] = useState({});
  const [filterableFields, setFilterableFields] = useState<FilterableField[]>(
    []
  );
  const [placeHolderOptions, setPlaceholderOptions] = useState<any>([]);

  const LoginSchema = Yup.object({
    name: Yup.string().required(`Template name is required`),
    subject: Yup.string().required(`Subject is required`),
    body: Yup.string().required(`Body is required`),
  });

  const navigate = useNavigate();
  const location = useLocation();

  const formId = location?.pathname?.split("/")[2] ?? "";

  const usersSelected = location?.state?.users;

  const [addMailers] = usePostMailersMutation();
  const [updateMailer] = useUpdateMailersMutation();

  const [addMailersIsolated] = usePostMailersIsolatedMutation()
  const [updateMailersIsolated] = useUpdateMailersIsolatedMutation()
  const editModule = location?.state?.edit;

  const params: any = useParams();

  const { data: templateData, error }: any = useGetEventsByIdQuery(params.id, {
    skip: !params.id,
  });

  useEffect(() => {
    console.log("template data");

    setSFields([{ label: "new", value: "{{new}}" }]);

    if (templateData && templateData?.fields) {
      let filterableField: any = [];
      let fields = templateData?.fields;

      let placeHolderOptions1: any = [];

      for (let i = 0; i < fields.length; i++) {
        if (fields[i].type == "dropdown") {
          filterableField.push(fields[i]);
        }
        if (fields[i].display == true) {
          placeHolderOptions1.push(fields[i]);
        }
      }
      setFilterableFields(filterableField);
      setPlaceholderOptions(placeHolderOptions1);
      // setSFields([{ label: 'test', value: '{{test}}' }])
    }
  }, [templateData]);

  useEffect(() => {
    console.log("SMFIELDS", sFields);
  }, [sFields]);

  const loadUsers = async (eventId) => {
    const resp = await fetchUsers(eventId);
    return resp?.usersArray;
  };

  useEffect(() => {
    let updInitialValues: any = {};
    if (editModule) {
      const data = location?.state?.data;
      updInitialValues.name = data?.name ?? "";
      updInitialValues.subject = data?.subject ?? "";
      updInitialValues.body = data?.body ?? "";
      updInitialValues.on_registration = data?.on_registration ?? false;

      const arrayOfObjects = Object.keys(data?.filter).map((key) => {
        return {
          key: key,
          value: data?.filter[key],
        };
      });
      updInitialValues.filter = arrayOfObjects;

      setInitialValues(updInitialValues);
    }

    const eventId = location?.pathname?.split("/")[2] ?? "";

    loadUsers(eventId).then((userList) => {
      if (userList != null && userList.length > 0) {
        setPlaceHolderMap(userList[0]);
      }
    });
  }, [location]);

  const onSubmit = (values, actionType) => {
    if (actionType === "save") {
    } else if (actionType === "saveAndSendEmail") {
    }
  };

  const keyOptions = filterableFields.map((field) => ({
    value: field.label,
    label: field.label,
  }));

  const submitLogin = async (payloads: any) => {
    let updatePayload = {
      ...payloads,
      filter: payloads?.filter?.map((res: any) => {
        return { [res.key]: res?.value };
      }),
    };

    let updateFilter = {
      ...updatePayload,
      filter: updatePayload.filter.reduce((acc: any, obj: any) => {
        return { ...acc, ...obj };
      }, {}),
    };
    let payload = {
      id: formId,
      values: updateFilter,
    };

    if (editModule) {
      updateMailer(payload)
        .unwrap()
        .then((res: any) => {
          if (res) {
            navigate(`/mailers/${formId}`);
          }
        })
        .catch((error) => {});
    } else {
      addMailers(payload)
        .unwrap()
        .then((res: any) => {
          if (res) {
            navigate(`/mailers/${formId}`);
          }
        })
        .catch((error) => {});
    }
  };

  const replacePlaceholders = (template, values) => {
    return template.replace(/{{(.*?)}}/g, (_, key) => values[key.trim()] || "");
  };

  const getCommaSeparatedKeys = (obj) => {
    if (typeof obj === "object" && obj !== null) {
      return Object.keys(obj).join(", ");
    }
    return "";
  };

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={6}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3, height: "100%" } }}
        >
          <div className="d-flex justify-content-between mb-3">
            <button className="backBtn">
              <Tooltip title="Back">
                <ArrowBackIcon
                  className=""
                  onClick={() => navigate(`/mailers/${formId}`)}
                />
              </Tooltip>
            </button>
            <h4 className="text-center">
              {editModule ? "Edit Mailers" : "Add Mailers"}
            </h4>
            <div></div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            enableReinitialize={true}
            onSubmit={(values, { resetForm }) => {
              submitLogin(values);
            }}
          >
            {({
              handleBlur,
              handleChange,
              values,
              setFieldValue,
              setFieldError,
              validateForm,
              setTouched,
              setFieldTouched,
            }) => (
              <Form>
                <>{}</>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="campaignName"
                      label="Template Name"
                      placeholder="Enter your template Name"
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      name="name"
                      className="formInput"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error-msg"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="qrField"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                      name="subject"
                      label="Subject"
                      placeholder="Enter subject"
                      fullWidth
                      variant="outlined"
                      className="formInput"
                    />
                    <ErrorMessage
                      name="subject"
                      component="div"
                      className="error-msg"
                    />
                    {/* <EmailTemplateEditor /> */}
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.show_company_logo}
                            onChange={(e) => {
                              setShowCompanyLogo(e.target.checked);
                              setFieldValue(
                                "show_company_logo",
                                e.target.checked
                              );
                            }}
                            name={"show_company_logo"}
                            color="primary"
                          />
                        }
                        label="Show Company Logo"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="mt-2 text-sm text-gray-500">
                      Please note, here are the placeholder values that are
                      replaceable, {getCommaSeparatedKeys(placeHolderMap)}
                    </p>

                    {/* <EmailTemplateEditor
                                            value={values.body}
                                            onChange={(content: any) => setFieldValue("body", content)}
                                        /> */}
                    <EmailEditor
                      fields={sFields}
                      name={"body"}
                      value={values.body}
                      onChange={(content) => setFieldValue("body", content)}
                    />
                    {/* <ReactQuill  theme='snow' value={values.body} onChange={(content) => setFieldValue("body", content)}/>
                                        <ErrorMessage name="body" component="div" className="error-msg" /> */}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="button"
                      className="mt-2 ms-1"
                      variant="outlined"
                      onClick={() => {
                        setGeneratedHtmlContent(
                          replacePlaceholders(values.body, placeHolderMap)
                        );
                      }}
                    >
                      Preview
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <p>Filters</p>
                    <FieldArray name="filter">
                      {({ push, remove }) => (
                        <div>
                          {values?.filter?.map((filter, index) => {
                            const selectedField = filterableFields.find(
                              (field) => field.label === filter.key
                            );
                            const valueOptions = selectedField
                              ? selectedField.dropDownValue
                                  .split(",")
                                  .map((value) => ({
                                    value: value.trim(),
                                    label: value.trim(),
                                  }))
                              : [];

                            return (
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                key={index}
                                className="border p-2 mt-2 position-relative"
                              >
                                <Grid item xs={5}>
                                  <Select
                                    options={keyOptions}
                                    name={`filter[${index}].key`}
                                    value={keyOptions.find(
                                      (option) => option.value === filter.key
                                    )}
                                    onChange={(selectedOption) => {
                                      setFieldValue(
                                        `filter[${index}].key`,
                                        selectedOption
                                          ? selectedOption.value
                                          : ""
                                      );
                                      setFieldValue(
                                        `filter[${index}].value`,
                                        ""
                                      );
                                    }}
                                    placeholder="Select key"
                                  />
                                  <ErrorMessage
                                    name={`filter[${index}].key`}
                                    component="div"
                                    className="error-msg"
                                  />
                                </Grid>

                                <Grid item xs={5}>
                                  <Select
                                    options={valueOptions}
                                    name={`filter[${index}].value`}
                                    value={valueOptions.find(
                                      (option) => option.value === filter.value
                                    )}
                                    onChange={(selectedOption) =>
                                      setFieldValue(
                                        `filter[${index}].value`,
                                        selectedOption
                                          ? selectedOption.value
                                          : ""
                                      )
                                    }
                                    placeholder="Select value"
                                    isDisabled={!filter.key}
                                  />
                                  <ErrorMessage
                                    name={`filter[${index}].value`}
                                    component="div"
                                    className="error-msg"
                                  />
                                </Grid>

                                <Grid item xs={2}>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => remove(index)}
                                    startIcon={<DeleteIcon />}
                                  ></Button>
                                </Grid>
                              </Grid>
                            );
                          })}

                          <Button
                            type="button"
                            variant="outlined"
                            onClick={() => push({ key: "", value: "" })}
                            className="mt-2"
                          >
                            Add Filter
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                    {/* <FieldArray name="c">
                                            {({ push, remove }) => {
                                                return (
                                                    <div className="">
                                                        {
                                                            values?.filter?.map((p: any, index: any) => (
                                                                <>
                                                                    <Grid container spacing={0} className="align-items-center border p-2 mt-4 position-relative">

                                                                        <Button
                                                                            onClick={() => remove(index)}
                                                                            className="deleteBtnPosition"
                                                                        >
                                                                            <DeleteIcon />
                                                                        </Button>

                                                                        <Grid item xs={4}>
                                                                            <FormLabel component="legend">
                                                                                Key
                                                                            </FormLabel>
                                                                        </Grid>

                                                                        <Grid item xs={8}>
                                                                            <TextField
                                                                                margin="normal"
                                                                                variant="outlined"
                                                                                label=""
                                                                                style={{ width: "100%" }}
                                                                                name={`filter[${index}].key`}
                                                                                value={p?.key}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </Grid>


                                                                        <Grid item xs={4}>
                                                                            <FormLabel component="legend">
                                                                                Value
                                                                            </FormLabel>
                                                                        </Grid>

                                                                        <Grid item xs={8}>
                                                                            <TextField
                                                                                margin="normal"
                                                                                variant="outlined"
                                                                                label=""
                                                                                style={{ width: "100%" }}
                                                                                name={`filter[${index}].value`}
                                                                                value={p?.value}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            ))
                                                        }
                                                        <div>
                                                            
                                                        </div>
                                                        <Button
                                                            type="button"
                                                            className="mt-2"
                                                            variant="outlined"
                                                            onClick={() =>
                                                                push({
                                                                    key: "",
                                                                    value: "",
                                                                })
                                                            }
                                                        >
                                                            Add
                                                        </Button>
                                                        
                                                    </div>
                                                )
                                            }


                                            }
                                        </FieldArray> */}
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.on_registration}
                            onChange={handleChange}
                            name={"on_registration"}
                            color="primary"
                          />
                        }
                        label="On Registration"
                      />
                    </FormGroup>
                  </Grid>

                  <Grid
                    container
                    className="d-flex justify-content-center "
                    gap={1}
                  >
                    <Grid item>
                      <Button
                        type="reset"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        // onClick={(e)=> {e.preventDefault();onSubmit(values,  'save');}}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={async (e) => {
                          setFieldTouched("name", true);
                          setFieldTouched("subject", true);
                          setFieldTouched("body", true);

                          const errors = await validateForm();

                          if (Object.keys(errors).length === 0) {

                            let updatePayload = {
                                ...values,
                                filter: values?.filter?.map((res: any) => {
                                  return { [res.key]: res?.value };
                                }),
                              };
                          
                              let updateFilter = {
                                ...updatePayload,
                                filter: updatePayload.filter.reduce((acc: any, obj: any) => {
                                  return { ...acc, ...obj };
                                }, {}),
                              };
                              let payload = {
                                id: formId,
                                values: updateFilter,
                              };
                          
                              if (editModule) {
                                updateMailersIsolated(payload)
                                  .unwrap()
                                  .then((res: any) => {
                                    if (res) {
                                    //   navigate(`/mailers/${formId}`);

                                    console.log("updated---",res)
                                    }
                                  })
                                  .catch((error) => {});
                              } else {
                                addMailersIsolated(payload)
                                  .unwrap()
                                  .then((res: any) => {
                                    if (res) {
                                        console.log("added---",res)
                                    //   navigate(`/mailers/${formId}`);

                                        if(res?.id){
                                            console.log("Values---",values)
                                            //we can send mail using the mailer id
                                            let filtersApplied =  values?.filter
                                            let filter = {}
                                            if(filtersApplied){
                                                for(let ele of filtersApplied){
                                                    filter[ele['key']] = ele['value']
                                                }
                                            }
                                            

                                            let sendMailerIsolatedPayload = {
                                                id : res?.id,
                                                values : {
                                                    filters : filter,
                                                    ids: usersSelected
                                                }
                                            }


                                            sendMailerIsolated(sendMailerIsolatedPayload)


                                        }


                                    }
                                  })
                                  .catch((error) => {

                                    

                                  });
                              }


                            // Add your success logic here
                          } 
                        }}
                      >
                        Save and Send Email
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
      <Grid item xs={5} sx={{ display: "flex" }}>
        <Paper
          elevation={4}
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3, width: "100%" } }}
        >
          <div className="flex items-center justify-between">
            <FormLabel> Preview</FormLabel>
            <Button
              type="button"
              className="mt-2 ms-1"
              variant="outlined"
              onClick={() => {}}
            >
              Send Test Email
            </Button>
          </div>
          <hr className="my-2 border-t border-gray-300" />
          {showCompanyLogo && templateData?.banner && (
            <div>
              <img src={templateData?.banner} />
            </div>
          )}
          <div
            style={
              {
                // border: '1px solid #ccc',
                // padding: '16px',
                // borderRadius: '8px',
                // backgroundColor: '#f9f9f9',
                // marginTop: '12px',
                // minHeight: '200px', // Adjust height as needed
              }
            }
            {...(generatedHtmlContent
              ? { dangerouslySetInnerHTML: { __html: generatedHtmlContent } }
              : {})}
            // dangerouslySetInnerHTML={generatedHtmlContent ? { __html: generatedHtmlContent } : {}}
          ></div>
        </Paper>
      </Grid>
    </Grid>
  );
}
export default AddMailers;
