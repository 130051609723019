import { useGetEventLabelIsolatedQuery } from '@/Redux/Services/BaseQueryIsolated'
import { useParams } from 'react-router-dom'
import React, { useEffect, useRef, useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import { useDrop } from "react-dnd";
import "./GenarateForm.css";
import DragFile from "./DragFile";
import DeleteImg from "../../Assets/delete.svg";
import { fontStyle, fontWeight, textAlign } from "@mui/system";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import { loader } from "../../Redux/Services/StaticReducer";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../utils/firebase";


import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useGetOneEventQuery } from "../../Redux/Services/userRegister";
import CustomCard from "../ui/Card";
import { Card, FormControl, InputLabel, TextField } from "@mui/material";
import { useCreateEventLabelIsolatedMutation } from "@/Redux/Services/BaseQueryIsolated";

function convertMMToGridLayout(
  mmLayout,
  containerWidthMm,
  containerHeightMm,
  paddingMm,
  rowHeightPx,
  gridColumns = 12
) {
  // Convert row height from mm to px
  // 96 DPI assumption
  const rowHeightMm = (rowHeightPx / 96) * 25.4; 
  const columnWidthMm = (containerWidthMm - 2 * paddingMm) / gridColumns;

  return mmLayout.map(item => {
    const xGrid = (item.xMm - paddingMm) / columnWidthMm;
    const yGrid = (item.yMm - paddingMm) / rowHeightMm;
    const wGrid = item.widthMm / columnWidthMm;
    const hGrid = item.heightMm / rowHeightMm;

    return {
      x: Math.round(xGrid),
      y: Math.round(yGrid),
      w: Math.round(wGrid),
      h: Math.round(hGrid),
      i: item.field, // Or generate unique ID if needed
      type: item.type,
      props: {
        name: item.field,
        style: {
          fontSize: item.fontSizeMm
            ? parseFloat((item.fontSizeMm / 0.352778).toFixed(2)) // Convert font size back from mm to pt
            : undefined,
        },
      },
    };
  });
}

const ReactGridLayout = WidthProvider(RGL);


const dimensionOptions = [
    { value: "50,75", label: "75mm X 50mm" },
    { value: "75,75", label: "75mm X 75mm" }
  ]
  
  let idCounter = 0;
  
  const getId = () => {
    idCounter++;
  
    return idCounter.toString();
  };

  

const GenerateFormEdit = () => {

    



    const params = useParams();
    const { data: labelData, error } : any= useGetEventLabelIsolatedQuery({eventId: params.id,labelId:params.labelId}, {
        skip: !params.id || !params.labelId
      });
      useEffect(() => {
        
        if(labelData && labelData?.template?.rawLayout){
            console.log("rawLayout --", labelData?.template?.rawLayout)
            setLayout(labelData?.template?.rawLayout)
            idCounter = labelData?.template?.rawLayout.length
            setTemplateName(labelData?.name)
            setRowInsertedCount(labelData?.template?.rawLayout.length)
        }
      }, [labelData]);

      const scalingFactor = 1.5
      const [input, setInput] = useState({
        height: calculateMMtoPx(dimensionOptions[0].value.split(",")[0]),
        width: calculateMMtoPx(dimensionOptions[0].value.split(",")[1])
      })
      const [previewDimensions, setPreviewDimensions] = useState({
        height: dimensionOptions[0].value.split(",")[0],
        width: dimensionOptions[0].value.split(",")[1]
      })
    
      const [selectedDimension, setSelectedDimension] = useState(dimensionOptions[0].value)
    
    
      const [gridRowHeight, setGridRowHeight] = useState(50)
      const [gridMargin, setGridMargin] = useState(5)
    
    
      const containerRef = useRef<any>(null);
    
      const [containerWidths, setContainerWidth] = useState(0);
      const [containerHeight, setContainerHeight] = useState(0);
      const [rowInsertedCount, setRowInsertedCount] = useState(0);
    
    
    
      const [createLabel] = useCreateEventLabelIsolatedMutation();
    
    
    
    
    
    
      useEffect(() => {
        if (containerRef.current) {
          // Measure the width of the container
          const width = containerRef.current.offsetWidth;
          console.log("ContainerRef width --", width)
          setContainerWidth(width);
    
          const height = containerRef.current.offsetHeight;
          console.log("ContainerRef height --", height)
          setContainerHeight(height);
        }
    
        document.addEventListener("click", handleClickOutside);
    
        // Cleanup the event listener when the component unmounts
        return () => {
          document.removeEventListener("click", handleClickOutside);
        };
    
    
      }, []);
    
    
    
      // function percentageToPixels(percentage: any, containerWidth: any) {
      //   percentage = parseFloat(percentage);
      //   return (percentage / 100) * containerWidth;
      // }
      // let containerWidth = containerWidths; // Example container width in pixels
      // let percentage = input.width; // Example percentage
      // let pixels = percentageToPixels(percentage, containerWidth);
    
    
      // let containerHe = containerHeight; // Example container width in pixels
      // const height = percentageToPixels(input.height, containerHe);
    
    
      function calculateMMtoPx(dimensionInMM) {
        let dimMM = parseFloat(dimensionInMM);
        let dpiAssumed = 96;
        let dimensionInPx = dimensionInMM * (dpiAssumed / 25.4)
        return dimensionInPx * scalingFactor;
      }
    
    
    
    
      // console.log(percentage + '% of ' + containerWidth + 'px is ' + pixels + 'px', "height", height);
    
      // const style = {
      //   // position: 'absolute' as 'absolute',
      //   // top: '50%',
      //   // left: '50%',
      //   transform: 'translate(-50%, -50%)',
      //   width: input.width,
      //   height: input.height,
      //   bgcolor: 'background.paper',
      //   border: '2px solid #000',
      //   boxShadow: 24,
      //   p: 1,
      // };
    
      const [layout, setLayout] = useState<any>([]);
      const [index, setIndex] = useState("");
      const [xposition, setXposition] = useState(0)
      const [yposition, setYposition] = useState(0)
      const [size, setSize] = useState<any>({});
      const commonIndex = layout?.findIndex((res: any) => res?.i === index);
      const commonStyle = layout[commonIndex]?.props?.style;
      const commonSettings = layout[commonIndex]?.props
      const imageRef = useRef<any>(null)
      const dispatch = useDispatch();
      const toolbarRef = useRef<any>(null);
      const selectRefs = useRef<any>([]);
      const [templateName, setTemplateName] = useState('')
    
    
      const previewLayout = layout ? layout.map((item) => {
        return {
          ...item, isDraggable: false, isResizable: false
        }
      }) : []
    
      console.log("Preview Layout --", previewLayout)
    
      const pharams = useParams()?.id;
      const { data: data, error: errorEvent, isLoading } = useGetOneEventQuery(pharams)
    
    
      const [open, setOpen] = React.useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);
    
    
      function getRowCount(gridHeight, rowHeight, verticalMargin) {
        // Calculate the total row height including the vertical margin
        const totalRowHeight = rowHeight + verticalMargin;
        // Calculate the number of rows that fit within the grid height
        const rowCount = Math.floor(gridHeight / totalRowHeight);
    
        return rowCount;
      }
    
      const addNewItem = (item: any) => {
        let maxRowsAllowed = getRowCount(input.height, gridRowHeight, gridMargin)
        console.log("MaxRowsAllowed", maxRowsAllowed)
        if (!(rowInsertedCount < maxRowsAllowed)) {
          return
        }
    
        const newInputBox = {
          x: xposition > 2 ? xposition - 2 : 0,
          y: yposition > 2 ? yposition - 2 : 0,
          w: 12,
          h: 1,
          i: getId(),
          isBounded: "",
          type: item?.name?.type,
          props: {
            ...item?.name,
            value: "",
            style: {
              fontSize: 14
            }
          },
          moved: true,
          isResizable: true,
          isDraggable: true,
          static: true,
        };
    
    
        setRowInsertedCount(prev => prev + 1)
        setLayout((prevLayout: any) => [newInputBox, ...prevLayout]);
      };
      console.log("item", layout);
    
      const [{ isOver }, dropRef] = useDrop({
        accept: "language",
        drop: (item) =>
          //  setLayout((pre)=>[...pre,item])
          addNewItem(item),
        collect: (monitor) => ({
          isOver: monitor.isOver(),
        }),
      });
    
      const changeLayout = (changeLayouts: any) => {
        console.log("changeLayout", JSON.parse(JSON.stringify(changeLayouts)))
        console.log("newLap", JSON.parse(JSON.stringify(layout)))
        const combinedArray = layout.map((item1: any) => ({
          ...item1,
          ...changeLayouts.find((item2: any) => item2.i === item1.i),
        }));
    
    
        console.log("After change layout --", combinedArray)
    
    
        setLayout(combinedArray);
      };
    
    
      // const handleClickOutside = (event) => {
      //   // Check if the clicked element is inside a grid item by class
      //   // if (!event.target.classList.contains("gridDIv") && !event.target.classList.contains("gridControl")) {
      //   //   setIndex('');}
    
    
    
    
      //     const isInsideGrid =
      //   event.target.classList.contains("gridDIv") ||
      //   event.target.classList.contains("gridControl");
    
      //     if(!isInsideGrid){
      //       setIndex('')
      //     }
      // };
    
    
      const handleClickOutside = (e) => {
    
    
        console.log('clickedELE', e.target)
    
        let isClickFromToolbar = false;
        if (
          e.target.classList.contains("toolbarSelect") ||
          e.target.querySelector(".toolbarSelect") ||
          e.target.closest(".toolbarSelect") ||
          e.target.classList.contains('imageBlock')
        ) {
          isClickFromToolbar = true
        }
        if (
          toolbarRef.current &&
          !toolbarRef.current.contains(e.target)
          &&
          !isClickFromToolbar
        ) {
          setIndex('');
        }
      };
    
      const gridClickFn = (e, index: any) => {
        e.stopPropagation();
        setIndex(index);
        let ind = layout.findIndex((res: any) => res.i === index);
      };
      const dragOver = (e: any) => {
        console.log("Calling dragover ---", e.currentTarget.clientWidth)
        console.log("Calling dragoverY ---", e.currentTarget.clientHeight)
        console.log("clientX", e.clientX)
        console.log("clientY", e.clientY)
        const containerWidth = e.currentTarget.clientWidth;
        const containerHeight = e.currentTarget.clientHeight;
        setSize(e);
    
    
    
        let x = (e.clientX / containerWidth) * 12;
        console.log("xbefore", x)
    
        x = Math.floor(Math.min(12, Math.max(0, x)));
    
        console.log("xafter", x)
    
        setXposition(x)
    
        let y = (e.clientY / containerHeight);
    
        console.log("ybefore", y)
    
        y = Math.floor(Math.min(12, Math.max(0, y)));
        console.log("yafter", y)
    
        setYposition(y)
      }
    
      const areAllRowsOccupied = () => {
        const rows = new Set();
    
        // Track all rows occupied by grid items
        layout.forEach((item) => {
          for (let row = item.y; row < item.y + item.h; row++) {
            rows.add(row);
          }
        });
    
        // Find the highest row number
        const highestRow = Math.max(...layout.map((item) => item.y + item.h - 1));
    
        // Check if all rows up to the highest row are occupied
        for (let row = 0; row <= highestRow; row++) {
          if (!rows.has(row)) {
            return false; // Found an unoccupied row
          }
        }
        return true; // All rows are occupied
      };
    
      // const dragOver = (e: any) => {
      //   console.log("Dragg", layout)
      //   // setOrderID(section.id)
      //   setSize(e);
      //   const containerWidth = e.currentTarget.clientWidth;
      //   let x = (e.clientX / containerWidth) * 12;
      //   x = Math.floor(Math.min(12, Math.max(0, x)));
      //   setXposition(x)
    
      // // Example fixed height
      //   let y = e.clientY / gridRowHeight; // Scale y based on rowHeight
      //   y = Math.floor(Math.min(containerHeight / gridRowHeight - 1, Math.max(0, y))); // Clamp within rows
      //   setYposition(y);
      // }
    
    
      const handleDeleteControl = (id: any) => {
        let res = layout.filter((res: any) => res.i !== id);
        setRowInsertedCount(prev => prev - 1)
        setLayout(res)
        setTimeout(() => {
          setIndex("")
        }, 100)
      }
    
      const handleChangeText = (e: any, id: any) => {
        let ind = layout.findIndex((res: any) => res.i === id);
        const temp: any = [...layout]
        temp[ind].props = {
          ...temp[ind].props,
          value: e.target.value
        }
        setLayout(temp)
      }
    
      const fontStyleClick = (css: any) => {
        let ind = layout.findIndex((res: any) => res.i === index);
        const temp: any = [...layout]
        temp[ind].props = {
          ...temp[ind].props,
          style: {
            ...temp[ind].props.style,
            ...css
          }
        }
        setLayout(temp)
      }
    
      const handleChangeFontFamily = (e: any) => {
        let ind = layout.findIndex((res: any) => res.i === index);
    
        const temp: any = [...layout]
        temp[ind].props = {
          ...temp[ind].props,
          style: {
            ...temp[ind].props.style,
            [e.target.name]: e.target.value
          }
        }
        setLayout(temp)
      }
    
      const handleChangeFieldMapping = (e: any) => {
        let ind = layout.findIndex((res: any) => res.i === index);
        const temp: any = [...layout]
        temp[ind].props = {
          ...temp[ind].props,
          fieldMapping: e.target.value
        }
        setLayout(temp)
      }
    
      const uploader = (file: File) => {
    
        // dispatch(loader(true))
        const storageRef = ref(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        uploadTask.on("state_changed",
          (snapshot) => {
            const progress =
              Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          },
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: string) => {
    
              console.log("Download URL is ---", downloadURL)
              // dispatch(loader(false))
    
              let ind = layout.findIndex((res: any) => res.i === index);
              const temp: any = [...layout]
              temp[ind].props = {
                ...temp[ind].props,
                value: downloadURL
              }
              setLayout(temp)
    
            });
          }
        );
      }
    
    
    
      const changeDimensions = (e: any) => {
        console.log("change Dimensions --", e.target.value)
    
        setInput({
          height: calculateMMtoPx(e.target.value.split(",")[0]),
          width: calculateMMtoPx(e.target.value.split(",")[1])
        })
        setPreviewDimensions({
          height: e.target.value.split(",")[0],
          width: e.target.value.split(",")[1]
        })
        setSelectedDimension(e.target.value)
      }
    
    
      const registerSelectRef = (element, index) => {
        selectRefs.current[index] = element;
      };
    
      const handleChangeInput = (e: any) => {
        if (e.target.name === "height") {
          setInput({ ...input, [e.target.name]: e.target.value })
        } else {
          setInput({ ...input, [e.target.name]: e.target.value <= 100 ? e.target.value : 100 })
        }
      }
    
      function convertGridToMMWithRowHeight(
        gridLayout,
        containerWidthMm,
        containerHeightMm,
        paddingMm,
        rowHeightPx,
        gridColumns = 12
      ) {
        // Convert row height from px to mm
        // 96 DPI assumption
        const rowHeightMm = (rowHeightPx / 96) * 25.4;
        const columnWidthMm = (containerWidthMm - 2 * paddingMm) / gridColumns;
    
        return gridLayout.map(item => {
          const xMm = paddingMm + item.x * columnWidthMm;
          const yMm = paddingMm + item.y * rowHeightMm;
          const widthMm = item.w * columnWidthMm;
          const heightMm = item.h * rowHeightMm;
    
          return {
            field: item.props?.name || "Unnamed Field",
            type: item.type,
            xMm: parseFloat(xMm.toFixed(2)),
            yMm: parseFloat(yMm.toFixed(2)),
            widthMm: parseFloat(widthMm.toFixed(2)),
            heightMm: parseFloat(heightMm.toFixed(2)),
            fontSizeMm: item.props?.style?.fontSize
              ? parseFloat((item.props.style.fontSize * 0.352778).toFixed(2)) // Convert font size from pt to mm
              : null
          };
        });
      }
    
      const SaveForm = () => {
    
        console.log("commonSettings", layout);
    
    
        console.log("pharams", pharams)
    
        const fieldsData = convertGridToMMWithRowHeight(layout, 50, 75, 10, gridRowHeight, 12)
        const saveData = {
          name: templateName,
          template: {
            size: {
              "unit": "mm",
              "height": selectedDimension.split(",")[0],
              "width": selectedDimension.split(",")[1],
            },
            fields : fieldsData,
            rawLayout: layout
          }
        }
    
    
        createLabel({
          eventId: pharams,
          data: saveData
        })
    
        console.log("zplReadyFormat", saveData);
      };
      return (
        <div className="p-3">
          <div className="mb-2">
            <FormControl size="small" >
              <InputLabel id="demo-simple-select-label"><p>{"size(L x H)"}</p></InputLabel>
              <Select
                labelId=""
                id=""
                name={'printSize'}
                value={selectedDimension}
                onChange={changeDimensions}
                label={<p>{"select size"}</p>}
              >
                {dimensionOptions.map((ele, idx) => {
                  return (<MenuItem key={idx} value={ele.value}>{ele.label}</MenuItem>)
                })}
    
              </Select>
            </FormControl>
            <TextField
              size="small"
              label={<p>Template Name</p>}
              // placeholder={`Enter your ${res?.label}`}
              sx={{ marginLeft: '10px' }}
              type={'text'}
              name={'fontSize'}
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              className="createrenderInput ml-1"
              autoComplete="off"
              InputLabelProps={{
                shrink: true
              }}
            />
    
          </div>
          <hr></hr>
          <div className="row">
            <div className="col-8"></div>
            <div className="col-4">
              <div className="row flex gap-1">
    
                <div ref={toolbarRef} className="">
                  {
                    (
                      // <div className="grid grid-cols-5 grid-rows-1 gap-x-1">
                      <div className="flex gap-2">
                        <div className="row col-span-1">
                          <div className="col-6 mt-3">
                            {/* <p className="mt-1 fontstyleHead">Font style</p> */}
                            <div className={`fontstyleDiv1 flex flex-wrap gap-1 ${!index ? "disabled" : ""}`}>
                              <p onClick={() => index && fontStyleClick({ fontWeight: commonStyle?.fontWeight ? "" : "bold" })} className={commonStyle?.fontWeight ? "fontstyleActive" : ""}>B</p>
                              <p onClick={() => index && fontStyleClick({ textDecoration: commonStyle?.textDecoration ? "" : "underline" })} className={commonStyle?.textDecoration ? "fontstyleActive" : ""}>U</p>
                              <p onClick={() => index && fontStyleClick({ fontStyle: commonStyle?.fontStyle ? "" : "italic" })} className={commonStyle?.fontStyle ? "fontstyleActive" : ""}>I</p>
                              <p onClick={() => index && fontStyleClick({ textAlign: commonStyle?.textAlign === 'left' ? "" : "left" })} className={commonStyle?.textAlign === 'left' ? "fontstyleActive" : ""}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-justify-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
                              </svg></p>
                              <p onClick={() => index && fontStyleClick({ textAlign: commonStyle?.textAlign === 'center' ? "" : "center" })} className={commonStyle?.textAlign === 'center' ? "fontstyleActive" : ""}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-justify" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
                              </svg></p>
                              <p onClick={() => index && fontStyleClick({ textAlign: commonStyle?.textAlign === 'right' ? "" : "right" })} className={commonStyle?.textAlign === 'right' ? "fontstyleActive" : ""}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-justify-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
                              </svg></p>
                            </div>
                          </div>
    
                        </div>
                        <div className="row">
                          <div className="col-6 mt-3">
                            <FormControl className="gridControl" size="small">
                              <InputLabel id="demo-simple-select-label"><p>{"Font Family"}</p></InputLabel>
                              <Select
                                labelId=""
                                id=""
                                disabled={!index}
                                className="gridControl"
                                sx={{ width: 200 }}
                                name={'fontFamily'}
                                value={commonStyle?.fontFamily ?? ""}
                                onChange={handleChangeFontFamily}
                                label={<p>{"Font Family"}</p>}
                              >
                                <MenuItem className="toolbarSelect" key={1} value={"monospace"}>monospace</MenuItem>
                                <MenuItem className="toolbarSelect" key={2} value={"Times New Roman"}>Times New Roman</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 mt-3">
                            {/* <p className="mt-1 fontstyleHead">Font size (px)</p>
    
                          <div className="d-flex gap-2">
                            <input className="fontsizeInput" value={commonStyle?.fontSize} type="number" name="fontSize" onChange={handleChangeFontFamily} />
                          </div> */}
                            <TextField
                              disabled={!index}
                              size="small"
                              label={<p>Font size (px)</p>}
                              // placeholder={`Enter your ${res?.label}`}
                              sx={{ width: 100 }}
                              type={'number'}
                              name={'fontSize'}
                              value={commonStyle?.fontSize}
                              onChange={handleChangeFontFamily}
                              className="createrenderInput"
                              autoComplete="off"
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 mt-3">
                            {/* <p className="mt-1 fontstyleHead">Field Mapping</p>
                          <select
                            className="fontsizeInput"
                            name="fontFamily"
                            onChange={handleChangeFieldMapping}
                            value={commonSettings?.fieldMapping ?? ""}
                          >
                            <option value={""}>select fields</option>
                            {
                              data?.fields?.map((res: any, index: number) => {
                                return <option key={index} value={res?.label}>{res?.label}</option>
                              })
                            }
                          </select> */}
                            <FormControl size="small">
                              <InputLabel id="fmapping"><p>{"Field Mapping"}</p></InputLabel>
                              <Select
                                labelId="fmapping"
                                disabled={!index}
                                id=""
                                sx={{ width: 200 }}
                                name={'fontFamily'}
                                value={commonSettings?.fieldMapping ?? ""}
                                onChange={handleChangeFieldMapping}
                                label={<p>{"Field Mapping"}</p>}
                              >
                                {
                                  data?.fields?.map((res: any, index: number) => {
                                    // return <option key={index} value={res?.label}>{res?.label}</option>
                                    return <MenuItem className="toolbarSelect" key={index} value={res?.label}>{res?.label}</MenuItem>
                                  })
                                }
                                {/* <MenuItem key={1} value={"monospace"}>monospace</MenuItem>
                              <MenuItem key={2} value={"Times New Roman"}>Times New Roman</MenuItem> */}
                              </Select>
                            </FormControl>
    
    
    
                          </div>
                        </div>
                        {
                          commonSettings?.type === "image" &&
                          <div className="row mt-3">
                            {/* <p className="mt-1 fontstyleHead">Image Settings</p> */}
                            <button className="imageUploadBtn" onClick={() => imageRef?.current?.click()}> {`Upload Image`}</button>
                          </div>
                        }
                      </div>
                    )
                  }
    
                </div>
              </div>
    
    
            </div>
          </div>
    
          <div className="row mt-3 flex">
            <div className="col-4 ">
              <div>
                <DragFile />
              </div>
              {/* {index === "" ? (
                <div>
                  <DragFile />
                </div>
              ) : (
                <div className="border p-3">
                  <div className="flex gap-2">
                    <button className="backBtn" onClick={() => setIndex("")}> {`<-Back`}</button>
                    <img src={DeleteImg} alt="delete" className="deleteSvgImg" onClick={() => handleDeleteControl(index)} />
                  </div>
    
                </div>
              )} */}
            </div>
            <div className="flex mt-3 justify-around  mb-4 gap-5 col-span-8 editorContainer">
    
              <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}>
                  <label className=" font-semibold">Drop items here </label>
                  {index && (<img src={DeleteImg} alt="delete" className="deleteSvgImg" onClick={() => handleDeleteControl(index)} />)}
                </div>
    
                <Card style={{ width: `${input.width}px`, height: `${input.height}px` }}>
                  <div className="col-8 border " ref={dropRef}>
                    <div ref={containerRef} onDragOver={(e) => dragOver(e)} className="" style={{ background: "#efefef", width: `${input.width}px`, height: `${input.height}px` }}>
                      <ReactGridLayout
                        style={{ width: `100%`, height: `100%`, minHeight: "50px" }}
                        className="layout mx-auto bg-white border"
                        margin={[gridMargin, gridMargin]}
                        layout={layout}
                        cols={12}
                        rowHeight={gridRowHeight}
                        onLayoutChange={changeLayout}
                        isResizable={true}
                        isDraggable={true}
                        allowOverlap={true}
                      >
                        {layout.map((item: any) => (
                          <div
                            key={item.i}
                            data-grid={item}
                            className="gridDIv position-ralative"
                            onClick={(e) => gridClickFn(e, item.i)}
                          >
                            <>{console.log("", commonStyle)}</>
                            {item.props.type === "text" && (
                              <textarea
                                name={item.props.type}
                                value={item?.props?.fieldMapping}
                                placeholder={`${item.props.name} ${item.i}`}
                                // onChange={(e) => handleChangeText(e, item.i)}
                                className="dragInputText"
    
                                style={{
                                  border: index === item.i ? "2px solid red" : "1px solid #bfbfbf",
                                  ...item.props?.style,
                                  fontSize: `${item.props?.style?.fontSize}px`
                                }}
                              />
                            )}
                            {item.props.type === "image" && (
                              <div className="dragInput" style={{
                                border: index === item.i ? "2px solid red" : "1px solid",
                              }}>
                                {
                                  item?.props?.value ?
                                    <img src={item?.props?.value ?? ""} alt="img" className="dragInputImg  p-1" />
                                    :
                                    <p>{item.props.fieldMapping}</p>
                                }
                                <input
                                  type={"file"}
                                  name={item.props.name}
                                  hidden
                                  ref={imageRef}
                                  onChange={(e: any) => {
                                    event?.stopPropagation()
                                    console.log("mmmmmmmmmmmmmmmmmmmm", e.target.files[0]);
                                    uploader(e.target.files[0])
                                  }}
                                  placeholder={`${item.props.fieldMapping}`}
                                  className="imageBlock"
    
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </ReactGridLayout>
                    </div>
                  </div>
                </Card>
              </div>
    
              <div>
                <div style={{ marginBottom: "4px" }}>
                  <label className=" font-semibold">Preview(L x H) {`${previewDimensions.width}mmx${previewDimensions.height}mm`}</label>
                </div>
                <Card className="previewForm " style={{ width: `${input.width}px`, height: `${input.height}px` }}>
                  <ReactGridLayout
                    // key={JSON.stringify(layout)}
                    className="layout"
                    style={{ width: `100%`, height: `100%`, minHeight: "50px" }}
                    layout={previewLayout}
                    cols={12}
                    rowHeight={gridRowHeight}
                    margin={[gridMargin, gridMargin]}
                    isResizable={false}
                    isDraggable={false}
                    isDroppable={false}
    
                  >
                    {previewLayout.map((item: any) => (
                      <div
                        key={item.i}
                        data-grid={item}
                        className="gridDIv position-ralative"
                      >
                        {item.props.type === "text" && (
                          <textarea
                            name={item.props.type}
                            value={item?.props?.fieldMapping}
                            placeholder={`${item.props.name} ${item.i}`}
                            className="dragInputText resize-none border-0"
                            style={{
                              ...item.props?.style,
                              fontSize: `${item.props?.style?.fontSize}px`
                            }}
                          />
                        )}
                        {item.props.type === "image" && (
                          <div className="dragInput" >
                            {
                              item?.props?.value ?
                                <img src={item?.props?.value ?? ""} alt="img" className="dragInputImg p-1" />
                                :
                                <p>{item.props.fieldMapping}</p>
                            }
                            <input
                              type={"file"}
                              name={item.props.name}
                              hidden
                              ref={imageRef}
                              onChange={(e: any) => {
                                console.log("mmmmmmmmmmmmmmmmmmmm", e.target.files[0]);
                                uploader(e.target.files[0])
                              }}
                              placeholder={`${item.props.name} ${item.i}`}
                              className="imageBlock"
    
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </ReactGridLayout>
                </Card>
              </div>
            </div>
          </div>
    
          <div className="flex gap-3">
            <button onClick={SaveForm} className="backBtn mt-3">
              Save
            </button>
            {/* <button onClick={() => {
              handleOpen();
            }} className="backBtn mt-3">
              Preview
            </button> */}
          </div>
    
    
          {/* <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="previewForm"
            >
              <Box sx={style}>
                <ReactGridLayout
                  className="layout"
                  layout={layout}
                  cols={12}
                  rowHeight={50}
                  isResizable={false}
                  isDraggable={false}
                  isDroppable={false}
                >
                  {layout.map((item: any) => (
                    <div
                      key={item.i}
                      data-grid={item}
                      className="gridDIv position-ralative"
                    >
                      {item.props.type === "text" && (
                        <textarea
                          name={item.props.type}
                          value={item?.props?.fieldMapping}
                          placeholder={`${item.props.name} ${item.i}`}
                          className="dragInputText resize-none border-0"
                          style={{
                            ...item.props?.style,
                            fontSize: `${item.props?.style?.fontSize}px`
                          }}
                        />
                      )}
                      {item.props.type === "image" && (
                        <div className="dragInput" >
                          {
                            item?.props?.value ?
                              <img src={item?.props?.value ?? ""} alt="img" className="dragInputImg p-1" />
                              :
                              <p>{item.props.fieldMapping}</p>
                          }
                          <input
                            type={"file"}
                            name={item.props.name}
                            hidden
                            ref={imageRef}
                            onChange={(e: any) => {
                              console.log("mmmmmmmmmmmmmmmmmmmm", e.target.files[0]);
                              uploader(e.target.files[0])
                            }}
                            placeholder={`${item.props.name} ${item.i}`}
                            className=""
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </ReactGridLayout>
              </Box>
            </Modal>
          </div> */}
        </div>
    
      );
    }

export default GenerateFormEdit