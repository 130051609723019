import { useGetEventsByIdQuery } from '@/Redux/Services/Events';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchRegisteredUserById, fetchUsers, updateCheckInByRegistrationId, updateOrSetApprovalStatus } from '../registerDetails.actions';
import * as Yup from "yup";
import Card from '@/Components/ui/Card';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateEventRegistrationByIdIsolatedMutation } from '@/Redux/Services/BaseQueryIsolated';
import { toast } from 'react-toastify';
import Textinput from '@/Components/ui/Textinput';
import Select from 'react-select';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import Button from '@/Components/ui/Button';


const RegisterUserEdit = () => {
  const params: any = useParams();
  const { data: eventDetails, error }: any = useGetEventsByIdQuery(params.id, {
    skip: !params.id
  });

  const [validationSchema, setValidationSchema] = useState<any>({})
  const [editableFields, setEditableFields] = useState<any>(null)
  const [userDetails, setUserDetails] = useState<any>({})


  const navigate = useNavigate();
  const location = useLocation();

  const users = location.state?.users || [];
  const currentIndex = users.findIndex((id) => id === params.registrationId);



  useEffect(() => {
    fetchUsersById(params.registrationId).then((response) => {
      console.log("Fetched users response  -", response)
      if (!(response?.error) && response?.userData) {
        setUserDetails(response.userData)
        reset(response.userData);
      }
    })
  }, [params.registrationId])


  const [updateEventRegistrationById] = useUpdateEventRegistrationByIdIsolatedMutation();

  useEffect(() => {

    if (eventDetails?.fields) {
      setValidationSchema(generateValidationSchema(eventDetails?.fields))
      setEditableFields(getEditableFields(eventDetails?.fields));
    }
  }, [eventDetails])



  const fetchUsersById = async (registrationId) => {
    const resp = await fetchRegisteredUserById(registrationId);
    return resp
  }

  const generateValidationSchema = (fields) => {


    const schema = {};

    fields.forEach((field) => {
      if (field.mandatory) {
        schema[field.label] = Yup.string().required(`${field.label} is required`);
      } else {
        schema[field.label] = Yup.string();
      }
    });
    return Yup.object(schema);
  };


  const getEditableFields = (fields) => {
    const editableFields = {};

    fields.forEach((field) => {
      if (field.display) {
        editableFields[field.label] = { ...field };
      }
      else {
        editableFields[field.label] = { ...field };
      }
    });
    return editableFields;
  }



  const getApprovalStatus = (userDetails) => {

    if (userDetails?.approval_status == "rejected") {
      return "Rejected"
    } else if (userDetails?.approvalRequired && userDetails?.approval_status == "approved") {
      return "Approved"
    } else if (userDetails?.approvalRequired && !(userDetails?.approval_status)) {
      return "Pending"
    } else if (!(userDetails?.approvalRequired)) {
      return "Approved"
    }
  }


  const handleApproveSelectedAndNext = async(data) => {

      const success = await updateOrSetApprovalStatus(params.registrationId, "approved")
      if(success){
        if (currentIndex < users.length - 1) {
          const nextUserId = users[currentIndex + 1];
          navigate(`/registered-details/${params.id}/${nextUserId}`, { state: { users } });
      } else {
          toast.success("Selected users have been updated!");
          navigate(`/registered-details/${params.id}`); 
      }
      }else{
        toast.error("Could not approve guest.");
      }

  }



  const onSubmit = async (data) => {
    console.log("Form Submitted:", data);
    // Perform API submission here

    const updatePayload = {
      payload: { ...userDetails, ...data },
      id: userDetails.key,
      eventId: params.id

    }
    try {
      const response = await updateEventRegistrationById(updatePayload).unwrap();
      if (response) {
        console.log("Status: 200");
        toast.success("User Registration Updated Succesfully")
        navigate(`/registered-details/${params.id}`);
      }
    } catch (error) {
      toast.error("Failed to update event")
    }
  };


  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm({
    defaultValues: userDetails,
    resolver: yupResolver(validationSchema),
  });

  return (
    <>
      <Card title={`Update Guest Details`} className="w-full" subtitle={undefined} headerslot={<div className='flex gap-2'>
        <div><Button
          text="Send Email"
          className="btn-dark btn-sm ml-1"
          onClick={() => { }}
          isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} div={undefined} /></div>
        <div><Button
          text="Send Whatsapp"
          className="btn-dark btn-sm ml-1"
          onClick={() => { }}
          isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} div={undefined} /></div>
        <div
          className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${userDetails?.checkedin?.default
            ? "bg-green-100 text-green-700" // Styles for true
            : "bg-red-100 text-red-700" // Styles for false
            }`}
        >
          {userDetails?.checkedin?.default ? "Checked In" : "Not Checked In"}
        </div>
        <div
          className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium 
    ${userDetails?.approval_status === "rejected"
              ? "bg-red-700 text-white" // Dark red for "Rejected"
              : userDetails?.approvalRequired && !userDetails?.approval_status
                ? "bg-red-100 text-red-700" // Light red for "Pending"
                : "bg-green-100 text-green-700" // Green for "Approved"
            }`}
        >
          {userDetails?.approval_status === "rejected"
            ? "Rejected"
            : userDetails?.approvalRequired && !userDetails?.approval_status
              ? "Pending"
              : "Approved"}
        </div>


      </div>
      } noborder={undefined}>
        <div className="conten-box ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 pt-2.5">
              {editableFields && Object.entries(editableFields).map(([key, value]) => {
                // if (key in userDetails) {
                let fieldConfig: any = value;
                // console.log("fieldConfig---", fieldConfig);return;
                if (fieldConfig.type == 'text') {
                  return (
                    <div key={key} >
                      <Textinput
                        label={key}
                        type="text"
                        placeholder={`${key}`}
                        name={key}
                        error={errors[key]}
                        register={register} readonly={undefined} value={undefined} icon={undefined} disabled={undefined} id={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} onChange={undefined} options={undefined} onFocus={undefined} defaultValue={undefined} />
                    </div>
                  )
                }
                else if (fieldConfig.type == 'dropdown') {
                  const dropDownValues = fieldConfig.dropDownValue.split(",").map((ele) => {
                    return { value: ele, label: ele }
                  });
                  return (
                    <div className='mt-4'>
                      <label className="block text-sm font-medium text-gray-700">{key}</label>
                      <Controller
                        control={control}
                        name={key}
                        render={({ field: { onChange, value, ref } }) => (
                          <Select
                            ref={ref}
                            className="react-select"
                            classNamePrefix="select"
                            options={dropDownValues}
                            value={dropDownValues.find(c => c.value === value)}
                            onChange={val => onChange(val?.value)}
                          />
                        )}
                      />
                    </div>
                  )
                }
                else if (fieldConfig.type == 'whatsapp') {
                  console.log("PHONE KEY ___", key)
                  return (<div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                    <Controller
                      name={key}
                      control={control}
                      defaultValue={""}
                      render={({ field }) => {
                        return (
                          <PhoneInput
                            {...field}
                            value={field.value?.toString() || ""}
                            defaultCountry="IN"
                            placeholder="Enter phone number"
                            className={`mt-1 block w-full rounded-md  ${errors.phone_number ? "border-red-500" : "border-gray-300 focus:border-indigo-500"
                              } shadow-sm focus:ring-indigo-500 sm:text-sm`}
                          />
                        )

                      }}
                    />
                    {errors[key]?.message && (
                      <p className="mt-1 text-sm text-red-600">{errors[key]?.message as string}</p>
                    )}
                  </div>)
                }
                else if (fieldConfig.type == 'number') {
                  return (
                    <div>
                      <Textinput
                        label={key}
                        type="number"
                        placeholder={`${key}`}
                        name={key}
                        error={errors[key]}
                        register={register} readonly={undefined} value={undefined} icon={undefined} disabled={undefined} id={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} onChange={undefined} options={undefined} onFocus={undefined} defaultValue={undefined} />
                    </div>
                  )
                }
                else if (fieldConfig.type == 'email') {
                  return (
                    <div>
                      <Textinput
                        label={key}
                        type="email"
                        placeholder={`${key}`}
                        name={key}
                        error={errors[key]}
                        register={register} readonly={undefined} value={undefined} icon={undefined} disabled={undefined} id={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} onChange={undefined} options={undefined} onFocus={undefined} defaultValue={undefined} />
                    </div>
                  )
                }
                // }
                return true;
              })}
            </div>
            <div className='flex justify-between mt-10'>
              <div>
                <Button
                  text="Check In"
                  className="btn-dark "
                  onClick={async () => {
                    console.log("Calling click")
                    const success = await updateCheckInByRegistrationId(params.registrationId, true);
                    if (success) {
                      // Re-fetch user details after updating the database
                      const response = await fetchUsersById(params.registrationId);
                      if (!(response?.error) && response?.userData) {
                        setUserDetails(response.userData);
                        reset(response.userData);
                      }
                    }
                  }}

                  isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} div={undefined} />
                <Button
                  text="Check Out"
                  className="btn-dark ml-1"
                  onClick={async () => {
                    const success = await updateCheckInByRegistrationId(params.registrationId, false);
                    if (success) {
                      // Re-fetch user details after updating the database
                      const response = await fetchUsersById(params.registrationId);
                      if (!(response?.error) && response?.userData) {
                        setUserDetails(response.userData);
                        reset(response.userData);
                      }
                    }
                  }}

                  isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} div={undefined} />
                <Button
                  text="Approve Guest"
                  className="btn-dark ml-1"
                  onClick={async () => {
                    const success = await updateOrSetApprovalStatus(params.registrationId, "approved")
                    if (success) {
                      // Re-fetch user details after updating the database
                      const response = await fetchUsersById(params.registrationId);
                      if (!(response?.error) && response?.userData) {
                        setUserDetails(response.userData);
                        reset(response.userData);
                      }
                    }

                  }} isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} div={undefined} />
                <Button
                  text="Reject Guest"
                  className="btn-dark ml-1"
                  onClick={
                    async () => {
                      const success = await updateOrSetApprovalStatus(params.registrationId, "rejected")
                      if (success) {
                        // Re-fetch user details after updating the database
                        const response = await fetchUsersById(params.registrationId);
                        if (!(response?.error) && response?.userData) {
                          setUserDetails(response.userData);
                          reset(response.userData);
                        }
                      }

                    }
                  } isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} div={undefined} />
                {users?.length > 1 && (<Button
                  text="Approve and Show Next Guest"
                  className="btn-dark ml-1"
                  onClick={handleApproveSelectedAndNext} isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} div={undefined} />)}
              </div>
              <div>
                <Button
                  text="Cancel"
                  className="btn-dark"
                  onClick={() => { navigate(`/registered-details/${params.id}`); }} isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} div={undefined} />
                <Button
                  text={"save"}
                  className="btn-dark ml-2"
                  type="submit" isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} onClick={undefined} div={undefined} />
              </div>
            </div>
          </form>
        </div>
      </Card>

    </>
  )
}

export default RegisterUserEdit