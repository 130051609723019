import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';

import Textinput from "@/Components/ui/Textinput";
import Checkbox from "@/Components/ui/Checkbox";
import Button from "@/Components/ui/Button";
import { auth } from '@/utils/firebase';
import { setToken } from '@/Redux/Store';

const schema = yup
  .object({
    email: yup.string().email("Invalid email").required("Email is Required"),
    password: yup.string().required("Password is Required"),
  })
  .required();

const LoginForm = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      )
      if (res.user) {
        toast.success("Login Successfully");
        localStorage.setItem(
          "event-register-app-is-auth",
          "true"
        );
        dispatch(setToken(res?.user?.stsTokenManager?.accessToken));
        navigate("/");
      }
    } catch (err) {
      setLoading(false);
      if (err.code === "auth/invalid-login-credentials") {
        toast.error("Invalid Login Credentials. Please try again.");
      }
    }
  };

  const [checked, setChecked] = useState(false);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 ">
      <Textinput
        name="email"
        label="email"
        type="email"
        placeholder=" Enter your email"
        register={register}
        error={errors.email}
        className="h-[48px]"
      />
      <Textinput
        hasicon
        name="password"
        label="password"
        type="password"
        placeholder=" Enter your password"
        register={register}
        error={errors.password}
        className="h-[48px]"
      />
      <div className="flex justify-between">
        <Checkbox
          value={checked}
          onChange={() => setChecked(!checked)}
          label="Keep me signed in"
        />
        <Link
          to="/forgot-password"
          className="text-sm text-slate-800 dark:text-slate-400 leading-6 font-medium"
        >
          Forgot Password?{" "}
        </Link>
      </div>

      <Button
        type="submit"
        text="Sign in"
        className="btn btn-dark block w-full text-center "
        isLoading={isLoading}
      />
    </form>
  );
};

export default LoginForm;
