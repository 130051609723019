import { toast } from "react-toastify";
import { collection, query, where, getDocs, documentId } from "firebase/firestore";

import get from "@/utils/get";
import { db } from '@/utils/firebase';

export const fetchScanStationsWithCheckinCounts = async (eventId) => {
  try {
    const q = query(collection(db, "Stations"), where("eventId", "==", eventId));
    const querySnapshot = await getDocs(q);
    const scanStationArray: any = [];
    for(const doc of querySnapshot?.docs) {
      const stationId = doc.id;
      const sq = query(collection(db, "Checkins"), where("eventId", "==", eventId), where("scanStationId", "==", stationId));
      const sQuerySnapshot = await getDocs(sq);
      const checkinCount = sQuerySnapshot?.docs.length;
      scanStationArray.push({
        ...doc.data(),
        checkinCount,
        key: stationId,
      });
    }
    return {scanStationArray, error: false};
  } catch (e) {
    console.log("error", e);
    toast.error(`Failed to fetch Scan stations with reason: ${e}`);
    return {error: true, scanStationArray: []};
  }
};

export const fetchScanStationNameWithId = async (scanStationId) => {
  try {
    let scanStationName = '';
    const q = query(collection(db, "Stations"), where(documentId(), "==", scanStationId));
    const querySnapshot = await getDocs(q);
    const firstDoc = querySnapshot?.docs[0];
    if(firstDoc) {
      scanStationName = firstDoc.data().name;
      return {scanStationName, errorMsg: ''};
    } else {
      return {scanStationName : '', errorMsg: 'Invalid Scan Station Id. Please check with admin!'};
    }
  } catch (e) {
    console.log("error", e);
    toast.error(`Failed to fetch Scan station name with reason: ${e}`);
    return {errorMsg: 'Failed to fetch Scan station name', scanStationName: ''};
  }
};

export const fetchScanStationsWithCheckinUsers = async (eventId, stationId) => {
  const usersArray : any = [];
  const checkedInTimeMap = {};
  try {
    const sq = query(collection(db, "Checkins"), where("eventId", "==", eventId), where("scanStationId", "==", stationId));
    const sQuerySnapshot = await getDocs(sq);
    const userIds = sQuerySnapshot?.docs.map((doc: any) => {
      const guestId = doc.data()?.guestId;
      checkedInTimeMap[guestId] = new Date(get(doc, '_document.createTime.timestamp.seconds') * 1000);
      return guestId;
    });
    if(userIds.length) {
      const q = query(collection(db, "Registrations"), where("_code", "in", userIds));
      const querySnapshot = await getDocs(q);
      querySnapshot?.docs?.forEach((doc) => {
        const data = doc.data();
        usersArray.push({
          ...data,
          checkedInTimeStamp: checkedInTimeMap[data['_code']].toLocaleTimeString(),
          key: doc.id,
        });
      });
      return {usersArray, errorMsg: ''};
    } else {
      return {errorMsg: '', usersArray: []};
    }
  } catch (e) {
    console.log("error", e);
    toast.error(`Failed to checkedin users with reason: ${e}`);
    return {errorMsg: 'Failed to checkedin users with reason', usersArray: []};
  }
};