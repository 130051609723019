import Button from '@/Components/ui/Button';
import Card from '@/Components/ui/Card';
import { useGetLabelsQuery } from '@/Redux/Services/Print'
import { Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useTable, useSortBy, usePagination } from 'react-table';
import Icon from "@/Components/ui/Icon";
const Listing = () => {


    const COLUMNS = [
        {
            Header: "Template Name",
            accessor: "name",
            Cell: (row: any) => {
                return <Tooltip arrow title={row?.cell?.value || '-'}><div style={{ maxWidth: '200px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{row?.cell?.value || '-'}</div></Tooltip>;
            },
            disableSortBy: false
        },
        {
            Header: "Size(width in mm)",
            accessor: "size",
            Cell: (row: any) => {
                return <span>{row?.cell?.value?.width || '-'}</span>;
            },
            disableSortBy: false
        }
    ]


    const params = useParams()
    const navigate = useNavigate()
    const { data: labelData, error, isLoading } = useGetLabelsQuery(params.id, {
        skip: !params.id
    });

    const [labelList, setLabelList] = useState<any>([])

    const columns: any = useMemo(() => COLUMNS, []);

    useEffect(() => {
        if (labelData) {
            setLabelList(labelData)
        }
    }, [labelData]);


    const tableInstance = useTable(
        {
            columns,
            data: labelList,
        },
        useSortBy,
        usePagination,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
    } = tableInstance;
    const { pageIndex, pageSize } = state;

    const handleRowClick = useCallback((row: any) => {
        navigate(`/print/${params.id}/${row?.original?.id}`)
    }, [navigate])




    return (
        <>
            <Card title="Labels List" subtitle={undefined} headerslot={<Button className="btn-dark mb-3 text-white" text="Create" isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} onClick={() => navigate(`/print/${params.id}/add`)} div={undefined} ></Button>} noborder={undefined} className="min-w-full" bodyClass={undefined}>
                <div className="overflow-x-auto">
                    <div className="inline-block min-w-full align-middle">
                        <div className="overflow-hidden ">
                            <table
                                className="min-w-full divide-y divide-slate-100 table-auto dark:divide-slate-700"
                                {...getTableProps}
                            >
                                <thead className="bg-slate-200 dark:bg-slate-700">
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column: any) => (
                                                <th
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    scope="col"
                                                    className=" table-th "
                                                >
                                                    {column.render("Header")}
                                                    <span>
                                                        {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                                                    </span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody
                                    className="bg-white divide-y divide-slate-100 dark:bg-slate-800 dark:divide-slate-700"
                                    {...getTableBodyProps}
                                >
                                    {page.map((row: any) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()} onClick={() => handleRowClick(row)}>
                                                {row.cells.map((cell: any) => {
                                                    //TO prevent time from breaking and break description
                                                    const isDescription = cell.column.id === "description";
                                                    const isTime = cell.column.id === "time";
                                                    return (
                                                        <td {...cell.getCellProps()} className="table-td min-w-full cursor-pointer"
                                                            style={{
                                                                maxWidth: isDescription ? "200px" : undefined,
                                                                wordBreak: isDescription ? "break-word" : undefined,
                                                                whiteSpace: isDescription ? "normal" : "nowrap",
                                                                minWidth: isTime ? "80px" : undefined,
                                                                textAlign: isTime ? "center" : "left",
                                                            }}
                                                        >

                                                            {cell.render("Cell")}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="md:flex md:space-y-0 space-y-5 justify-between mt-6 items-center">
          <div className=" flex items-center space-x-3 rtl:space-x-reverse">
            <select
              className="form-control py-2 w-max"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 25, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            <span className="text-sm font-medium text-slate-600 dark:text-slate-300">
              Page{" "}
              <span>
                {pageIndex + 1} of {pageOptions.length}
              </span>
            </span>
          </div>
          <ul className="flex items-center  space-x-3  rtl:space-x-reverse">
            <li className="text-xl leading-4 text-slate-900 dark:text-white rtl:rotate-180">
              <button
                className={` ${!canPreviousPage ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <Icon icon="heroicons:chevron-double-left-solid" className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined} />
              </button>
            </li>
            <li className="text-sm leading-4 text-slate-900 dark:text-white rtl:rotate-180">
              <button
                className={` ${!canPreviousPage ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Prev
              </button>
            </li>
            {pageOptions.map((page, pageIdx) => (
              <li key={pageIdx}>
                <button
                  aria-current="page"
                  className={` ${pageIdx === pageIndex
                      ? "bg-slate-900 dark:bg-slate-600  dark:text-slate-200 text-white font-medium "
                      : "bg-slate-100 dark:bg-slate-700 dark:text-slate-400 text-slate-900  font-normal  "
                    }    text-sm rounded leading-[16px] flex h-6 w-6 items-center justify-center transition-all duration-150`}
                  onClick={() => gotoPage(pageIdx)}
                >
                  {page + 1}
                </button>
              </li>
            ))}
            <li className="text-sm leading-4 text-slate-900 dark:text-white rtl:rotate-180">
              <button
                className={` ${!canNextPage ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
            </li>
            <li className="text-xl leading-4 text-slate-900 dark:text-white rtl:rotate-180">
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className={` ${!canNextPage ? "opacity-50 cursor-not-allowed" : ""
                  }`}
              >
                <Icon icon="heroicons:chevron-double-right-solid" className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined} />
              </button>
            </li>
          </ul>
        </div>
            </Card>
        </>
    )
}

export default Listing