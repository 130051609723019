import { baseApi } from "./BaseQuery";



const userManagementApi = baseApi.injectEndpoints({
  endpoints: (build) => ({

    postRoles: build.mutation({
      query: (payload) => ({
        url: `events/${payload.id}/roles`,
        method: "POST",
        body: payload.values
      }),
      invalidatesTags: ['Users']
    }),

    getRoles: build.query({
      query: (id) => ({
        url: `events/${id}/roles`,
        method: "GET"
      }),
      providesTags: ["Users"]
    }),

    postUsers: build.mutation({
      query: (payload) => ({
        url: `events/${payload.id}/users`,
        method: "POST",
        body: payload.values
      }),
      invalidatesTags: ['Users']
    }),

    getUsers: build.query({
      query: (id) => ({
        url: `events/${id}/users`,
        method: "GET"
      }),
      providesTags: ["Users"]
    }),

    postScanStation: build.mutation({
      query: (payload) => ({
        url: `events/${payload.id}/stations`,
        method: "POST",
        body: payload.values
      }),
      invalidatesTags: ['Users']
    }),

    getScanStation: build.query({
      query: (id) => ({
        url: `events/${id}/stations`,
        method: "GET"
      }),
      providesTags: ["Users"]
    }),
    getScanStationDetail: build.query({
      query: ({ eventId, stationId }) => ({
        url: `events/${eventId}/stations/${stationId}`,
        method: "GET"
      }),
      providesTags: ["Users"]
    }),

    getMailers: build.query({
      query: (id) => ({
        url: `events/${id}/mailers`,
        method: "GET"
      }),
      providesTags: ["Template"]
    }),

    postMailers: build.mutation({
      query: (payload) => ({
        url: `events/${payload.id}/mailers`,
        method: "POST",
        body: payload.values
      }),
      invalidatesTags: ['Template']
    }),

    getMailersStatus: build.query({
      query: (payload) => ({
        url: `events/${payload?.formId}/mailers/${payload?.mailerId}/status`,
        method: "GET"
      }),
      providesTags: ["Template"]
    }),


    postSendMailerStatus: build.mutation({
      query: (payload) => ({
        url: `events/${payload?.formId}/mailers/${payload?.mailerId}/run`,
        method: "POST",
      }),
      invalidatesTags: ['Template']
    }),


    updateMailers: build.mutation({
      query: (payload) => ({
        url: `events/${payload.id}/mailers`,
        method: "PUT",
        body: payload.values
      }),
      invalidatesTags: ['Template']
    }),


    updateSacnStationByid: build.mutation<any, any>({
      query: (updated) => ({
        url: `events/${updated.id}/stations/${updated.formId}`,
        method: "PUT",
        body: updated?.values,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['Users']
    }),

    deleteScanStation: build.mutation<any, any>({
      query: (updated) => ({
        url: `events/${updated.id}/stations/${updated.formId}`,
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['Users']
    }),

  }),
});

export const {
  usePostRolesMutation,
  useGetRolesQuery,
  usePostUsersMutation,
  useGetUsersQuery,
  usePostScanStationMutation,
  useGetScanStationQuery,
  useGetMailersQuery,
  usePostMailersMutation,
  useGetMailersStatusQuery,
  usePostSendMailerStatusMutation,
  useUpdateMailersMutation,
  useUpdateSacnStationByidMutation,
  useDeleteScanStationMutation,
  useGetScanStationDetailQuery,
  useLazyGetScanStationDetailQuery

} = userManagementApi;
