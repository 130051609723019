import { toast } from "react-toastify";
import { collection, query, where, getDocs, startAfter,  getCountFromServer, limit, and, endBefore, orderBy, limitToLast, documentId, doc, getDoc, updateDoc, setDoc } from "firebase/firestore";

import { db } from '@/utils/firebase';

export const fetchUsers = async (eventId) => {
  try {
    const q = query(collection(db, "Registrations"), where("_eventId", "==", eventId));
    const querySnapshot = await getDocs(q);
    const usersArray: any = [];
    querySnapshot?.docs?.forEach((doc) => {
      usersArray.push({
        ...doc.data(),
        key: doc.id,
      });
    });
    return {usersArray, error: false};
  } catch (e) {
    console.log("error", e);
    toast.error(`Failed to fetch Registered users with reason: ${e}`);
    return {error: true, usersArray: []};
  }
};

export const fetchRegisteredUserById = async (registrationId) => {
  try {
    console.log("registrationIdfetchUserById---", registrationId);

    const docRef = doc(db, "Registrations", registrationId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("doc--", docSnap.data());
      return { 
        userData: { ...docSnap.data(), key: docSnap.id }, 
        error: false 
      };
    } else {
      console.log("No such document!");
      throw new Error("User Registration not found")
    }
  } catch (e) {
    console.log("error", e);
    toast.error(`Failed to fetch Registered users with reason: ${e}`);
    return { error: true, usersArray: [] };
  }
}


// Function to update checkin
export const updateCheckInByRegistrationId = async (registrationId, checkedinStatus) => {
  try {
    console.log("Updating checkedin for registrationId: ", registrationId);
    const docRef = doc(db, "Registrations", registrationId);
    await updateDoc(docRef, {
      "checkedin.default": checkedinStatus, 
    });
    return true;
  } catch (error) {
    return false
    // toast.error("Failed to update city.");
  }
};

export const   updateOrSetApprovalStatus =async (registrationId, approval_status) =>  {
  const docRef = doc(db, "Registrations", registrationId);

  try {
    await setDoc(docRef, { ["approval_status"]: approval_status }, { merge: true });
    console.log("Field updated or set successfully!");
    return true;
  } catch (error) {
    console.error("Error updating or setting field:", error);
    return false;
  }
}




export const fetchUsersPaginated = async (
  eventId,
  pageSize,
  docRef = null,
  fetchPreviousPage = false,
   // Field to search
  searchValue = null, // Value to search
  searchField = null,
  orderByField = null // Field to order by
) => {
  try {


    const baseRef = collection(db, "Registrations");


    let baseQuery = query(
      baseRef,
      where("_eventId", "==", eventId),
      ...(searchField && searchValue ? [where(searchField, "==", searchValue)] : []),

      ...(orderByField ? [orderBy(orderByField)] : [orderBy(documentId())])
    );

    if (fetchPreviousPage === true) {
      baseQuery = query(baseQuery, ...(docRef ? [endBefore(docRef)] : []), limitToLast(pageSize));
    } else if(fetchPreviousPage === false) {
      baseQuery = query(baseQuery, ...(docRef ? [startAfter(docRef)] : []), limit(pageSize));
    } else{
      baseQuery = query(baseQuery, limit(pageSize));
    }

    
    const querySnapshot = await getDocs(baseQuery);
    const usersArray = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      key: doc.id,
    }));


    const countQuery = query(
      baseRef,
      where("_eventId", "==", eventId),
      ...(searchField && searchValue ? [where(searchField, "==", searchValue)] : [])
    );
    const countSnapshot = await getCountFromServer(countQuery);
    const totalRecords = countSnapshot.data().count;

    return {
      usersArray,
      firstDoc: querySnapshot.docs[0] || null, 
      lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1] || null,
      totalRecords,
      error: false,
    };
  } catch (e) {
    console.error("Failed to fetch registered users:", e);
    toast.error(`Failed to fetch Registered users with reason: ${e}`);
    return { error: true, usersArray: [], totalRecords: 0 };
  }
}


