import { Checkbox, Container, FormControl, FormGroup, InputLabel, MenuItem, Paper, Select, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import { Formik, Form, ErrorMessage, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid';
import FormLabel from "@mui/material/FormLabel";
import "./Event.css"
import DeleteIcon from '@mui/icons-material/Delete';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useCreateDynamicEventMutation, useCreateEventMutation, useGetEventsByIdQuery, useGetWhatsappTempQuery, useUpdateEventsByidMutation } from "../../Redux/Services/Events";
import { storage } from "../../utils/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useDispatch } from "react-redux";
import { loader } from "../../Redux/Services/StaticReducer";
import { QflowList } from "../../Constants/Utils";
import DeleteImg from "../../Assets/delete.svg"

const DynamicCreate = () => {
    const navigate = useNavigate()

    const imageRef = useRef<any>(null);
    const elementRef = useRef<any>(null);
    const [width, setWidth] = useState(0);
    const location = useLocation();
    const [imgData, setImgData] = useState<any>(null);
    const [imgDataLogo, setImgDataLogo] = useState<any>(null);
    const [progress, setProgress] = useState(0);
    const [initialValues, setInitialValues] = useState<any>({
        name: "",
        template: "",
        num_params: 0,
        params: [
        ],
        banner: "",
        logo: "",
        description: "",
        send_email: false,
        send_whatsapp: false,
        start_time: "",
        end_time: "",
        fields: [
            {
                label: "",
                mandatory: "optional",
                type: "text",
                qflow: "",
                separator: "",
                search: false,
                display: false,
                onSpotRegister: false
            }
        ],
        prefix: "",
        additional_guests: 0,
        qrFieldColor: "",
        colors: []
    });
    const [createEvent] = useCreateDynamicEventMutation()
    const [updateEvent] = useUpdateEventsByidMutation();
    const { data: getWaTemplate } = useGetWhatsappTempQuery("");
    const id: any = location?.pathname?.split("/")[2]
    const { data: getViewData }: any = useGetEventsByIdQuery(id, {
        skip: !id
    })



    const dispatch = useDispatch()
    useEffect(() => {
        setWidth(elementRef.current.offsetWidth);
        console.log('location', location?.state?.row)
    }, [window.onresize]);

    const LoginSchema = Yup.object({
        email: Yup.string(),
        whatsapp: Yup.string(),
        banner: Yup.string(),
        logo: Yup.string(),
        fields: Yup.array().of(
            Yup.object().shape({
                label: Yup.string().required("Label is required"),
            })
        ),
    })

    useEffect(() => {

        if (getViewData) {
            console.log("getViewData", getViewData);

            setInitialValues({
                ...initialValues,
                name: getViewData?.name ?? "",
                template: getViewData?.template ?? "",
                num_params: getViewData?.num_params ?? 0,
                params: getViewData?.params ?? [
                ],
                banner: getViewData?.banner ?? "",
                logo: getViewData?.logo ?? "",
                description: getViewData?.description ?? "",
                send_email: getViewData?.send_email ?? false,
                send_whatsapp: getViewData?.send_whatsapp ?? false,
                start_time: getViewData?.start_time ?? "",
                end_time: getViewData?.end_time ?? "",
                fields: getViewData?.fields ? getViewData?.fields?.map((res: any) => {
                    return {
                        ...res,
                        mandatory: res?.mandatory ? "mandatory" : "optional"
                    }
                }) : [
                    {
                        label: "",
                        mandatory: "optional",
                        type: "text",
                        qflow: "",
                        separator: "",
                        search: false,
                        display: false,
                        onSpotRegister: false
                    }
                ],
                prefix: getViewData?.prefix ?? "",
                additional_guests: getViewData?.additional_guests ?? 0,
                "qrFieldColor": getViewData?.qrFieldColor ?? "",
                "colors": getViewData?.colors ? Object.keys(getViewData?.colors)?.map(key => {
                    return {
                        key: key,
                        value: getViewData?.colors[key]
                    };
                }) : [],
                "qr-color": {
                    "field": getViewData?.qrFieldColor ?? "",
                    "colors": getViewData?.colors?.length > 0 ? Object.keys(getViewData?.colors)?.map(key => {
                        return {
                            key: key,
                            value: getViewData?.colors[key]
                        };
                    }) : []
                }
            })
            // initialValues.name = locationData.name;
            // initialValues.title = locationData.title
            // initialValues.eventId = locationData.qflowEventId
        }

    }, [getViewData])

    useEffect(() => {
        if (imgData) {
            imageRef.current.setFieldValue("banner", imgData);
        }
    }, [imgData])

    useEffect(() => {
        if (imgDataLogo) {
            imageRef.current.setFieldValue("logo", imgDataLogo);
            imageRef.current.setFieldError("logo", "")
        }
    }, [imgDataLogo])

    const convertMandatoryToBoolean = (mandatory: string) => {
        return mandatory === 'mandatory';
    };

    const uploader = (file: File, onComplete: (url: string) => void) => {
        dispatch(loader(true))
        const storageRef = ref(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: string) => {
                    onComplete(downloadURL)
                    setProgress(0)
                    dispatch(loader(false))
                });
            }
        );
    }

    const handleSubmitForm = (values: any) => {
        uploadData()
    }

    const handleUpdateEvents = (values: any) => {
        const updatedFileds = values?.fields?.map((person: { lable: string, mandatory: string }) => ({
            ...person,
            mandatory: convertMandatoryToBoolean(person.mandatory),
        }));

        let updatePayload = values?.colors?.map((res: any) => {
            return { [res.key]: res?.value }
        })


        let updateFilter = updatePayload?.reduce((acc: any, obj: any) => {
            return { ...acc, ...obj };
        }, {})


        const payload = {
            ...values,
            fields: updatedFileds,
            "colors": updateFilter,
            "qr-color": {
                "field": values?.qrFieldColor,
                "colors": updateFilter
            }
        }

        const updated = {
            payload: payload,
            id: id
        }
        console.log("payload = ", payload, values)
        dispatch(loader(true))
        updateEvent(updated).then((response: any) => {
            console.log("response", response);
            if (response?.data) {
                navigate("/")
                dispatch(loader(false))
            }
        })
    }


    function uploadData() {
        let values = imageRef?.current?.values
        console.log("fields = ", values, initialValues)
        const updatedFileds = values?.fields?.map((person: { lable: string, mandatory: string }) => ({
            ...person,
            mandatory: convertMandatoryToBoolean(person.mandatory),
        }));

        let updatePayload = values?.colors?.map((res: any) => {
            return { [res.key]: res?.value }
        })


        let updateFilter = updatePayload?.reduce((acc: any, obj: any) => {
            return { ...acc, ...obj };
        }, {})


        const payload = {
            ...values,
            fields: updatedFileds,
            "colors": updateFilter,
            "qr-color": {
                "field": values?.qrFieldColor,
                "colors": updateFilter
            }
        }
        console.log("XX: payload = ", payload)
        dispatch(loader(true))
        createEvent({ payload }).then((response: any) => {
            console.log("response", response);
            if (response?.data) {
                navigate("/")
                dispatch(loader(false))
            }
        })
    }


    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    return (
        <React.Fragment>
            <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <div className=''>
                        <button className='backBtn'>
                            <Tooltip title="Back">
                                <ArrowBackIcon className='' onClick={() => navigate("/")} />
                            </Tooltip>
                        </button>
                    </div>
                    <div>
                        <h1 className='heading'>Event - {initialValues.name}</h1>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={LoginSchema}
                        onSubmit={(values: any, { resetForm }) => {
                            if (id) {
                                handleUpdateEvents(values)
                            } else {
                                handleSubmitForm(values)
                            }
                            console.log("valueeeeeeeeeeeeeeeeeeeeeeee", values);

                        }}
                        innerRef={imageRef}
                        enableReinitialize
                    >
                        {({
                            handleBlur,
                            handleChange,
                            values,
                            errors,
                            setFieldValue,
                            touched,
                            setFieldError
                        }) => (
                            <Form className="mt-3">
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <FormLabel component="legend">
                                            Name
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="qrField"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                            name="name"
                                            label="Enter Name"
                                            fullWidth
                                            variant="outlined"
                                            className='formInput'
                                        />
                                        <ErrorMessage name="name" component="div" className="error-msg" />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <FormLabel component="legend">
                                            Prefix
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="qrField"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.prefix}
                                            name="prefix"
                                            label="Enter prefix"
                                            fullWidth
                                            variant="outlined"
                                            className='formInput'
                                        />
                                        <ErrorMessage name="prefix" component="div" className="error-msg" />
                                    </Grid>

                                    {/* <Grid item xs={4}>
                                        <FormLabel component="legend">
                                            Logo
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div className="add_profile_name_container">
                                            <img
                                                title=""
                                                className={
                                                    "prfile_img"
                                                }
                                                src={
                                                    values?.logo
                                                }
                                            />
                                        </div>
                                        <input
                                            className="add_profile_input"
                                            type="file"
                                            accept="image/jpeg, image/png, image/jpg"
                                            name="logo"
                                            onChange={(e: any) => {
                                                // onChangePictureLogo(e);
                                                uploader(e.target.files[0], (url: string) => {
                                                    setFieldValue("logo", url)
                                                })

                                            }}
                                        />
                                        <ErrorMessage name="logo" component="div" className="error-msg" />
                                    </Grid> */}

                                    <Grid item xs={4}>
                                        <FormLabel component="legend">
                                            Logo
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={8} className="">
                                        <div className="add_profile_name_container">
                                            <img
                                                title=""
                                                className={
                                                    "prfile_img"
                                                }
                                                src={
                                                    values?.logo
                                                }
                                            />
                                            <img src={DeleteImg} alt="DeleteImg" className="deleteImg" onClick={() => setFieldValue("logo", "")} />
                                        </div>
                                        <input
                                            className="add_profile_input"
                                            type="file"
                                            accept="image/jpeg, image/png, image/jpg"
                                            name="logo"
                                            onChange={(e: any) => {
                                                // onChangePictureLogo(e);
                                                uploader(e.target.files[0], (url: string) => {
                                                    setFieldValue("logo", url)
                                                })

                                            }}
                                        />
                                        <ErrorMessage name="logo" component="div" className="error-msg" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormLabel component="legend">
                                            Banner Image
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div style={{ position: "relative" }}>

                                            <img src={values?.banner} className="bannerImg" style={{ width: width }} />
                                            <img src={DeleteImg} alt="DeleteImg" className="deleteImgBanner" onClick={() => setFieldValue("banner", "")} />

                                        </div>
                                        <div className="Neon Neon-theme-dragdropbox">
                                            <input
                                                name="banner"
                                                type="file"
                                                accept="image/jpeg, image/png, image/jpg"
                                                className="uploadDiv"
                                                onChange={(e: any) => {
                                                    uploader(e.target.files[0], (url: string) => {
                                                        setFieldValue("banner", url)
                                                    })
                                                }} />
                                            <div className="Neon-input-dragDrop" ref={elementRef}>
                                                <div className="Neon-input-inner">
                                                    <div className="Neon-input-icon">
                                                        <i className="fa fa-file-image-o">
                                                        </i></div>
                                                    <div className="Neon-input-text">
                                                    </div>
                                                    <a className="Neon-input-choose-btn blue">Browse Files</a>
                                                </div>
                                            </div>
                                        </div>
                                        <ErrorMessage name="banner" component="div" className="error-msg" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormLabel component="legend">
                                            Description
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <textarea
                                            className='formInputDescription'
                                            name="description"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.description}
                                            placeholder="Enter description"
                                        />
                                        <ErrorMessage name="description" component="div" className="error-msg" />
                                    </Grid>






                                    <Grid item xs={4}>
                                        <FormLabel component="legend">
                                            Start Time
                                        </FormLabel>
                                    </Grid>


                                    <Grid item xs={8}>

                                        <TextField
                                            id="qrField"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.start_time}
                                            name="start_time"
                                            fullWidth
                                            variant="outlined"
                                            className='formInput'
                                            type="datetime-local"
                                        />
                                        <ErrorMessage name="start_time" component="div" className="error-msg" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormLabel component="legend">
                                            End Time
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={8}>

                                        <TextField
                                            id="qrField"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.end_time}
                                            name="end_time"
                                            fullWidth
                                            variant="outlined"
                                            className='formInput'
                                            type="datetime-local"
                                        />
                                        <ErrorMessage name="end_time" component="div" className="error-msg" />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <FormLabel component="legend">
                                            Email
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Checkbox {...label}
                                            checked={values.send_email}
                                            onChange={(e: any) => {
                                                setFieldValue("send_email", e.target.checked)
                                            }} />
                                    </Grid>


                                    <Grid item xs={4}>
                                        <FormLabel component="legend">
                                            Whatsapp
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Checkbox {...label}
                                            checked={values.send_whatsapp}
                                            onChange={(e: any) => {
                                                setFieldValue("send_whatsapp", e.target.checked);

                                            }} />
                                    </Grid>


                                    <Grid item xs={12}>
                                        <FormLabel component="legend">
                                            Add Fields
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FieldArray name="fields">
                                            {({ push, remove }) => (
                                                <div>
                                                    {values.fields?.map((p: any, index: any) => {
                                                        const label = `fields[${index}].label`;
                                                        const touchedFirstName = getIn(touched, label);
                                                        const errorFirstName = getIn(errors, label);

                                                        const value = `fields[${index}].mandatory`;

                                                        const labelType = `fields[${index}].type`;
                                                        const qflow = `fields[${index}].qflow`;
                                                        const separatorType = `fields[${index}].separator`;

                                                        const displayName = `fields[${index}].display`;
                                                        const searchName = `fields[${index}].search`;

                                                        const onSpotRegisterName = `fields[${index}].onSpotRegister`;

                                                        const dropDownValue = `fields[${index}].dropDownValue`;


                                                        return (
                                                            <div key={index} className="row border p-2 mt-2">
                                                                <TextField
                                                                    className="col-4 w-99"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    label="Label"
                                                                    name={label}
                                                                    value={p.label}
                                                                    helperText={
                                                                        touchedFirstName && errorFirstName
                                                                            ? errorFirstName
                                                                            : ""
                                                                    }
                                                                    error={Boolean(touchedFirstName && errorFirstName)}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <FormControl
                                                                    className="col-4 w-99"
                                                                    style={{ marginTop: "17px" }}

                                                                >
                                                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        name={labelType}
                                                                        value={p.type}
                                                                        label="Type"
                                                                        onChange={handleChange}
                                                                    >
                                                                        <MenuItem value={"text"}>Text</MenuItem>
                                                                        <MenuItem value={"date"}>Date</MenuItem>
                                                                        <MenuItem value={"number"}>Number</MenuItem>
                                                                        <MenuItem value={"whatsapp"}>Whatsapp</MenuItem>
                                                                        <MenuItem value={"email"}>Email</MenuItem>
                                                                        <MenuItem value={"dropdown"}>Dropdown</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                                {
                                                                    p.type === "dropdown" &&

                                                                    <TextField
                                                                        className="col-4 w-99"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        label="DropDown Values"
                                                                        name={dropDownValue}
                                                                        value={p.dropDownValue}
                                                                        helperText={
                                                                            touchedFirstName && errorFirstName
                                                                                ? errorFirstName
                                                                                : ""
                                                                        }
                                                                        error={Boolean(touchedFirstName && errorFirstName)}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                }
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                                    name={value}
                                                                    className="col-4 w-99"
                                                                    value={p.mandatory}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                >
                                                                    <FormControlLabel value="optional" control={<Radio />} label="Optional" />
                                                                    <FormControlLabel value="mandatory" control={<Radio />} label="Mandatory" />
                                                                </RadioGroup>
                                                                <FormControl
                                                                    style={{ marginTop: "17px" }}
                                                                    className="col-4 w-99"

                                                                >
                                                                    <InputLabel id="demo-simple-select-label">Q-flow</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        name={qflow}
                                                                        value={p.qflow}
                                                                        label="Q-flow"
                                                                        onChange={handleChange}
                                                                    >
                                                                        {
                                                                            QflowList?.map((res: { label: string, value: string }, index: number) => <MenuItem value={res?.value}>{res?.label}</MenuItem>)
                                                                        }

                                                                    </Select>
                                                                </FormControl>
                                                                <FormControl
                                                                    className="col-4 w-99"
                                                                    style={{ marginTop: "17px" }}

                                                                >
                                                                    <InputLabel id="demo-simple-select-label">separator</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        name={separatorType}
                                                                        value={p.separator}
                                                                        label="separator"
                                                                        onChange={handleChange}
                                                                    >
                                                                        <MenuItem value={","}>Comma</MenuItem>
                                                                        <MenuItem value={` `}>Space</MenuItem>

                                                                    </Select>
                                                                </FormControl>

                                                                <div className="col-4 d-flex">
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            control={<Checkbox
                                                                                checked={p.search}
                                                                                onChange={handleChange}
                                                                                name={searchName}
                                                                                color="primary"
                                                                            />}
                                                                            label="Search"
                                                                        />
                                                                        <FormControlLabel
                                                                            control={<Checkbox
                                                                                checked={p.display}
                                                                                onChange={handleChange}
                                                                                name={displayName}
                                                                                color="primary"
                                                                            />}
                                                                            label="Display"
                                                                        />
                                                                        <FormControlLabel
                                                                            control={<Checkbox
                                                                                checked={p.onSpotRegister}
                                                                                onChange={handleChange}
                                                                                name={onSpotRegisterName}
                                                                                color="primary"
                                                                            />}
                                                                            label="Spot Registration"
                                                                        />
                                                                    </FormGroup>
                                                                    {
                                                                        values?.fields?.length > 1 &&
                                                                        <Button
                                                                            onClick={() => remove(index)}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    })}


                                                    <Button
                                                        type="button"
                                                        className="mt-2"
                                                        variant="outlined"
                                                        onClick={() =>
                                                            push({
                                                                label: "", mandatory: "optional", type: "text", separator: "", qflow: "", search: false,
                                                                display: false, onSpotRegister: false
                                                            })
                                                        }
                                                    >
                                                        Add
                                                    </Button>
                                                </div>
                                            )}
                                        </FieldArray>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormLabel component="legend">
                                            Unique Identifier
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl fullWidth
                                        // style={{ marginTop: "17px", marginLeft: "10px", width: "30%" }}

                                        >
                                            <InputLabel id="demo-simple-select-label">Unique Identifier </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name={'unique_identifier'}
                                                value={values.unique_identifier}
                                                label="Unique identifier"
                                                onChange={handleChange}
                                            >
                                                {
                                                    values?.fields?.map((field: any, index: number) => <MenuItem value={field?.label}>{field?.label}</MenuItem>)
                                                }

                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <FormLabel component="legend">
                                            Template
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl fullWidth

                                        >
                                            <InputLabel id="demo-simple-select-label">Template</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Template"
                                                value={values.template}
                                                name="template"
                                                onChange={handleChange}
                                            >
                                                {getWaTemplate?.map((res: any, index: number) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={res?.name}
                                                        onClick={() => {
                                                            const paramsArray = Array.from({ length: res?.num_params }, () => ({
                                                                dropdown: '',
                                                                input: '',
                                                            }));
                                                            setFieldValue("params", paramsArray)
                                                            setFieldValue("num_params", res?.num_params)

                                                        }}
                                                    >
                                                        {res.name}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Grid>


                                    {/* <Grid item xs={12}> */}
                                    <FieldArray name="params">
                                        {({ push, remove }) =>
                                            values.params?.map((p: any, index: any) => (
                                                <>
                                                    <Grid item xs={4}>
                                                        <FormLabel component="legend">
                                                            {`param-${index + 1}`}
                                                        </FormLabel>
                                                    </Grid>

                                                    <Grid item xs={8}>
                                                        <div key={index} className="d-flex align-items-center gap-3">
                                                            <FormControl fullWidth className="mt-2">
                                                                <InputLabel id={`type-label-${index}`}>Dropdown</InputLabel>
                                                                <Select
                                                                    labelId={`type-label-${index}`}
                                                                    id={`type-select-${index}`}
                                                                    name={`params[${index}].dropdown`}
                                                                    value={values.params[index].dropdown}
                                                                    label="Dropdown"
                                                                    className=""
                                                                    onChange={handleChange}
                                                                >
                                                                    <MenuItem value="fixed">Fixed</MenuItem>
                                                                    <MenuItem value="field">Field</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                            <FormControl fullWidth >
                                                                <TextField
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    label=""
                                                                    name={`params[${index}].input`}
                                                                    value={values.params[index]?.input}
                                                                    onChange={handleChange}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </Grid>

                                                </>
                                            ))
                                        }
                                    </FieldArray>
                                    {/* </Grid> */}

                                    <Grid item xs={4}>
                                        <FormLabel component="legend">
                                            Number of additional guest
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            id="additional_guests"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.additional_guests}
                                            name="additional_guests"
                                            label="Enter additional guest"
                                            fullWidth
                                            variant="outlined"
                                            className='formInput'
                                            type="number"
                                        />
                                        <ErrorMessage name="additional_guests" component="div" className="error-msg" />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <FormLabel component="legend">
                                            Qr color Field
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl fullWidth
                                        // style={{ marginTop: "17px", marginLeft: "10px", width: "30%" }}

                                        >
                                            <InputLabel id="demo-simple-select-label">Qr color Field </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name={'qrFieldColor'}
                                                value={values.qrFieldColor}
                                                label="Qr color Field"
                                                onChange={handleChange}
                                            >
                                                {
                                                    values?.fields?.map((field: any, index: number) => <MenuItem key={index} value={field?.label}>{field?.label}</MenuItem>)
                                                }

                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <p>Colors</p>

                                        <FieldArray name="colors">
                                            {({ push, remove }) => {
                                                return (
                                                    <div className="">
                                                        {
                                                            values?.colors?.map((p: any, index: any) => (
                                                                <>
                                                                    <Grid container spacing={0} className="align-items-center border p-2 mt-4 position-relative">

                                                                        <Button
                                                                            onClick={() => remove(index)}
                                                                            className="deleteBtnPosition"
                                                                        >
                                                                            <DeleteIcon />
                                                                        </Button>

                                                                        <Grid item xs={4}>
                                                                            <FormLabel component="legend">
                                                                                Field value
                                                                            </FormLabel>
                                                                        </Grid>

                                                                        <Grid item xs={8}>
                                                                            <TextField
                                                                                margin="normal"
                                                                                variant="outlined"
                                                                                label=""
                                                                                style={{ width: "100%" }}
                                                                                name={`colors[${index}].key`}
                                                                                value={p?.key}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </Grid>


                                                                        <Grid item xs={4}>
                                                                            <FormLabel component="legend">
                                                                                Color
                                                                            </FormLabel>
                                                                        </Grid>

                                                                        <Grid item xs={8}>
                                                                            <TextField
                                                                                margin="normal"
                                                                                variant="outlined"
                                                                                label=""
                                                                                style={{ width: "100%" }}
                                                                                name={`colors[${index}].value`}
                                                                                value={p?.value}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </Grid>


                                                                    </Grid>
                                                                </>
                                                            ))
                                                        }


                                                        <Button
                                                            type="button"
                                                            className="mt-2"
                                                            variant="outlined"
                                                            onClick={() =>
                                                                push({
                                                                    key: "",
                                                                    value: "",
                                                                })
                                                            }
                                                        >
                                                            Add
                                                        </Button>
                                                    </div>
                                                )
                                            }


                                            }
                                        </FieldArray>
                                    </Grid>


                                    <Grid container className='d-flex justify-content-between'>
                                        <Grid item>
                                            <Button
                                                type="reset"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2, ml: 3 }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Container>
        </React.Fragment>

    )
};

export default DynamicCreate;
