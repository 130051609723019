class SmartFieldModule {
    // constructor(quill, options) {
    //     this.quill = quill;
    //     this.options = options;

    //     // Create the dropdown element
    //     const toolbar = quill.getModule('toolbar');
    //     if (toolbar) {
    //         this.addSmartFieldDropdown(toolbar.container);
    //     }
    // }

    // addSmartFieldDropdown(toolbar) {
    //     const dropdown = document.createElement('select');
    //     dropdown.className = `
    //         bg-gray-50 border border-gray-300 text-gray-900 text-sm 
    //         rounded-lg focus:ring-blue-500 focus:border-blue-500 
    //         block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 
    //         dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
    //     `;

    //     // Add placeholder option
    //     const placeholder = document.createElement('option');
    //     placeholder.textContent = 'Insert Smart Field';
    //     placeholder.setAttribute('value', '');
    //     placeholder.setAttribute('disabled', true);
    //     placeholder.setAttribute('selected', true);
    //     dropdown.appendChild(placeholder);

    //     // Add options dynamically
    //     this.options.fields.forEach(field => {
    //         const option = document.createElement('option');
    //         option.textContent = field.label;
    //         option.setAttribute('value', field.value);
    //         dropdown.appendChild(option);
    //     });

    //     // Handle insertion on selection
    //     dropdown.addEventListener('change', event => {
    //         const value = event.target.value;
    //         if (value) {
    //             const range = this.quill.getSelection();
    //             if (range) {
    //                 this.quill.insertText(range.index, value);
    //                 this.quill.setSelection(range.index + value.length);
    //             }
    //             dropdown.selectedIndex = 0; // Reset dropdown
    //         }
    //     });

    //     toolbar.appendChild(dropdown);
    // }


    // constructor(quill, options) {
    //     this.quill = quill;
    //     this.options = options;

    //     // Create the dropdown element
    //     const toolbar = quill.getModule('toolbar');
    //     if (toolbar) {
    //         this.addSmartFieldDropdown(toolbar.container);
    //     }
    // }

    // addSmartFieldDropdown(toolbar) {
    //     // Create wrapper for the dropdown
    //     const dropdownWrapper = document.createElement('div');
    //     dropdownWrapper.className = 'relative'; // Make sure the parent is relatively positioned
    //     toolbar.appendChild(dropdownWrapper);

    //     // Create dropdown button
    //     const dropdownButton = document.createElement('button');
    //     dropdownButton.className = `
    //         relative inline-flex items-center text-sm font-medium text-center text-gray-500 
    //         hover:text-gray-900 focus:outline-none dark:hover:text-white dark:text-gray-400
    //     `;
    //     dropdownButton.type = 'button';

    //     // Add icon to the button (same as in your example)
    //     const svgIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    //     svgIcon.setAttribute('class', 'w-5 h-5');
    //     svgIcon.setAttribute('aria-hidden', 'true');
    //     svgIcon.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    //     svgIcon.setAttribute('fill', 'currentColor');
    //     svgIcon.setAttribute('viewBox', '0 0 14 20');
    //     const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    //     path.setAttribute('d', 'M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z');
    //     svgIcon.appendChild(path);
    //     dropdownButton.appendChild(svgIcon);

    //     // Create the dropdown menu (initially hidden)
    //     const dropdownMenu = document.createElement('div');
    //     dropdownMenu.className = 'z-20 hidden absolute w-48 max-w-sm bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-800 dark:divide-gray-700';
    //     dropdownMenu.setAttribute('aria-labelledby', 'dropdownNotificationButton');

    //     // Add menu header
    //     const menuHeader = document.createElement('div');
    //     menuHeader.className = 'block px-4 py-2 font-medium text-center text-gray-700 rounded-t-lg bg-gray-50 dark:bg-gray-800 dark:text-white';
    //     menuHeader.textContent = 'Insert Smart Field';
    //     dropdownMenu.appendChild(menuHeader);

    //     // Add options dynamically to the dropdown menu
    //     const menuList = document.createElement('div');
    //     menuList.className = 'divide-y divide-gray-100 dark:divide-gray-700';
    //     this.options.fields.forEach(field => {
    //         const menuItem = document.createElement('a');
    //         menuItem.className = 'flex px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-700';
    //         menuItem.href = '#';
    //         menuItem.textContent = field.label;
    //         menuItem.addEventListener('click', () => this.handleFieldInsertion(field.value, dropdownMenu));
    //         menuList.appendChild(menuItem);
    //     });
    //     dropdownMenu.appendChild(menuList);

    //     // Append the button and dropdown menu to the wrapper
    //     dropdownWrapper.appendChild(dropdownButton);
    //     dropdownWrapper.appendChild(dropdownMenu);

    //     // Toggle dropdown menu visibility when button is clicked
    //     dropdownButton.addEventListener('click', () => {
    //         dropdownMenu.classList.toggle('hidden');
    //     });
    // }

    // handleFieldInsertion(value, dropdownMenu) {
    //     const range = this.quill.getSelection();
    //     if (range) {
    //         this.quill.insertText(range.index, value);
    //         this.quill.setSelection(range.index + value.length);
    //     }

    //     // Hide dropdown after selection
    //     dropdownMenu.classList.add('hidden');
    // }


    // constructor(quill, options) {
    //     this.quill = quill;
    //     this.options = options;

    //     // Create the dropdown element
    //     const toolbar = quill.getModule('toolbar');
    //     if (toolbar) {
    //         this.addSmartFieldDropdown(toolbar.container);
    //     }
    // }

    // addSmartFieldDropdown(toolbar) {
    //     // Create wrapper for the dropdown (using span with ql-formats and relative)
    //     const dropdownWrapper = document.createElement('span');
    //     dropdownWrapper.className = 'ql-formats relative'; // Apply relative positioning here
    //     toolbar.appendChild(dropdownWrapper);

    //     // Create dropdown button
    //     const dropdownButton = document.createElement('button');
    //     dropdownButton.className = `
    //         relative inline-flex items-center text-sm font-medium text-center text-gray-500 
    //         hover:text-gray-900 focus:outline-none dark:hover:text-white dark:text-gray-400
    //     `;
    //     dropdownButton.type = 'button';

    //     // Add icon to the button (same as in your example)
    //     const svgIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    //     svgIcon.setAttribute('class', 'w-5 h-5');
    //     svgIcon.setAttribute('aria-hidden', 'true');
    //     svgIcon.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    //     svgIcon.setAttribute('fill', 'currentColor');
    //     svgIcon.setAttribute('viewBox', '0 0 14 20');
    //     const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    //     path.setAttribute('d', 'M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z');
    //     svgIcon.appendChild(path);
    //     dropdownButton.appendChild(svgIcon);

    //     // Create the dropdown menu (initially hidden)
    //     const dropdownMenu = document.createElement('div');
    //     dropdownMenu.className = 'z-20 hidden absolute w-48 max-w-sm bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-800 dark:divide-gray-700';
    //     dropdownMenu.setAttribute('aria-labelledby', 'dropdownNotificationButton');

    //     // Add menu header
    //     const menuHeader = document.createElement('div');
    //     menuHeader.className = 'block px-4 py-2 font-medium text-center text-gray-700 rounded-t-lg bg-gray-50 dark:bg-gray-800 dark:text-white';
    //     menuHeader.textContent = 'Insert Smart Field';
    //     dropdownMenu.appendChild(menuHeader);

    //     // Add options dynamically to the dropdown menu
    //     const menuList = document.createElement('div');
    //     menuList.className = 'divide-y divide-gray-100 dark:divide-gray-700';
    //     this.options.fields.forEach(field => {
    //         const menuItem = document.createElement('a');
    //         menuItem.className = 'flex px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-700';
    //         menuItem.href = '#';
    //         menuItem.textContent = field.label;
    //         menuItem.addEventListener('click', () => this.handleFieldInsertion(field.value, dropdownMenu));
    //         menuList.appendChild(menuItem);
    //     });
    //     dropdownMenu.appendChild(menuList);

    //     // Append the button and dropdown menu to the wrapper
    //     dropdownWrapper.appendChild(dropdownButton);
    //     dropdownWrapper.appendChild(dropdownMenu);

    //     // Toggle dropdown menu visibility when button is clicked
    //     dropdownButton.addEventListener('click', () => {
    //         dropdownMenu.classList.toggle('hidden');
    //     });
    // }

    // handleFieldInsertion(value, dropdownMenu) {
    //     const range = this.quill.getSelection();
    //     if (range) {
    //         this.quill.insertText(range.index, value);
    //         this.quill.setSelection(range.index + value.length);
    //     }

    //     // Hide dropdown after selection
    //     dropdownMenu.classList.add('hidden');
    // }


    constructor(quill, options) {
        this.quill = quill;
        this.options = options;

        // Create the dropdown element
        const toolbar = quill.getModule('toolbar');
        if (toolbar) {
            this.addSmartFieldDropdown(toolbar.container);
        }
    }

    addSmartFieldDropdown(toolbar) {
        // Create wrapper for the dropdown (using span with ql-formats and relative)
        const dropdownWrapper = document.createElement('span');
        dropdownWrapper.className = 'ql-formats relative'; // Apply relative positioning here
        toolbar.appendChild(dropdownWrapper);

        // Create dropdown button
        const dropdownButton = document.createElement('button');
        dropdownButton.className = `
            relative inline-flex items-center text-sm font-medium text-center text-gray-500 
            hover:text-gray-900 focus:outline-none dark:hover:text-white dark:text-gray-400
        `;
        dropdownButton.type = 'button';

        // Add icon to the button (same as in your example)
        const svgIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svgIcon.setAttribute('class', 'w-5 h-5');
        svgIcon.setAttribute('aria-hidden', 'true');
        svgIcon.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        svgIcon.setAttribute('fill', 'currentColor');
        svgIcon.setAttribute('viewBox', '0 0 14 20');
        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        path.setAttribute('d', 'M12.133 10.632v-1.8A5.406 5.406 0 0 0 7.979 3.57.946.946 0 0 0 8 3.464V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C1.867 13.018 0 13.614 0 14.807 0 15.4 0 16 .538 16h12.924C14 16 14 15.4 14 14.807c0-1.193-1.867-1.789-1.867-4.175ZM3.823 17a3.453 3.453 0 0 0 6.354 0H3.823Z');
        svgIcon.appendChild(path);
        dropdownButton.appendChild(svgIcon);

        // Create the dropdown menu (initially hidden)
        const dropdownMenu = document.createElement('div');
        dropdownMenu.className = 'z-20 hidden absolute w-48 max-w-sm bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-800 dark:divide-gray-700';
        dropdownMenu.setAttribute('aria-labelledby', 'dropdownNotificationButton');

        // Add menu header
        const menuHeader = document.createElement('div');
        menuHeader.className = 'block px-4 py-2 font-medium text-center text-gray-700 rounded-t-lg bg-gray-50 dark:bg-gray-800 dark:text-white';
        menuHeader.textContent = 'Insert Smart Field';
        dropdownMenu.appendChild(menuHeader);

        // Add options dynamically to the dropdown menu
        const menuList = document.createElement('div');
        menuList.className = 'divide-y divide-gray-100 dark:divide-gray-700';
        this.options.fields.forEach(field => {
            const menuItem = document.createElement('a');
            menuItem.className = 'flex px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-700';
            menuItem.href = '#';
            menuItem.textContent = field.label;
            menuItem.addEventListener('click', () => this.handleFieldInsertion(field.value, dropdownMenu));
            menuList.appendChild(menuItem);
        });
        dropdownMenu.appendChild(menuList);

        // Append the button and dropdown menu to the wrapper
        dropdownWrapper.appendChild(dropdownButton);
        dropdownWrapper.appendChild(dropdownMenu);

        // Toggle dropdown menu visibility when button is clicked
        dropdownButton.addEventListener('click', (event) => {
            event.stopPropagation(); // Prevent the click event from propagating to the document
            dropdownMenu.classList.toggle('hidden');
        });

        // Close the dropdown when clicking outside of it
        document.addEventListener('click', (event) => {
            if (!dropdownWrapper.contains(event.target)) {
                dropdownMenu.classList.add('hidden');
            }
        });
    }

    handleFieldInsertion(value, dropdownMenu) {
        const range = this.quill.getSelection();
        if (range) {
            this.quill.insertText(range.index, value);
            this.quill.setSelection(range.index + value.length);
        }

        // Hide dropdown after selection
        dropdownMenu.classList.add('hidden');
    }
}

export default SmartFieldModule;
