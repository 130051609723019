import Flatpickr from "react-flatpickr";
import { Controller, useWatch } from "react-hook-form";
import Textinput from "@/Components/ui/Textinput";
import Textarea from "@/Components/ui/Textarea";
import Switch from "@/Components/ui/Switch";
import Image from "./Image";
import { storage } from "@/utils/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useEffect, useRef, useState } from 'react';
import { ALLOWED_IMAGE_EXTENSIONS } from '../createEventWizard.constants';
import Fileinput from "@/Components/ui/Fileinput";
import ControlledQuill from "@/Components/ui/ControlledComponents/ControlledTextEditor";

type EventDetailsProps = {
  errors: any;
  register: any;
  control: any;
  setValue: any,
  getValues: any,
  setError: any,
  clearErrors: any,
  isDirty: any,
};

const EventDetails = ({ errors, register, control, setValue, getValues, setError, clearErrors, isDirty }: EventDetailsProps) => {

  // const [bannerImagePreview, setBannerImagePreview] = useState(getValues('bannerImage'));
  // const [logoImagePreview, setLogoImagePreview] = useState(getValues('logo'));


  const [qRImagePreview, setQrImagePreview] = useState(getValues('paymentQRImage'));

  const paymentQRImage = useWatch({
    control,
    name: "paymentQRImage",
  });

  const paymentRequired = useWatch({
    control,
    name: "paymentRequired",
  })

  useEffect(() => {

    if (paymentQRImage != qRImagePreview && paymentQRImage instanceof FileList && paymentQRImage.length > 0) {
      const file = paymentQRImage[0];

      if (file instanceof File) {
        const fileExtension = file.name.split('.').pop()?.toUpperCase();
        if (fileExtension && ALLOWED_IMAGE_EXTENSIONS.indexOf(fileExtension) > -1) {
          onPaymentQRImageFileChange(file);
          // handleFileToCrop(file, 'bannerImage');
        }
      }
    } else if (typeof paymentQRImage === 'string') {
      setQrImagePreview(paymentQRImage)
    }
  }, [paymentQRImage]);


  const onPaymentQRImageFileChange = async (file) => {
    uploader(file, (url: string) => {
      setQrImagePreview(url);
      setValue('paymentQRImage', url)
    })
  }


  const restrictToNonNegative = (e) => {
    const value = parseInt(e.target.value);
    if (isNaN(value)) {
      setValue('additionalGuests', "0")
    }
    const validValue = Math.max(0, value);
    setValue('additionalGuests', validValue.toString())
  }

  const restrictBetweenZeroAndTen = (e) => {
    const value = parseInt(e.target.value);
    if (isNaN(value)) {
      setValue('additionalGuests', "0")
    }
    const validValue = Math.min(Math.max(0, value), 10);
    setValue('additionalGuests', validValue.toString())
  }


  const startDateTimeSelected = useWatch({
    control,
    name: "startDateTime",
  })


  const handleStartDateChange = (selectedDate: Date) => {
    const startDate = selectedDate ? +selectedDate : null;
    const endDate = getValues("endDateTime");

    if (endDate && startDate && startDate > endDate) {
      setValue("endDateTime", null);
    }
    setValue("startDateTime", startDate);
  };

  const handleEndDateChange = (selectedDate: Date) => {
    const endDate = selectedDate ? +selectedDate : null;
    const startDate = getValues("startDateTime");

    if (startDate && endDate && endDate < startDate) {
      setValue("startDateTime", null);
    }
    setValue("endDateTime", endDate);
  };


  const uploader = (file: File, onComplete: (url: string) => void) => {
    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: string) => {
          onComplete(downloadURL)
        });
      }
    );
  }



  return (
    <div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 pt-2.5">
        <div className="lg:col-span-3 md:col-span-2 col-span-1">
          <h4 className="text-base text-slate-800 dark:text-slate-300">
            Enter Event Details
          </h4>
        </div>
        <Textinput
          label="Name"
          type="text"
          placeholder="Type Event Name"
          name="name"
          error={errors.name}
          register={register} readonly={undefined} value={undefined} icon={undefined} disabled={undefined} id={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} onChange={undefined} options={undefined} onFocus={undefined} defaultValue={undefined} />
        {/* <Textarea
          label="Description"
          type="text"
          placeholder="Write Description"
          name="description"
          error={errors.description}
          register={register} readonly={undefined} dvalue={undefined} icon={undefined} disabled={undefined} id={undefined} horizontal={undefined} validate={undefined} msgTooltip={undefined} description={undefined} cols={undefined} onChange={undefined} /> */}
        
        <div className="col-span-2">
        <ControlledQuill
          name="description"
          control={control}
          label="Description"
          rules={{ required: 'Description is required' }}
        />
        </div>

        <Textinput
          label="Number of Additional Guests"
          type="number"
          placeholder="Enter No. of Additional Guests"
          name="additionalGuests"
          error={errors.additionalGuests}
          register={register} readonly={undefined} value={undefined} icon={undefined} disabled={undefined} id={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} onChange={restrictBetweenZeroAndTen} options={undefined} onFocus={undefined} defaultValue={undefined} />
        <div className="fromGroup">
          <label className="form-label" htmlFor="startDateTime">
            Start Time
          </label>
          <Controller
            control={control}
            name="startDateTime"
            render={({ field: { onChange, value, ref, ...props }, fieldState: { error } }) => (
              <div className={`fromGroup ${error && 'has-error'}`}>
                <Flatpickr
                  {...props}
                  value={value}
                  data-enable-time
                  className="form-control py-2"
                  placeholder="Enter Start Date & Time"
                  options={{
                    minDate: 'today',
                    altInput: true,
                    altFormat: "F j, Y - G:i K",
                  }}
                  onChange={([date]) => handleStartDateChange(date)}
                />
                {error && (<div className="mt-2 text-danger-500 block text-sm">{error.message}</div>)}
              </div>
            )}
          />
        </div>
        <div>
          <label className="form-label" htmlFor="endDateTime">
            End Time
          </label>
          <Controller
            control={control}
            name="endDateTime"
            render={({ field: { onChange, value, ref, ...props }, fieldState: { error } }) => (
              <div className={`fromGroup ${error && 'has-error'}`}>
                <Flatpickr
                  {...props}
                  value={value}
                  data-enable-time
                  className="form-control py-2"
                  placeholder="Enter End Date & Time"
                  options={{
                    minDate: startDateTimeSelected || 'today',
                    altInput: true,
                    altFormat: "F j, Y - G:i K",
                  }}
                  onChange={([date]) => handleEndDateChange(date)}
                />
                {error && (<div className="mt-2 text-danger-500 block text-sm">{error.message}</div>)}
              </div>
            )}
          />
        </div>
        {/* <div className="lg:col-span-1 md:col-span-1 col-span-1"> */}
        <div className="flex justify-center flex-wrap sm:justify-start">
        <Controller
          name="approvalRequired"
          control={control}
          render={({ field }: any) => <Switch
            prevIcon={undefined} nextIcon={undefined} id={undefined} badge={undefined} label="Approval Required"
            {...field}
          />}
        />
        <Controller
          name="paymentRequired"
          control={control}
          render={({ field }: any) => <Switch
            prevIcon={undefined} nextIcon={undefined} id={undefined} badge={undefined} label="Payment Required"
            {...field}
          />}
        />
        </div>
           {/* </div> */}
        {/* <Controller
          name="sendEmail"
          control={control}
          render={({ field }: any) => <Switch
            prevIcon={undefined} nextIcon={undefined} id={undefined} badge={undefined} label="Email"
            {...field}
          />}
        />
        <Controller
          name="sendWhatsapp"
          control={control}
          render={({ field }: any) => <Switch
            prevIcon={undefined} nextIcon={undefined} id={undefined} badge={undefined} label="Whatsapp"
            {...field}
          />}
        /> */}
        {/* <div>
          
        </div> */}
        
        {paymentRequired && (<Textinput
          label="Payment UPI ID"
          type="text"
          placeholder="Enter UPI ID"
          name="paymentUPI"
          error={errors.paymentUPI}
          register={register} readonly={undefined} value={undefined} icon={undefined} disabled={undefined} id={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} onChange={undefined} options={undefined} onFocus={undefined} defaultValue={undefined} />)}

        {paymentRequired && (<Textinput
          label="Payment Amount"
          type="number"
          placeholder="Enter payment amount"
          name="paymentAmount"
          error={errors.paymentAmount}
          register={register} readonly={undefined} value={undefined} icon={undefined} disabled={undefined} id={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} onChange={restrictToNonNegative} options={undefined} onFocus={undefined} defaultValue={undefined} />)}
        {paymentRequired && (<div>
          <label className="form-label">
            Payment QR Code
          </label>
          <Fileinput
            accept="image/jpeg, image/png, image/jpg"
            placeholder="Choose QR Code"
            name="paymentQRImage"
            error={errors?.paymentQRImage}
            register={register}
            preview
            selectedFile={qRImagePreview}
            multiple={false} id={undefined} badge={undefined} selectedFiles={undefined} onChange={undefined} />
        </div>)}


      </div>
      <div className="border-t-2 border-gray-300 mt-5 pt-5">
        <Image errors={errors} register={register} setValue={setValue} control={control} getValues={getValues} />
      </div>

      <div className="border-t-2 border-gray-300 mt-5 pt-5 grid md:grid-cols-2 grid-cols-1 gap-5">
        <div className="md:col-span-2 col-span-1">
          <h4 className="text-base text-slate-800 dark:text-slate-300 mb-3 md:mb-6">
             Registration Confirmation Message
          </h4>
        </div>
        <ControlledQuill
          name="regConfirmationMessage"
          control={control}
          label="Registration Message Before Event Day"
          rules={{ required: 'Description is required' }} />
        
        <ControlledQuill
          name="onSiteRegConfirmationMessage"
          control={control}
          label="On Site Registration Message, Event Day"
          rules={{ required: 'Description is required' }} />

      </div>
    </div>
  );
}

export default EventDetails;

