export const STEPS = [
  {
    title: "Event Details",
  },
  {
    title: "Create Fields",
  },
  {
    title: "QR Configuration",
  },
  // {
  //   title: "Registration Configuration",
  // },
  {
    title: "Communication",
  },
];

export const TYPE_OPTIONS = [
  { label: "Text", value: "text" },
  { label: "Date", value: "date" },
  { label: "Number", value: "number" },
  { label: "Whatsapp", value: "whatsapp" },
  { label: "Email", value: "email" },
  { label: "Dropdown", value: "dropdown" },
];

export const ALLOWED_IMAGE_EXTENSIONS = ['JPG', 'JPEG', 'PNG'];