import { baseApi } from "./BaseQuery";


const printApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getLabels : build.query({
            query: (id) => ({
              url: `events/${id}/labels`,
              method: "GET"
            }),
          }),

        deleteLabelById: build.mutation<any, any>({
        query: (payload) => ({
            url: `events/${payload.id}/labels/${payload.labelId}`,
            method: "DELETE",
        }),
        invalidatesTags: ['Events']
        })
    })
})

export const {      
  useGetLabelsQuery,
  useDeleteLabelByIdMutation,
  
  } = printApi;
  