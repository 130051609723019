import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from '@mui/material/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel, FormGroup, MenuItem, Select, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { usePostScanStationMutation, useUpdateSacnStationByidMutation } from '../../Redux/Services/Usermanagement';

function AddScanStation() {

    const initialValues = {
        name: "",
        maxCount: "",
    }

    const LoginSchema = Yup.object({
        name: Yup.string().required(`Station name is required`),
        maxCount: Yup.string().required(`Count is required`),
    })

    const navigate = useNavigate();
    const location = useLocation();
    const formId = location?.pathname?.split("/")[2] ?? ""
    const isFirst = location?.state?.isFirst;
    const [addScanStation] = usePostScanStationMutation();
    const [updateSacnStationByid] = useUpdateSacnStationByidMutation();

    console.log("location", location?.state?.row);

    useEffect(() => {
        initialValues.name = location?.state?.row?.name
        initialValues.maxCount = location?.state?.row?.maxCount
    }, [location?.state])

    const submitLogin = async (payloads: any) => {
        let payload = {
            id: formId,
            values: {...payloads, default: isFirst},
        }

        addScanStation(payload)
            .unwrap()
            .then((res: any) => {
                if (res) {
                    navigate(`/scan-station/${formId}`)
                }
            })
            .catch((error) => {

            })
    };


    const updateScan = async (payloads: any) => {
        let payload = {
            id: formId,
            values: payloads,
            formId: location?.state?.row?.id
        }

        updateSacnStationByid(payload)
            .unwrap()
            .then((res: any) => {
                if (res) {
                    navigate(`/scan-station/${formId}`)
                }
            })
            .catch((error) => {

            })
    };

    return (
        <React.Fragment>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>

                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <div className='d-flex justify-content-between mb-3'>
                        <button className='backBtn'>
                            <Tooltip title="Back">
                                <ArrowBackIcon className='' onClick={() => navigate(`/scan-station/${formId}`)} />
                            </Tooltip>
                        </button>
                        <h4 className='text-center'>{location?.state?.row ? "Edit Scan Station" : "Add Scan Station"}</h4>
                        <div></div>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={LoginSchema}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) => {
                            if (location?.state?.row) {
                                updateScan(values)
                            } else {
                                submitLogin(values)
                            }
                        }}
                    >
                        {({
                            handleBlur,
                            handleChange,
                            values,
                            setFieldValue,
                            setFieldError
                        }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="campaignName"
                                            label="Scan Station Name"
                                            placeholder='Enter your Scan Station Name'
                                            fullWidth
                                            variant="outlined"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                            name="name"
                                            className='formInput'
                                        />
                                        <ErrorMessage name="name" component="div" className="error-msg" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="qrField"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.maxCount}
                                            name="maxCount"
                                            label="limit count"
                                            placeholder='Enter your Count'
                                            fullWidth
                                            variant="outlined"
                                            className='formInput'
                                        />
                                        <ErrorMessage name="maxCount" component="div" className="error-msg" />
                                    </Grid>


                                    <Grid container className='d-flex justify-content-center gap-5'>
                                        <Grid item>
                                            <Button
                                                type="reset"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2, ml: 3 }}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Container>
        </React.Fragment>
    );
}
export default AddScanStation;