import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from '@mui/material/Button/Button';
import "./RegisterForm.css";
import { RegistrationResponse, useRegisterMutation } from '../../Redux/Services/RegisterForm';

declare global {
  interface Window {
    Cashfree: any;
  }
}

function RegisterForm() {

  const initialValues = {
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: ""
  }

  const LoginSchema = Yup.object({
    first_name: Yup.string().required(`First name is required`),
    last_name: Yup.string().required(`Last name is required`),
    mobile_number: Yup.string().required(`Mobile number is required`),
    email: Yup.string().required(`Email is required`).email("Valid email is required")
  })

  const [postRegister, registrationResponse] = useRegisterMutation();



  const cashfree = window.Cashfree({
    mode: "sandbox" //or production
  });

  const FrontEndUrl = window.location.origin;

  const cashfreeSubmit = (sessionId: string, orderId: string) => {
    let checkoutOptions = {
      paymentSessionId: sessionId,
      returnUrl: `${FrontEndUrl}/cashfree-redirect?order_id=${orderId}`,
    }

    cashfree.checkout(checkoutOptions).then(function (result: any) {
      console.log("Redirection", result)
      if (result.error) {
        alert(result.error.message)
      }
      if (result.redirect) {
      }
    });
  };

  /**
   *
   *
   * @param {*} values
   * after success the register api need to pass sessionID and orderId to cashfreeSubmit function
   */

  const handleSubmitForm = (values: any) => {
    postRegister({ values })
  }

  useEffect(() => {
        console.log('registrationResponse', registrationResponse)
        const sessionId = registrationResponse.data?.cfSessionId;
        const orderId = registrationResponse.data?.cfOrderId;
        if (sessionId && orderId) {
          cashfreeSubmit(sessionId, orderId)
        }
    
  }, [registrationResponse])

  return (
    <React.Fragment>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <h4 className='text-center'>Register for events</h4>
          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            enableReinitialize={true}
            onSubmit={(values, { resetForm }) => {
              handleSubmitForm(values)
            }}
          >
            {({
              handleBlur,
              handleChange,
              values,
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="campaignName"
                      label="Enter First Name"
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                      name="first_name"
                      className='formInput'
                    />
                    <ErrorMessage name="first_name" component="div" className="error-msg" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="qrField"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                      name="last_name"
                      label="Enter Last Name"
                      fullWidth
                      variant="outlined"
                      className='formInput'
                    />
                    <ErrorMessage name="last_name" component="div" className="error-msg" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="campaignName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobile_number}
                      name="mobile_number"
                      label="Enter Mobile Number"
                      fullWidth
                      variant="outlined"
                      className='formInput'
                    />
                    <ErrorMessage name="mobile_number" component="div" className="error-msg" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="campaignName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      name="email"
                      label="Enter Email"
                      fullWidth
                      variant="outlined"
                      className='formInput'
                    />
                    <ErrorMessage name="email" component="div" className="error-msg" />
                  </Grid>
                  <Grid container className='d-flex justify-content-between'>
                    <Grid item>
                      <Button
                        type="reset"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, ml: 3 }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Container>
    </React.Fragment>
  );
}
export default RegisterForm;