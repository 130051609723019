import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from '@mui/material/Button/Button';
import Link from '@mui/material/Link';
import EyeTrue from "../../Assets/eyeactive.svg";
import EyeFalse from "../../Assets/eyeinactive.svg";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'react-toastify';
import { addDoc, collection } from "firebase/firestore";
import { auth, db } from '../../utils/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel, FormGroup, MenuItem, Select, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useGetRolesQuery, usePostUsersMutation } from '../../Redux/Services/Usermanagement';

function AddUser() {

    const navigate = useNavigate();
    const location = useLocation();
    const formId = location?.pathname?.split("/")[2] ?? ""

    const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
        role: "",
        phone_number: ""
    }

    const LoginSchema = Yup.object({
        first_name: Yup.string().required(`First name is required`),
        last_name: Yup.string().required(`Last name is required`),
        role: Yup.string().required(`Role is required`),
        email: Yup.string().required(`Email is required`).email("Valid email is required"),
        phone_number: Yup.string().required(`Phone number is required`),
    })
    const [postUsers] = usePostUsersMutation();

    const {data:getRoles} = useGetRolesQuery(formId)


    console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkk", getRoles);
    const submitLogin = async (payloads: any) => {
        let payload = {
            id: formId,
            values: payloads
        }

        postUsers(payload)
            .unwrap()
            .then((res: any) => {
                if(res){
                    navigate(`/user-management/${formId},`,{state:{value:location?.state?.value}})
                }
            })
            .catch((error) => {

            })
    };


    console.log("LOCSTATE--",location?.state?.value)

    return (
        <React.Fragment>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>

                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <div className='d-flex justify-content-between mb-3'>
                        <button className='backBtn'>
                            <Tooltip title="Back">
                                <ArrowBackIcon className='' onClick={() => navigate(`/user-management/${formId}`,{state:{value:location?.state?.value}})} />
                            </Tooltip>
                        </button>
                        <h4 className='text-center'>Add user</h4>
                        <div></div>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={LoginSchema}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) => {
                            submitLogin(values)
                        }}
                    >
                        {({
                            handleBlur,
                            handleChange,
                            values,
                            setFieldValue,
                            setFieldError
                        }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="campaignName"
                                            label="First Name"
                                            placeholder='Enter your first name'
                                            fullWidth
                                            variant="outlined"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.first_name}
                                            name="first_name"
                                            className='formInput'
                                        />
                                        <ErrorMessage name="first_name" component="div" className="error-msg" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="qrField"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.last_name}
                                            name="last_name"
                                            label="Last Name"
                                            placeholder='Enter your last name'
                                            fullWidth
                                            variant="outlined"
                                            className='formInput'
                                        />
                                        <ErrorMessage name="last_name" component="div" className="error-msg" />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            id="campaignName"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            name="email"
                                            label="Email"
                                            placeholder='Enter your email'
                                            fullWidth
                                            variant="outlined"
                                            className='formInput'
                                        />
                                        <ErrorMessage name="email" component="div" className="error-msg" />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <PhoneInput
                                            defaultCountry="IN"
                                            placeholder="Enter phone number"
                                            value={values.phone_number}
                                            onChange={(value: any) => {
                                                if (value) {
                                                    if (isValidPhoneNumber(value)) {
                                                        setFieldValue("phone_number", value)
                                                        setFieldError("phone_number", '')

                                                    } else {
                                                        setFieldError("phone_number", `InValid Phone Number`)
                                                    }
                                                }
                                            }} />
                                        <ErrorMessage name={"phone_number"} component="span" className="error-msg" />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={values?.role}
                                            name='role'
                                            style={{ width: "100%" }}
                                            onChange={handleChange}
                                            placeholder='Select Role'
                                            displayEmpty
                                        >
                                            <MenuItem disabled value="">
                                                <em>select role</em>
                                            </MenuItem>
                                            {
                                                getRoles?.map((res:any,index:number)=>{
                                                    return  <MenuItem key={index} value={res.role_name}>{res?.role_name}</MenuItem>
                                                })
                                            }
                                          
                                        </Select>
                                        <ErrorMessage name={"role"} component="span" className="error-msg" />
                                    </Grid>

                                    <Grid container className='d-flex justify-content-center gap-5'>
                                        <Grid item>
                                            <Button
                                                type="reset"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2, ml: 3 }}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Container>
        </React.Fragment>
    );
}
export default AddUser;