import { useEffect } from "react";

export function useBeforeUnload(isDirty , setIsFormDirtied) {
    useEffect(() => {
        useEffect
      const handleBeforeUnload = (e) => {
        if (isDirty) {
          const message = "You have unsaved changes. Are you sure you want to leave?";
          e.preventDefault();
          e.returnValue = message;
          return message;
        }
      };
  
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, [isDirty,setIsFormDirtied]);
  }