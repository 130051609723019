import React from 'react'

import { Quill } from "react-quill";
import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import ImageUploader from "quill-image-uploader";
import QuillResizeImage from 'quill-resize-image';
// import { IndentStyle } from './indent.js'
import SmartFieldModule from './SmartFieldModule';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "@/utils/firebase";


const CustomUndo = () => (
    <svg viewBox="0 0 18 18">
        <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
        <path
            className="ql-stroke"
            d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
        />
    </svg>
);


// Redo button icon component for Quill editor
const CustomRedo = () => (
    <svg viewBox="0 0 18 18">
        <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
        <path
            className="ql-stroke"
            d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
        />
    </svg>
);


// Quill.register('modules/smartField', SmartFieldModule);
// configure Quill to use inline styles so the email's format properly
// Quill.register(IndentStyle, true)
var DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);

var AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);

var BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass, true);

var ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass, true);

var DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass, true);

var FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass, true);

var SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass, true);

var AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

var BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);

var ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);

var DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);

var FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);

var SizeStyle = Quill.import('attributors/style/size');
Quill.register(SizeStyle, true);

//   // Undo and redo functions for Custom Toolbar
// function undoChange() {
//     this.quill.history.undo();
//   }
//   function redoChange() {
//     this.quill.history.redo();
//   }

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");

Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/resize", QuillResizeImage);


Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
    "arial",
    "comic-sans",
    "courier-new",
    "georgia",
    "helvetica",
    "lucida"
];
Quill.register(Font, true);

const fields = [
    { label: 'Full Name', value: '{{full name}}' },
    { label: 'Email Address', value: '{{email}}' },
    { label: 'Phone Number', value: '{{phone}}' },
  ];


  const uploader = (file: File, onComplete: (url: string) => void) => {
    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: string) => {
          onComplete(downloadURL)
        });
      }
    );
  }
  

export const modules = {
    toolbar: {

        container: "#toolbar",
        //   handlers: {
        //     undo: undoChange,
        //     redo: redoChange
        //   }
    },
    // smartField: { fields },

    history: {
        delay: 500,
        maxStack: 100,
        userOnly: true
    },
    imageUploader: {

        upload: (file) => {
            return new Promise((resolve, reject) => {
                uploader(file, (url) => {
                    if (url) {
                        resolve(url); // Resolve the Promise with the URL
                    } else {
                        reject(new Error("Failed to upload file")); // Reject in case of failure
                    }
                });
            });
        },


        // upload: (file) => {
        //     let url = ''
        //     const url = uploader(file, (url: string) => {
        //         return url
        //         // setLogoImagePreview(url);
        //         // setValue('logo', url);
        //       });




        //     // return new Promise((resolve, reject) => {
        //     //     setTimeout(() => {
        //     //         resolve(
        //     //             "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/480px-JavaScript-logo.png"
        //     //         );
        //     //     }, 3500);
        //     // });
        // },
    },
    resize: {
        locale: { center: "center", },
    },
};


export const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    // "script",
    // "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "code-block"
];


const EditorToolbar = ({ editorRef ,fields }) => {


    function insertStar() {
        console.log("Insert Star")
        //  const value = event.target.value;
            if (editorRef && editorRef.current) {
                const quill = editorRef.current.getEditor();
                const range = quill.getSelection();
                // if (range) {
                //     quill.insertText(range.index, value);
                //     quill.setSelection(range.index + value.length); // Move the cursor after the inserted text
                // }
            }
      }

    const letFIeld= [
        { label: 'Full Name', value: '{{full name}}' },
        { label: 'Email Address', value: '{{email}}' },
        { label: 'Phone Number', value: '{{phone}}' },
    ]


    // Custom Undo button icon component for Quill editor. You can import it directly
    // from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
    // handle them correctly

    const handleInsertSmartField = (event) => {

        console.log('evnt')
        // const value = event.target.value;
        // if (editorRef && editorRef.current) {
        //     const quill = editorRef.current.getEditor();
        //     const range = quill.getSelection();
        //     if (range) {
        //         quill.insertText(range.index, value);
        //         quill.setSelection(range.index + value.length); // Move the cursor after the inserted text
        //     }
        // }
    };


    // const handleInsertSmartField = (value) => {
    //     console.log("current REf--",editorRef)
    //     console.log("current REf--",value)
    //     if (editorRef && editorRef.current) {
    //         const quill = editorRef.current.getEditor();
    //         const range = quill.getSelection();
    //         if (range) {
    //             quill.insertText(range.index, value);
    //             quill.setSelection(range.index + value.length); // Move cursor after the inserted text
    //         }
    //     }
    // };

    const CustomButton = () => <span className="octicon octicon-star" />;



    return (
        <div id="toolbar">
            {/* <span className="ql-formats">
                <select className="ql-font" defaultValue="">
                    <option value="" disabled>Select Smart Field</option>
                    {fields.map((field, index) => (
                        <option key={index} value={field.value}>
                            {field.label}
                        </option>
                    ))}
                </select>
            </span> */}
            <span className="ql-formats">
                <select className="ql-font" defaultValue="arial">
                    <option value="arial">Arial</option>
                    <option value="comic-sans">Comic Sans</option>
                    <option value="courier-new">Courier New</option>
                    <option value="georgia">Georgia</option>
                    <option value="helvetica">Helvetica</option>
                    <option value="lucida">Lucida</option>
                </select>
                <select className="ql-size" defaultValue="medium">
                    <option value="extra-small">Size 1</option>
                    <option value="small">Size 2</option>
                    <option value="medium">Size 3</option>
                    <option value="large">Size 4</option>
                </select>
                <select className="ql-header" defaultValue="3">
                    <option value="1">Heading</option>
                    <option value="2">Subheading</option>
                    <option value="3">Normal</option>
                </select>
            </span>
            <span className="ql-formats">
                <button className="ql-bold" />
                <button className="ql-italic" />
                <button className="ql-underline" />
                <button className="ql-strike" />
                <button className="ql-list" value="ordered" />
                <button className="ql-list" value="bullet" />
                <button className="ql-indent" value="-1" />
                <button className="ql-indent" value="+1" />
            </span>
            {/* <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span> */}
            {/* <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-blockquote" />
      <button className="ql-direction" />
    </span> */}
            <span className="ql-formats">
                <select className="ql-align" />
                <select className="ql-color" />
                <select className="ql-background" />
            </span>
            <span className="ql-formats">
                <button className="ql-link" />
                <button className="ql-image" />
                {/* <button className="ql-video" /> */}
            </span>
            <span className="ql-formats">
                {/* <button className="ql-formula" /> */}
                {/* <button className="ql-code-block" /> */}
                <button className="ql-clean" />
            </span>
            {/* <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span> */}
        </div>
    )
}

export default EditorToolbar