import { useNavigate, useLocation, unstable_useBlocker } from "react-router-dom";
import { useEffect } from "react";

export function useNavigationBlocker(isDirty) {
  const blocker = unstable_useBlocker(() => isDirty);

  useEffect(() => {
    if (blocker.state === "blocked") {
      const confirmLeave = window.confirm(
        "Changes you made may not be saved."
      );

      if (confirmLeave) {
        blocker.proceed();
      } else {
        blocker.reset(); 
      }
    }
  }, [blocker]);
}