import React from 'react';
import { Controller } from 'react-hook-form';
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';

const ControlledSelect = ({ name, control, label, options, rules, defaultValue = '', ...rest }) => {
    return (

        <Controller
            name={name}
            control={control}
            // defaultValue={defaultValue}
            // rules={rules}
            render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth style={{ marginBottom: '16px' }}>
                    <InputLabel id={`${name}-label`}>{label}</InputLabel>
                    <Select {...field} {...rest}
                    size='small'
                    label={label}
                    labelId={`${name}-label`} 
                    id={name}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{error ? error.message : ""}</FormHelperText>
                </FormControl>
            )}
        />

    );
};

export default ControlledSelect;