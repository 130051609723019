import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';


const ControlledTextField = ({ name, control, label, rules, defaultValue = "", ...rest }) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        // rules={rules}
        render={({ field, fieldState: { error } }) => (
          <TextField
          size="small"
            {...field}
            label={label}
            error={!!error}
            helperText={error ? error.message : ""}
            variant="outlined"
            fullWidth
            InputLabelProps={{
              style: { fontSize: "0.875rem" }, 
            }}
            {...rest}
          />
        )}
      />
    );
  };
  
  export default ControlledTextField;