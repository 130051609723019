import { checkIsUserAuthenticated } from "@/Constants/Utils";
import { createSlice } from "@reduxjs/toolkit";

const isAuth = checkIsUserAuthenticated();

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    emailId: '',
    isAuth,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload.token;
      state.emailId = action.payload.emailId;
      state.isAuth = true;
    },
    logOut: (state) => {
      state.token = null;
      state.emailId = '';
      state.isAuth = false;
    },
  },
});

export const { setToken, logOut } = authSlice.actions;
export default authSlice.reducer;
