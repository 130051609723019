import { useEffect, Suspense } from 'react';
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { onAuthStateChanged } from "firebase/auth";
import { motion } from "framer-motion";

import Loader from '@/Components/Loader';
import Header from "@/Components/partials/header";
import Sidebar from '@/Components/partials/sidebar';
import MobileMenu from "@/Components/partials/sidebar/MobileMenu";

import useWidth from '@/hooks/useWidth';
import useMobileMenu from '@/hooks/useMobileMenu';

import { setToken, logOut, loader } from '@/Redux/Store';
import type { RootState } from '@/Redux/Store';

import { auth } from '@/utils/firebase';

function PrivateRoute() {
  const { width, breakpoints } = useWidth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isAuth, emailId} = useSelector((state: RootState) => state.auth);
  const isLoading = useSelector((state: RootState) => state.static.loader);
  const [mobileMenu, setMobileMenu] = useMobileMenu();

  useEffect(() => {
    dispatch(loader(true));
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then((token) => {
          dispatch(setToken({token, emailId: user.email}));
          dispatch(loader(false));
        })
      } else {
        dispatch(logOut());
        dispatch(loader(false));
        localStorage.setItem(
          "event-register-app-is-auth",
          "false"
        );
        toast.error("Your token has expired please re-login", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/");
      }
    });
    return () => {
      unsubscribe();
    }
  }, []);

  return isAuth ? (
    <>
    {!isLoading  && <Header className={width > breakpoints.xl ? "ltr:ml-[248px] rtl:mr-[248px]" : ""} emailId={emailId}/>}
    {width > breakpoints.xl && (
        <Sidebar />
      )}
      <MobileMenu
        className={`${
          width <= breakpoints.xl && mobileMenu
            ? "left-0 visible opacity-100  z-[9999]"
            : "left-[-300px] invisible opacity-0  z-[-999] "
        }`}
      />
      {width < breakpoints.xl && mobileMenu && (
        <div
          className="overlay bg-slate-900/50 backdrop-filter backdrop-blur-sm opacity-100 fixed inset-0 z-[999]"
          onClick={() => setMobileMenu(false)}
        ></div>
      )}
      <div
        className={`content-wrapper transition-all duration-150 ${
          width > 1280 ? "ltr:ml-[248px] rtl:mr-[248px]" : ""
        }`}
      >
        {/* md:min-h-screen will h-full*/}
        <div className="page-content   page-min-height  ">
          <div
            className="container-fluid"
          >
    <Suspense fallback={<Loader />}>
    {!isLoading && (<motion.div
                key={window.location.pathname}
                initial="pageInitial"
                animate="pageAnimate"
                exit="pageExit"
                variants={{
                  pageInitial: {
                    opacity: 0,
                    y: 50,
                  },
                  pageAnimate: {
                    opacity: 1,
                    y: 0,
                  },
                  pageExit: {
                    opacity: 0,
                    y: -50,
                  },
                }}
                transition={{
                  type: "tween",
                  ease: "easeInOut",
                  duration: 0.5,
                }}
              >
                <Outlet />
              </motion.div>)}
            </Suspense>
            </div></div></div>
    </>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
}

export default PrivateRoute;