import { Table, TableBody, TableContainer, TableHead, TableSortLabel, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react"
import { StyledTableCell, StyledTableRow } from "../../Constants/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { useGetMailersStatusQuery } from "../../Redux/Services/Usermanagement";


const headCellsRoles = [
    {
        id: "s_no",
        label: "S.No",
    },
    {
        id: "email",
        label: "Email",
    },
    {
        id: "status",
        label: "Status",
    },
    {
        id: "timestamp",
        label: "Timestamp",
    },
];

function EnhancedTableHeadRoles(props: any) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: string) => (event: any) => {
        onRequestSort(event, property);
    };

    const [header, setHeader] = useState(headCellsRoles);


    return (
        <TableHead className="infoTableHead">
            <StyledTableRow>
                {header?.map((headCell: any, index: any) => {
                    return (
                        <StyledTableCell
                            key={index}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                <p className="infotable-headText">{headCell.label}</p>
                            </TableSortLabel>
                        </StyledTableCell>
                    );
                })}
            </StyledTableRow>
        </TableHead>
    );
}


const Status = () => {
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("first_name");
    const [searchArray, setSearchArray] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const formId = location?.pathname?.split("/")[2] ?? ""
    const mailerId = location?.pathname?.split("/")[4]
    let payload = {
        formId: formId,
        mailerId: mailerId
    }

    const { data: status } = useGetMailersStatusQuery(payload)

    const handleRequestSort = (
        event: any,
        property: React.SetStateAction<string>
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    useEffect(() => {
        setSearchArray([])
    }, [])



    return (
        <div className="container table">

            <TableContainer className="">
                <Table
                    className={"mt-4 table"}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                >
                    <EnhancedTableHeadRoles
                        classes={""}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={searchArray?.length}

                    />
                    <TableBody>
                        {
                            status?.map((row: any, index: any) => {

                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <StyledTableRow hover key={index}>
                                        <StyledTableCell
                                            component="th"
                                            id={labelId}
                                            key={index}
                                            scope="row"
                                        >
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell className="cursor-pointer">{row?.email}</StyledTableCell>
                                        <StyledTableCell className="cursor-pointer">{row?.status}</StyledTableCell>
                                        <StyledTableCell className="cursor-pointer">{row?.timestamp}</StyledTableCell>

                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
};

export default Status;
