import { useState } from "react";
import Textinput from "@/Components/ui/Textinput";

const GlobalFilter = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter);
  const onChange = (e) => {
    setValue(e.target.value);
    setFilter(e.target.value || undefined);
  };
  return (
    <div className="md:flex justify-between items-center mb-6 flex justify-between  items-center">
      <Textinput
        value={value || ""}
        onChange={onChange}
        placeholder=" Search in users..." type={undefined} label={undefined} register={undefined} name={undefined} readonly={undefined} error={undefined} icon={undefined} disabled={undefined} id={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} options={undefined} onFocus={undefined} defaultValue={undefined}      />
    </div>
  );
};

export default GlobalFilter;
