import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from './Redux/Store';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { MantineProvider } from '@mantine/core';


//used createBrowserRouter instead of BrowserRouter to make use of useBlocker *revert if unregular behaviour
const router = createBrowserRouter(createRoutesFromElements(<Route path='*' element={<App />} />));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <BrowserRouter> */}
    <Provider store={store}>
      <MantineProvider>
        {/* <App /> */}
        <RouterProvider router={router} />
        <ToastContainer />
      </MantineProvider>
    </Provider>
    {/* </BrowserRouter> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
