import { useFieldArray, Controller, useWatch } from "react-hook-form";
import Select from 'react-select';
import { motion, AnimatePresence } from "framer-motion";

import Textinput from "@/Components/ui/Textinput";
import Icon from "@/Components/ui/Icon";
import Button from "@/Components/ui/Button";
import Switch from "@/Components/ui/Switch";
import Card from "@/Components/ui/Card";
import get from '@/utils/get';
import DropdownValues from "./DropdownValues";
import { TYPE_OPTIONS } from "../createEventWizard.constants";
import { useEffect, useState } from "react";
import { Tab, Tabs, TextField } from "@mui/material";
import ControlledTextField from "@/Components/ui/ControlledComponents/ControlledTextField";
import ControlledSelect from "@/Components/ui/ControlledComponents/ControlledSelect";

type CreateFieldsProps = {
  errors: any,
  register: Function,
  control: any,
}


const CreateFieldsTabbed = ({ errors, register, control }: CreateFieldsProps) => {
  

  const additionalGuests = useWatch({
    control,
    name: "additionalGuests",
  })


  const { fields: mainFields, append: appendMain, remove: removeMain } = useFieldArray({
    control,
    name: "fields",
  });

  const { fields: additionalGuestsFields, append: appendAdditional, remove: removeAdditional } =
    useFieldArray({
      control,
      name: "additionalGuestsFields", // For the second tab
    });

  const [activeTab, setActiveTab] = useState(0);



  useEffect(() => {
    if (additionalGuests > 0) {
      // Check if there are errors in the "additionalGuestsFields" tab
      const additionalGuestErrors = get(errors, "additionalGuestsFields") ?? [];
      if (additionalGuestErrors.length > 0 && activeTab !== 1) {
        setActiveTab(1);
      }
    }
  }, [errors, additionalGuests, activeTab]);

  const renderFields = (
    fields: any[],
    append: Function,
    remove: Function,
    name: string,
    allFieldsRemoveable: boolean
  ) => (
    <AnimatePresence>
      {fields.map((field, index, arr) => (
        <motion.div
          key={field.id}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Card
            bodyClass="p-3"
            className="card-fields relative overflow-y-visible overflow-x-visible mt-2"
            title={undefined} subtitle={undefined} headerslot={undefined} noborder={undefined}
          >
            <div className="lg:grid-cols-4 md:grid-cols-2 grid-cols-1 grid gap-2 mb-1 last:mb-0">
              {/* <TextField/> */}
              <ControlledTextField
                name={`${name}[${index}].label`}
                control={control}
                label="Label"
                rules={{ required: "First name is required" }}
              />
              {/* <Textinput
                    label="Label"
                    type="text"
                    id={`label${index}`}
                    placeholder="Enter Label"
                    register={register}
                    name={`${name}[${index}].label`} // Dynamic name
                    error={get(errors, `${name}.${index}.label`)}
                    readonly={undefined} value={undefined}  icon={undefined} disabled={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} onChange={undefined} options={undefined} onFocus={undefined} defaultValue={undefined}  

                  /> */}
              <div>
                {/* <label htmlFor="type" className="form-label ">
                      Type
                    </label>
                    <Controller
                      control={control}
                      name={`${name}[${index}].type`}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          ref={ref}
                          className="react-select"
                          classNamePrefix="select"
                          options={TYPE_OPTIONS}
                          value={TYPE_OPTIONS.find((c) => c.value === value)}
                          onChange={(val) => onChange(val?.value)}
                        />
                      )}
                    /> */}
                <ControlledSelect
                  name={`${name}[${index}].type`}
                  control={control}
                  label="Type"
                  options={TYPE_OPTIONS}
                  rules={{ required: "Type is required" }}
                />
              </div>
              <DropdownValues parentIndex={index} control={control} register={register} namePrefix={name} />
              <div className="lg:grid-cols-3 md:grid-cols-3 grid-cols-3 grid lg:gap-5 md:gap-5 mb-2 last:mb-0">
              <Controller
                name={`${name}[${index}].mandatory`}
                control={control}
                render={({ field }: any) => (
                  <Switch  labelClass= {"text-slate-500 dark:text-slate-400 text-sm font-medium mr-4"} labelPosition="bottom" {...field} label="Mandatory" />
                )}
              />
              <Controller
                name={`${name}[${index}].search`}
                control={control}
                render={({ field }: any) => <Switch labelClass= {"text-slate-500 dark:text-slate-400 text-sm font-medium mr-4"} labelPosition="bottom" {...field} label="Search" />}
              />
              <Controller
                name={`${name}[${index}].display`}
                control={control}
                render={({ field }: any) => <Switch labelClass= {"text-slate-500 dark:text-slate-400 text-sm font-medium mr-4"} labelPosition="bottom" {...field} label="Display" />}
              />
            </div>
            </div>
            
            {(arr.length > 1) && (
              <div className="flex-none absolute -right-5 -top-5 delete-btn">
                <button
                  onClick={() => remove(index)}
                  type="button"
                  className="inline-flex items-center justify-center h-10 w-10 bg-danger-500 text-lg border rounded border-danger-500 text-white"
                >
                  <Icon icon="heroicons-outline:trash"
                    className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined}
                  />
                </button>
              </div>
            )}
          </Card>
        </motion.div>
      ))}
      <div className="flex justify-center w-full mt-3 md:mt-6">
        <Button
          onClick={() =>
            append({
              label: "",
              display: false,
              mandatory: false,
              search: false,
              type: TYPE_OPTIONS[0].value,

            })
          }
          text="Add Fields"
          className="btn-outline-dark rounded-[999px] text-right"
          isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} div={undefined}
        />
      </div>
    </AnimatePresence>
  );


  return (<>
    {additionalGuests > 0 ? (
      <>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
        >
          <Tab label="Fields" />
          <Tab label="Additional Guest Fields" />
        </Tabs>
        {activeTab === 0 && renderFields(mainFields, appendMain, removeMain, "fields", false)}
        {activeTab === 1 && renderFields(additionalGuestsFields, appendAdditional, removeAdditional, "additionalGuestsFields", true)}

      </>
    ) : (
      renderFields(mainFields, appendMain, removeMain, "fields", false)
    )}

  </>)
}
export default CreateFieldsTabbed;