import './App.scss';
import { Routes, Route, Navigate } from "react-router-dom";
import { PublicRouteComponents, RoutesComponents } from './Routes/Route';
import PrivateRoute from './Layout/AppLayout';
import PublicRoute from './Layout/PublicRoute';
import Loader from './Components/Loader/Loader';
import { useSelector } from 'react-redux';
import { RootState } from './Redux/Store';
import Header from './Components/Header/Header';
import "simplebar-react/dist/simplebar.min.css";
import "flatpickr/dist/themes/light.css";
import "cleave.js/dist/addons/cleave-phone.us";

function App() {
  const loader = useSelector((state: RootState) => state.static.loader);

  const getPrivateRoutes = () => {
    return RoutesComponents.map((prop: any, key: number) => {
      let Component = prop.component;
      return <Route path={prop.path} element={<Component />} key={key} />;
    });
  };

  const getRoutes = () => {
    return PublicRouteComponents.map((prop, key) => {
      let Component = prop.component;
      return <Route path={prop.path} element={<Component />} key={key} />;
    });
  };

  return (
    <>
      {loader && <Loader />}
      <Routes>
        <Route element={<PublicRoute />}>{getRoutes()}</Route>
        <Route element={<PrivateRoute />}>{getPrivateRoutes()}</Route>
        <Route index element={<Navigate to="/login" replace />} />
      </Routes >

    </>
  );
}

export default App;
