export type menuItemsType = {
  isHeader? : boolean,
  title: string,
  icon?: string,
  link?: string,
  action?: string,
}[];

export const publicMenu : menuItemsType = [
  // {
  //   isHeader: true,
  //   title: "menu",
  // },
  {
    title: "Dashboard",
    icon: "heroicons-outline:home",
    link: 'home'
  },
];

export const menuItems : menuItemsType = [
  {
    title: "Dashboard",
    icon: "heroicons-outline:home",
    link: 'home'
  },
  {
    isHeader: true,
    title: "menu",
  },
  {
    title: "update",
    icon: "heroicons-outline:pencil-square",
    link: "edit-event",
  },
  {
    title: "Guests",
    icon: "heroicons-outline:user",
    link: "registered-details",
  },
  {
    title: "Print",
    icon: "material-symbols:print-outline",
    link: "print",
  },
  {
    title: "Manage Users",
    icon: "mdi:users-outline",
    link: "user-management",
  },
  {
    title: "Scan Station",
    icon: "bx:qr-scan",
    link: "scan-station",
  },
  {
    title: "Mailers",
    icon: "heroicons:inbox",
    link: "mailers",
  },
];

const mobileMeu: menuItemsType = [{
  isHeader: true,
  title: "actions",
},
{
  title: "logout",
  icon: "heroicons-outline:login",
  action: 'logout'
}];

export const publicMobileMenu : menuItemsType  = [
  ...publicMenu,
  ...mobileMeu,
]

export const mobileMenuItems: menuItemsType = [
  ...menuItems,
  ...mobileMeu,
];

export const colors = {
  primary: "#ef7f1a",
  secondary: "#A0AEC0",
  danger: "#F1595C",
  black: "#111112",
  warning: "#FA916B",
  info: "#0CE7FA",
  light: "#425466",
  success: "#50C793",
  "gray-f7": "#F7F8FC",
  dark: "#1E293B",
  "dark-gray": "#0F172A",
  gray: "#68768A",
  gray2: "#EEF1F9",
  "dark-light": "#CBD5E1",
};

export const hexToRGB = (hex, alpha) => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};



export const  defaultRegistrationMessage = 
`<p><span style="color: rgb(0, 0, 0);">Thank You for Registering!</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">We're excited to have you join us at [Event Name]!</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">What’s Next?</span></p><p><span style="color: rgb(0, 0, 0);">✅ You’ll receive a confirmation email shortly, including a unique QR code for event check-in.</span></p><p><span style="color: rgb(0, 0, 0);">✅ Please keep the email handy, as the QR code is required for entry.</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">For any questions, feel free to contact us at [contact details].</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">We look forward to seeing you at [event location] on [event date]! </span></p>`

export const defaultOnSiteRegistrationMessage = 
`<p><span style="color: rgb(0, 0, 0);">Welcome to [Event Name]!</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">Thank you for registering on-site.</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">What’s Next?</span></p><p><span style="color: rgb(0, 0, 0);">✅ Please show the QR code displayed on your screen at the registration desk to collect your event badge.</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">If you have any questions, our team at the desk will be happy to assist you.</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">We’re thrilled to have you with us and hope you enjoy [Event Name]! </span></p>`