
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { sendPasswordResetEmail } from 'firebase/auth';

import { auth } from '@/utils/firebase';
import Button from "@/Components/ui/Button";
import Textinput from "@/Components/ui/Textinput";
import Dummy from "@/Assets/dummy.png";
import bgImage from "@/Assets/images/login-bg.png";

const ForgotPassword = () => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const schema = yup
    .object({
      email: yup.string().email("Invalid email").required("Email is Required"),
    })
    .required();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await sendPasswordResetEmail(
        auth,
        data.email,
      );
      setLoading(false);
      navigate("/");
      toast.success("Password Reset Email Sent Successfully");

    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };



  return (
    <div
      className="loginwrapper bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className="lg-inner-column">
        <div className="left-columns lg:w-1/2 lg:block hidden">
          <div className="logo-box-3">
            <Link to="/" className="">
              <img src={Dummy} alt="" className="mb-10 logoImage" />
            </Link>
          </div>
        </div>
        <div className="lg:w-1/2 w-full flex flex-col items-center justify-center">
          <div className="auth-box-3">
            <div className="mobile-logo text-center mb-6 lg:hidden block">
              <Link to="/">
                <img
                  src={Dummy}
                  alt=""
                  className="mx-auto logoImage"
                />
              </Link>
            </div>
            <div className="text-center 2xl:mb-10 mb-5">
              <h4 className="font-medium mb-4">Forgot Your Password?</h4>
            </div>
            <div className="font-normal text-base text-slate-500 dark:text-slate-400 text-center px-2 bg-slate-100 dark:bg-slate-600 rounded py-3 mb-4 mt-10">
              Enter your Email and instructions will be sent to you!
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 ">
              <Textinput
                name="email"
                label="email"
                type="email"
                placeholder=" Enter Email Address"
                register={register}
                error={errors.email}
                className="h-[48px]"
              />
              <Button
                type="submit"
                text="Send recovery email"
                className="btn btn-dark block w-full text-center"
                isLoading={isLoading}
              />
            </form>
            <div className="md:max-w-[345px] mx-auto font-normal text-slate-500 dark:text-slate-400 2xl:mt-12 mt-8 uppercase text-sm">
              Forget It,{' '}
              <Link
                to="/login"
                className="text-slate-900 dark:text-white font-medium hover:underline"
              >
                Send me Back{' '}
              </Link>
              to The Sign In
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
