import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "@/Components/ui/Button";

import Card from "@/Components/ui/Card";
import { useCreateDynamicEventMutation, useUpdateEventsByidMutation } from "@/Redux/Services/Events";

import StepsHeader from "./StepsHeader";
import StepsFooter from "./StepsFooter";
import EventDetails from "./steps/EventDetails";
import Image from './steps/Image';
import CreateFields from "./steps/CreateFields";
import SelectFields from "./steps/SelectFields";
import Colors from "./steps/Colors";

import { STEPS, TYPE_OPTIONS, ALLOWED_IMAGE_EXTENSIONS } from './createEventWizard.constants';
import { getCreateEventPayload } from './createEventWizard.helpers';
import EmailConfig from "./steps/EmailConfig";
import CreateFieldsTabbed from "./steps/CreateFieldsTabbed";
import { useUpdateEventsByidIsolatedMutation } from "@/Redux/Services/BaseQueryIsolated";
import { useNavigationBlocker } from "@/hooks/useNavigationBlocker";
import { useBeforeUnload } from "@/hooks/useBeforeUnload";
import { replacePlaceholders } from "@/Constants/Utils";
import { defaultRegistrationMessage } from "@/Constants/data";

const stepSchema = yup.object().shape({
  name: yup.string().required("Event name is required").min(4, "Event name must be at least 4 characters long"),
  additionalGuests: yup.number().min(0,"Additional Guests cannot be negative").transform((value, originalValue) => {
    // If the value is an empty string, treat it as 0
    return originalValue === "" || originalValue === "-" ? 0 : value;
  }),
  description: yup.string().max(900, "Description cannot be more than 900 characters long"),
  startDateTime: yup.number().required("Start Date and Time is required"),
  endDateTime: yup.number().required("End Date and Time is required"),
  // logo: yup.mixed()
  //       .test("required", "You need to provide a image file", (file:any) => {
  //         if(typeof file === 'string') {
  //           return true;
  //         }
  //         if(file.length > 0) {
  //           if (ALLOWED_IMAGE_EXTENSIONS.indexOf(file[0]?.name.split('.').pop().toUpperCase()) > -1) return true;
  //           else return false;
  //         }
  //         return false
  //       })
});
const createFieldsSchema = yup.object().shape({
  fields: yup.array().of(
    yup.object().shape({
        label: yup.string().required("Label is required"),
        type: yup.string().required("Type is required"),
    })
  ),
  
  additionalGuestsFields: yup.array().when('additionalGuests', {
    // Use a callback function with [value] and schema (sch)
    // The first parameter ([value]) is an array of values corresponding to the dependencies
    // The second parameter (sch) is the schema that gets modified based on the condition
    is: (additionalGuests) => additionalGuests > 0,
    then: (sch) =>
      sch.of(
        yup.object().shape({
          label: yup.string().required("Label is required"),
          type: yup.string().required("Type is required"),
        })
      ).required("Additional guest fields are required when additional guests are provided."),
    otherwise: (sch) => sch.notRequired(),
  }),

  
});


const fileFormSchema = yup.object().shape({
  // logo: yup.mixed()
  //       .test("required", "You need to provide a image file", (file:any) => {
  //         if(typeof file === 'string') {
  //           return true;
  //         }
  //         if(file.length > 0) {
  //           if (ALLOWED_IMAGE_EXTENSIONS.indexOf(file[0]?.name.split('.').pop().toUpperCase()) > -1) return true;
  //           else return false;
  //         }
  //         return false
  //       })
    });

const emailConfigSchema = yup.object().shape({
  customEmail: yup.string().email("Please provide a valid email")
})


const qrConfigSchema = yup.object().shape({
  prefix : yup.string().max(5, "Prefix cannot be more than 5 characters long"),
})



type EventWizardProps = {
  defaultValues?: any,
  stepsValidatedArray: boolean[],
  updateValidationArray: (index: number, value: boolean) => void
}
const EventWizard = ({defaultValues, stepsValidatedArray, updateValidationArray} : EventWizardProps) => {
  const [stepNumber, setStepNumber] = useState(0);
  const params : any = useParams();
  const isUpdate = params.id;
  const navigate = useNavigate();
  const [createEvent] = useCreateDynamicEventMutation()
  const [updateEvent] = useUpdateEventsByidMutation();



  const [updateEventIsolated] = useUpdateEventsByidIsolatedMutation();

  const [isFormDirtied,setIsFormDirtied] = useState(false)

  // find current step schema


  const getSchemaForStep = (stepNumber: number) => {
    switch (stepNumber) {
      case 0:
        return stepSchema;
      case 1:
        return createFieldsSchema;
      case 2:
        return qrConfigSchema;
      case 3:
        return emailConfigSchema;
      // case 4:
      //   return emailConfigSchema;
      default:
        return stepSchema;
    }
  };




  const {
    register,
    formState: { errors,  dirtyFields, isValid ,isDirty,touchedFields},
    handleSubmit,
    control,
    trigger,
    setError,
    setValue,
    getValues,
    clearErrors,
    watch,

    
  } = useForm({
    resolver: yupResolver(getSchemaForStep(stepNumber) as any),
    // keep watch on all fields
    mode: "all",
    defaultValues: async () => {
        return isUpdate ? defaultValues : {
          additionalGuests: 0,
          regConfirmationMessage : defaultRegistrationMessage,
          onSiteRegConfirmationMessage :  defaultRegistrationMessage,
          fields: [{ label: '', display: false, mandatory : false, search : false, type: TYPE_OPTIONS[0].value }],
          colors: [],
          additionalGuestsFields: [{ label: '', display: false, mandatory : false, search : false, type: TYPE_OPTIONS[0].value }],
        };
    },
  });


const watchedTouchedFields = watch(); 

useEffect(() => {
  if (Object.keys(touchedFields).length > 0) {
    setIsFormDirtied(true);
  }
}, [watchedTouchedFields, touchedFields]);

 

  // let isBlocked = isDirty;
  // let blocker = unstable_useBlocker(isBlocked)
  useBeforeUnload(isFormDirtied,setIsFormDirtied);
  useNavigationBlocker(isFormDirtied);

  const onSubmit = async (data: any) => {
    // next step until last step . if last step then submit form

    let totalSteps = STEPS.length;
    const isLastStep = stepNumber === totalSteps - 1;
    if (isLastStep ) {
      updateValidationArray(stepNumber, true)
      let stepsValidatatedArr = [...stepsValidatedArray];
      stepsValidatatedArr[stepNumber] = true;
      
      if(!isUpdate){
        for(let i = 0; i < stepsValidatatedArr.length; i++) {
          if(stepsValidatatedArr[i] == false) {
            setStepNumber(i);
            return;
          }
        }
      }
      
      // console.log("Payload Before processing : " , data)
      let response: any;
      const payload = getCreateEventPayload(data);


      // console.log("Payload : " , payload)
      // return 


      if(isUpdate) {
        const updatePayload = {
          payload: payload,
          id: params.id,
      }
        response = await updateEvent(updatePayload)
      } else {
        response = await createEvent({ payload })
      }
      
      if (response?.data) {
        toast.success(isUpdate ? `Event ${payload.name}  updated Successfully` : `Event ${payload.name} created Successfully`);
      }
    } else {
      updateValidationArray(stepNumber, true)
      setStepNumber(stepNumber + 1);
    }
  };


  const updateEventDetails = async () => {
    const formdata = getValues();

    // return ;
    const areFieldsValid = await trigger();

      if(areFieldsValid && dirtyFields){
            let response: any;
            const payload = getCreateEventPayload(formdata);

            const updatePayload = {
                payload: payload,
                id: isUpdate,
            }
            // response = await updateEvent(updatePayload).unwrap()
            try {
              const response = await updateEvent(updatePayload).unwrap();
              if (response) {
                // console.log("Status: 200");
                if (response.message) {
                  // console.log("Message:", response.message);
                  toast.success(response.message)
                }
              }
            } catch (error) {
              toast.error("Failed to update event")
            }
      }
  }

  const handlePrev = () => {
    setStepNumber(stepNumber - 1);
  };


  const handleStepClick = async (step: number) => {
    const isValid = await trigger();
    if (isValid) {
      updateValidationArray(stepNumber, true)
      setStepNumber(step);
    }
  };  
  
 


  return (<Card title={isUpdate ? `Update Event ${defaultValues?.name}` : "Create Event"} className="w-full" subtitle={undefined} headerslot={isUpdate && (<Button
    text={"save"}
    className="btn-dark mr-2"
    type="button" isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} onClick={updateEventDetails} div={undefined} />)} noborder={undefined}>
      <div>
        <StepsHeader currentStepNumber={stepNumber} onStepClick={handleStepClick} />
        <div className="conten-box ">
          <form onSubmit={handleSubmit(onSubmit)}>
            {stepNumber === 0 && (
              <EventDetails errors={errors} register={register} control={control} setValue ={setValue} getValues={getValues} setError={setError} clearErrors= {clearErrors} isDirty={isDirty}/>
            )}
            {stepNumber === 1 && (
              <CreateFieldsTabbed errors={errors} register={register} control={control}/>
            )}
            {stepNumber === 2 && (
              <SelectFields control={control} errors={errors} register={register}/>
            )}
            {/* {stepNumber === 3 && (
              <Image errors={errors} register={register} setValue={setValue} control={control} getValues={getValues}/>
            )} */}
            {stepNumber === 3 && (
              <EmailConfig  errors={errors} register={register} control={control} dirtyFields= {dirtyFields} getValues={getValues} />
            )}
            <StepsFooter handlePrev={handlePrev} stepNumber={stepNumber} isUpdate={isUpdate} isValid = {isValid} getValues={getValues} trigger = {trigger} dirtyFields={isDirty} updateEventDetails={updateEventDetails}/>
          </form>
        </div>
      </div>


    </Card>
  );
}

export default EventWizard;