import { useRef, useEffect, useState } from "react";

import SimpleBar from "simplebar-react";
import { Link, useParams } from "react-router-dom";

import Dummy from "@/Assets/dummy.png";
import { mobileMenuItems, publicMobileMenu } from "@/Constants/data";
import Icon from "@/Components/ui/Icon";
import useMobileMenu from "@/hooks/useMobileMenu";
import Navmenu from "./Navmenu";

const MobileMenu = ({ className = "custom-class" }) => {
  const scrollableNodeRef = useRef<HTMLElement>();
  const [scroll, setScroll] = useState(false);
  const [mobileMenu, setMobileMenu] = useMobileMenu();
  const params = useParams();

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableNodeRef.current && scrollableNodeRef.current.scrollTop > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    scrollableNodeRef.current && scrollableNodeRef.current.addEventListener("scroll", handleScroll);
  }, [scrollableNodeRef]);


  return (
    <div
      className={`${className} fixed  top-0 bg-white dark:bg-slate-800 shadow-lg  h-full   w-[248px]`}
    >
      <div className="logo-segment flex justify-between items-center bg-white dark:bg-slate-800 z-[9] h-[85px]  px-4 ">
        <Link to="/home">
          <div className="flex items-center space-x-4">
            <div className="logo-icon">
              <img src={Dummy} alt="" className="logoImage"/>
            </div>
            <div>
            </div>
          </div>
        </Link>
        <button
          type="button"
          onClick={() => setMobileMenu(!mobileMenu)}
          className="cursor-pointer text-slate-900 dark:text-white text-2xl"
        >
          <Icon icon="heroicons:x-mark" className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined} />
        </button>
      </div>

      <div
        className={`h-[60px]  absolute top-[80px] nav-shadow z-[1] w-full transition-all duration-200 pointer-events-none ${
          scroll ? " opacity-100" : " opacity-0"
        }`}
      ></div>
      <SimpleBar
        className="sidebar-menu px-4 h-[calc(100%-80px)]"
        scrollableNodeProps={{ ref: scrollableNodeRef }}
      >
        <Navmenu menus={params.id ? mobileMenuItems : publicMobileMenu} eventId={params.id} />
      </SimpleBar>
    </div>
  );
};

export default MobileMenu;
