import Icon from "@/Components/ui/Icon";
import { STEPS } from './createEventWizard.constants';

type StepsHeaderProps = {
  currentStepNumber: number;
  onStepClick: (step: number) => void;
};

const StepsHeader = ({ currentStepNumber, onStepClick }: StepsHeaderProps) => {
  return (<div className="flex z-[4] items-center relative justify-center md:ml-8 md:mr-16 mb-16 cursor-pointer">
          {STEPS.map((item, i) => (
            <div
              className="relative z-[1] items-center item flex flex-start flex-1 last:flex-none group"
              key={i}
              onClick={() => onStepClick(i)}
            >
              <div
                className={`${currentStepNumber >= i
                    ? "bg-[#ef7f1a] text-white ring-[#ef7f1a] ring-offset-2 dark:ring-offset-slate-500 dark:bg-[#ef7f1a] dark:ring-[#ef7f1a]"
                    : "bg-white ring-[#ef7f1a] ring-opacity-70  text-slate-900 dark:text-slate-300 dark:bg-slate-600 dark:ring-slate-600 text-opacity-70"
                  }  transition duration-150 icon-box md:h-12 md:w-12 h-7 w-7 rounded-full flex flex-col items-center justify-center relative z-[66] ring-1 md:text-lg text-base font-medium`}
              >
              <span> {i + 1}</span>
              </div>

              <div
                className={`${currentStepNumber >= i
                    ? "bg-[#ef7f1a] dark:bg-[#ef7f1a]"
                    : "bg-[#E0EAFF] dark:bg-slate-700"
                  } absolute top-1/2 h-[2px] w-full`}
              ></div>
              <div
                className={` ${currentStepNumber >= i
                    ? " text-slate-900 dark:text-slate-300"
                    : "text-slate-500 dark:text-slate-300 dark:text-opacity-40"
                  } absolute top-full text-base md:leading-6 mt-3 transition duration-150 md:opacity-100 opacity-0 group-hover:opacity-100`}
              >
                <span className="w-max">{item.title}</span>
              </div>
            </div>
          ))}
        </div>)
}
export default StepsHeader;