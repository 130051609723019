import { TableCell, TableRow } from "@mui/material";
import { withStyles } from "@mui/styles";

export const descendingComparator = (a: any, b: any, orderBy: any) => {
  if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
    return -1;
  }
  if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
    return 1;
  }
  return 0;
};

export const getComparator = (order: string, orderBy: string) => {

  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (
  array: any[],
  comparator: { (a: any, b: any): number; (arg0: any, arg1: any): any }
) => {
  const stabilizedThis = array?.map((el: any, index: any) => [el, index]);
  stabilizedThis?.sort((a: number[], b: number[]) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el: any[]) => el[0]);
};

export const DateFormatChange = (dateString: number) => {
  return new Date(dateString * 1000)?.toLocaleString("en-US",
    {
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).replace(',', '')
}

export const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 30,
  }
}))(TableRow);

export const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "0px 16px !important",
    border: "1px solid #d3d3d3"
  }
}))(TableCell);

export const checkIsUserAuthenticated = () => {
  try {
    return localStorage.getItem("event-register-app-is-auth") === 'true';
  } catch (e) {
    return false;
  }
  
};


export const QflowList = [
  { "label": "Title", "value": "title" },
  { "label": "First Name", "value": "firstName" },
  { "label": "Last Name", "value": "lastName" },
  { "label": "Other Names", "value": "otherNames" },
  { "label": "Email", "value": "email" },
  { "label": "Barcode", "value": "barcode" },
  { "label": "Tags", "value": "tags" },
  { "label": "Guest Notes", "value": "guestNotes" },
  { "label": "Plus Ones", "value": "plusOnes" },
  { "label": "Additional Info", "value": "additionalInfo" }
]


export function replacePlaceholders(inputString, replacements) {
  let result = inputString;
  for (const [placeholder, value] of Object.entries(replacements)) {
    const regex = new RegExp(`\\[${placeholder}\\]`, 'g');
    result = result.replace(regex, value);
  }
  return result;
}