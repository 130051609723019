import React from 'react'


const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  const options = [
    { label: "All", value: "all" }, 
    { label: "Checked In", value: "true" },
    { label: "Not Checked In", value: "false" },
  ];

  return (
    <select
      value={filterValue === undefined ? "all" : String(filterValue)} 
      onChange={(e) => {
        const value = e.target.value;
        setFilter(value === "all" ? undefined : value === "true");
      }}
      className="p-2 border border-gray-300 rounded"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default ColumnFilter;