export const ApprovalStatusFilter = ({ column }) => {
    const { filterValue, setFilter } = column;
  
    // Options for the filter dropdown
    const options = [
      { label: "All", value: "all" },
      { label: "Pending", value: "pending" },
      { label: "Rejected", value: "rejected" },
      { label: "Approved", value: "approved" },
    ];
  
    return (
      <select
        value={filterValue || "all"}
        onChange={(e) => {
          const value = e.target.value;
          setFilter(value === "all" ? undefined : value); 
        }}
        className="p-2 border border-gray-300 rounded"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  };
  