import { useEffect } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { loader } from '@/Redux/Store';
import type { RootState } from '@/Redux/Store';

function PublicRoute() {
  const isAuth = useSelector((state: RootState) => state.auth.isAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loader(false));
  }, []);

  return !isAuth ? <Outlet /> : <Navigate to={{ pathname: "/home" }} />;
}

export default PublicRoute;
