import { baseApi } from "./BaseQuery";

export class EventListType {
  eventId: string = "";
  name: string = "";
  description: string = "";
}
export type EventListTypes = EventListType[]

const registerSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getEvents: build.query<EventListType[], void>({
      query: () => ({
        url: `events`,
        method: "GET",
      }),
      providesTags: ['Events']
    }),
    createEvent: build.mutation<any, any>({
      query: (payload) => ({
        url: `events`,
        method: "POST",
        body: payload
      }),
      invalidatesTags: ['Events']
    }),

    createDynamicEvent: build.mutation<any, any>({
      query: ({ ...payload }) => ({
        url: `events`,
        method: "POST",
        body: payload.payload
      }),
      invalidatesTags: ['Events']
    }),

    uploadExcel: build.mutation({
      query: (payload) => ({
        url: `events/${payload.eventId}/import-registrations`,
        method: "POST",
        body: payload.file,
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      }),
    }),
    // uploadExcel: build.mutation<any, any>({
    //   query: (data) => ({
    //     url: `event/${data.eventId}/import-registrations`,
    //     method: "POST",
    //     body: data.formData,
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }),
    // }),

    getWhatsappStatus: build.query<any, void>({
      query: () => ({
        url: `wa-status`,
      }),
    }),

    getWhatsappTemp: build.query({
      query: () => ({
        url: `wa-templates`,
      }),
    }),

    getFormTemplateById: build.query({
      query: (id) => ({
        url: `events/${id}/import-template`,
        responseHandler: async (response) => {
          // Assuming the response is a blob (binary data)
          const blob = await response.blob();
          return blob;
        },
      }),
    }),

    getEmailVerificationStatus: build.query({
      query: (id) => ({
        url: `events/${id}/email`,
        method: "GET",
      }),
    }),


    getEventsById: build.query<EventListType[], void>({
      query: (id) => ({
        url: `events/${id}`,
        method: "GET",
      }),
      providesTags: ['Events']
    }),


    updateEventsByid: build.mutation<any, any>({
      query: (updated) => ({
        url: `events/${updated?.id}`,
        method: "PUT",
        body: updated?.payload
      }),
      invalidatesTags: ['Events']
    }),

    deleteEventsById: build.mutation<any, any>({
      query: (id) => ({
        url: `events/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ['Events']
    })
  }),
});

export const {
  useGetEventsQuery,
  useCreateEventMutation,
  useCreateDynamicEventMutation,
  useUploadExcelMutation,
  useGetWhatsappTempQuery,
  useGetFormTemplateByIdQuery,
  useGetEventsByIdQuery,
  useUpdateEventsByidMutation,
  useDeleteEventsByIdMutation,
  useGetWhatsappStatusQuery
} = registerSlice;
