import RegisterForm from "../Pages/RegisterForm/RegisterForm";
import RegisterDetails from "@/Pages/RegisterDetails";
import CashFreeRedirect from "../Pages/RegisterForm/CashFreeRedirect";
import EventsList from "../Pages/Events/Events";
import CreateEventWizard from "../Pages/Events/CreateEventWizard";
import DynamicCreate from "../Pages/Events/DymanicCreate";
import UploadExcel from "../Pages/Events/UploadExcel";
import Login from "../Pages/Login/Login.jsx";
import SignUp from "../Pages/Signup/Signup.jsx";
import ForgotPassword from '../Pages/ForgotPassword/ForgotPassword.jsx';
import UserRegister from "../Pages/userRegister";
import Print from "../Pages/Print";
import UserManagement from "../Pages/UserManagement";
import AddUser from "../Pages/UserManagement/add";
import AddRoles from "../Pages/UserManagement/addRoles";

import ScanStations from "../Pages/ScanStation/ScanStations";
import Checkin from "../Pages/ScanStation/Checkin";
import AddScanStation from "../Pages/ScanStation/add";
import Mailers from "../Pages/Mailers";
import AddMailers from "../Pages/Mailers/add";
import Status from "../Pages/Status";
import RegisterDetails2 from "@/Pages/RegisterDetails/RegisterDetails2";
import RegisterUserEdit from "@/Pages/RegisterDetails/RegisterEdit/RegisterUserEdit";
import WhatsappStatus from "@/Pages/Events/Whatsapp";
import Edit from "@/Pages/Print/edit";
import Listing from "@/Pages/Print/listing";
import RegisterDetails3 from "@/Pages/RegisterDetails/RegisterDetails3";

export const RoutesComponents = [
    {
        path: "/whatsapp",
        component: WhatsappStatus
    },
    {
        path: "whatsapp-status",
        component: WhatsappStatus
    },
    {
        path: "/edit-event/:id",
        component: CreateEventWizard
    },
    {
        path: "/register-form/:id",
        component: RegisterForm,
    },
    {
        path: "registered-details/:id",
        component: RegisterDetails3
    },
    {
        path: "registered-details/:id/:registrationId",
        component: RegisterUserEdit
    },
    {
        path: "/home",
        component: EventsList,
    },
    {
        path: "/events",
        component: EventsList,
    },
    {
        path: "*",
        component: EventsList,
    },
    {
        path: "/cashfree-redirect",
        component: CashFreeRedirect
    },
    {
        path: "/print/:id/add",
        component: Print
    },

    {
        path: "/print/:id",
        component: Listing
    },

    {
        path: "/print/:id/:labelId",
        component: Edit
    },
    {
        path: "/dynamic-create-events",
        component: CreateEventWizard
    },
    {
        path: "/import-excel/:id",
        component: UploadExcel
    },
    {
        path: "/download-template/:id",
        component: DynamicCreate
    },
    {
        path: "/user-management/:id",
        component: UserManagement
    },
    {
        path: "/user-management/:id/add",
        component: AddUser
    },
    {
        path: "/user-management/:id/addRoles",
        component: AddRoles
    },
    {
        path: "/user-management/:id/editRoles",
        component: AddRoles
    },
    {
        path: "/scan-station/:id",
        component: ScanStations
    },
    {
        path: "/scan-station/:id/add",
        component: AddScanStation
    },
    {
        path: "/scan-station/:id/edit",
        component: AddScanStation
    },
    {
        path: "/scan-station/:id/checkin/:stationId",
        component: Checkin,
    },
    {
        path: "/mailers/:id",
        component: Mailers
    },
    {
        path: "/mailers/:id/add",
        component: AddMailers
    },
    {
        path: "/mailers/:id/edit",
        component: AddMailers
    },
    {
        path: "mailers/:id/status/:id",
        component: Status
    }
]

export const PublicRouteComponents = [

    {
        path: "/login",
        component: Login
    },
    {
        path: "/register",
        component: SignUp
    },
    {
        path: "/forgot-password",
        component: ForgotPassword
    },
    {
        path: "/user/registration/:id",
        component: UserRegister
    },
    {
        path: "whatsapp-status",
        component: WhatsappStatus
    }

]