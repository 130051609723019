import { useRef, useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { useParams } from "react-router-dom";
import SidebarLogo from "./Logo";
import Navmenu from "./Navmenu";
import { menuItems, publicMenu } from "@/Constants/data";

const Sidebar = () => {
  const scrollableNodeRef = useRef<HTMLInputElement>();
  const [scroll, setScroll] = useState(false);
  const params = useParams();

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableNodeRef.current && scrollableNodeRef.current.scrollTop > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    scrollableNodeRef.current && scrollableNodeRef.current.addEventListener("scroll", handleScroll);
  }, [scrollableNodeRef]);

  const [menuHover, setMenuHover] = useState(false);

  return (
    <div>
      <div
        className={`sidebar-wrapper bg-white dark:bg-slate-800 w-[248px]
      ${menuHover ? "sidebar-hovered" : ""}
      shadow-base
      `}
        onMouseEnter={() => {
          setMenuHover(true);
        }}
        onMouseLeave={() => {
          setMenuHover(false);
        }}
      >
        <SidebarLogo menuHover={menuHover} />
        <div
          className={`h-[60px]  absolute top-[80px] nav-shadow z-[1] w-full transition-all duration-200 pointer-events-none ${
            scroll ? " opacity-100" : " opacity-0"
          }`}
        ></div>

        <SimpleBar
          className="sidebar-menu px-4 h-[calc(100%-80px)]"
          scrollableNodeProps={{ ref: scrollableNodeRef }}
        >
          <Navmenu menus={params.id ? menuItems: publicMenu} eventId={params.id}/>
        </SimpleBar>
      </div>
    </div>
  );
};

export default Sidebar;
