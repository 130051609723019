import { baseApi } from "./BaseQuery";

export class RegistrationResponse {
  cfSessionId: string = "";
  cfOrderId: string = "";
  registrationId: string = "";
}

export class Registration {
  firstName: string = "";
  lastName: string = "";
  number: string = "";
  status: string = "";
  whatsappStatus: string = "";
  paymentStatus: string = "";
}
export type Registrations = Registration[]

export class OrderStatus {
  order_status: string = ""
}
const registerSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    register: build.mutation<RegistrationResponse, any>({
      query: ({...payload }) => ({
        url: `registrations`,
        method: "POST",
        body:payload.values
      }),
    }),
    getRegistrations: build.query<Registration[], void>({
      query: () => ({
        url: `registrations`,
        method: "GET"
      }),
    }),
    getOrderStatus: build.query<OrderStatus, string>({
      query: (orderId) => ({
        url: `order/${orderId}/status`,
        method: "GET"
      }),
    }),
    updatePaymentStatus: build.mutation<RegistrationResponse, any>({
      query: ({...payload }) => ({
        url: `registration/id`,
        method: "PUT",
        body:payload.values
      }),
    }),
  }),
});

export const { 
  useRegisterMutation,
  useUpdatePaymentStatusMutation ,
  useGetOrderStatusQuery,
  useGetRegistrationsQuery
} = registerSlice;
