import { useWatch, Controller } from "react-hook-form";
import Select from 'react-select';
import Textinput from "@/Components/ui/Textinput";
type SelectFieldsProps = {
  control: any,
  errors: any,
  register: Function
}

const SelectFields = ({ control, errors,register }: SelectFieldsProps) => {
  const fieldsValues = useWatch({
    control,
    name: "fields",
  });

  const qrSource = useWatch({
    control,
    name: "qrSource",
  })


  const prefixType = useWatch({
    control,
    name: "prefixType",
  })




  const labelOptions: { label: string, value: string }[] = fieldsValues.map(({ label }: any) => ({ label, value: label }));
  return (<div>
    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
      <div className="lg:col-span-3 md:col-span-2 col-span-1">
        <h4 className="text-base text-slate-800 dark:text-slate-300 mb-3 md:mb-6">
          QR Configuration
        </h4>
      </div>
      <div className="">
        <label htmlFor="type" className="form-label ">
          QR Source
        </label>
        <Controller
          control={control}
          name={`qrSource`}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              ref={ref}
              className="react-select"
              classNamePrefix="select"
              options={[{ label: "Use Generated", value: "Generated" }, { label: "Use Unique Field", value: "Unique" }]}
              value={[{ label: "Use Generated", value: "Generated" }, { label: "Use Unique Field", value: "Unique" }].find(c => c.value === value)}
              onChange={val => onChange(val?.value)}
            />
          )}
        />
      </div>
      <div className="lg:col-span-2 md:col-span-2 col-span-2"> </div> 
      {/* FOR UNIQUE START*/}
      {qrSource == "Unique" && (<>
        <div>
          <label htmlFor="uniqueIdentifier" className="form-label ">
            Unique Identifier
          </label>
          <Controller
            control={control}
            name="uniqueIdentifier"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                className="react-select"
                classNamePrefix="select"
                options={labelOptions}
                value={labelOptions.find(c => c.value === value)}
                onChange={val => onChange(val?.value)}
                menuPortalTarget={typeof window !== 'undefined' ? document.body : null}
                styles={{
                  menuPortal: (provided: any) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
              />
            )}
          />
        </div>
        {/* <div>
          <label htmlFor="qrColorField" className="form-label ">
            QR Color Field
          </label>
          <Controller
            control={control}
            name="qrColorField"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                className="react-select"
                classNamePrefix="select"
                options={labelOptions}
                value={labelOptions.find(c => c.value === value)}
                onChange={val => onChange(val?.value)}
                menuPortalTarget={typeof window !== 'undefined' ? document.body : null}
                styles={{
                  menuPortal: (provided: any) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
              />
            )}
          />
        </div> */}
      </>)}

      {qrSource == "Generated" && (<>
        <div>
          <label htmlFor="prefixType" className="form-label ">
            Prefix Type
          </label>
          <Controller
            control={control}
            name="prefixType"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                className="react-select"
                classNamePrefix="select"
                options={[{label: "Prefix with sequential", value:"Serial"},{label: "Random", value:"Random"}]}
                value={[{label: "Prefix with sequential", value:"Serial"},{label: "Random", value:"Random"}].find(c => c.value === value)}
                onChange={val => onChange(val?.value)}
                menuPortalTarget={typeof window !== 'undefined' ? document.body : null}
                styles={{
                  menuPortal: (provided: any) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
              />
            )}
          />
        </div>

            {prefixType == "Serial"  && (
              <Textinput
              label="Prefix"
              type="text"
              placeholder="Type Prefix"
              name="prefix"
              error={errors.prefix}
              register={register}
              readonly={undefined} value={undefined} icon={undefined} disabled={undefined} id={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} onChange={undefined} options={undefined} onFocus={undefined} defaultValue={undefined}/>
            )}
      </>)}



      {/* FOR UNIQUE END*/}
      {/* FOR GENERATED START*/}
      {/* FOR GENERATED END*/}



    </div>
  </div>);
}
export default SelectFields;