import { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { useNavigate } from "react-router-dom";

import { useGetEventsByIdQuery } from "@/Redux/Services/Events";
import Card from '@/Components/ui/Card';
import Icon from "@/Components/ui/Icon";
import Button from "@/Components/ui/Button";
import Loader from "@/Components/Loader";
import LineChart from '@/Components/ui/LineChart';
import { fetchScanStationsWithCheckinCounts } from './scanstations.actions';

const COLUMNS = [
  {
    Header: "Scan Station Name",
    accessor: "name",
  },
  {
    Header: "Scans Allowed",
    accessor: "maxCount",
  },
  {
    Header: "Checkin Count",
    accessor: "checkinCount",
    Cell: (row: any) => {
      const original = row?.cell?.row?.original;
      const stationId = original?.key;
      const eventId = original?.eventId;
      const value = row?.cell?.value;
      return value ? <Link to={`/scan-station/${eventId}/checkin/${stationId}`}>{row?.cell?.value}</Link> : value;
    },
  },
  {
    Header: "Trend",
    accessor: "trend",
    Cell: (row: any) => {
      return <LineChart />;
    },
  }
];
const ScanStations = () => {
  const params: any = useParams();
  const eventId = params?.id;
  const navigate = useNavigate();
  const [scanStations, setScanStations] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [firebaseError, setFirebaseError] = useState(false);

  const { error } : any= useGetEventsByIdQuery(params.id, {
    skip: !params.id
  });

  useEffect(() => {
    if(!eventId) return;

    (async () => {
      setLoading(true);
      const resp = await fetchScanStationsWithCheckinCounts(eventId)
      setFirebaseError(resp.error);
      setScanStations(resp.scanStationArray);
      setLoading(false);
    })();
    
  }, []);


  const tableInstance = useTable(
    {
      columns: COLUMNS,
      data: scanStations,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = tableInstance;
  const {  pageIndex, pageSize } = state;
  return (
    <Card title="Scan Stations" subtitle={undefined} noborder={undefined} className="min-w-full" bodyClass={undefined} headerslot={(firebaseError || error) ? undefined : <Button className="btn-dark mb-3 text-white" text="Add" isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} onClick={() => navigate(`/scan-station/${eventId}/add`, {state: {isFirst: scanStations.length  === 0}})} div={undefined} ></Button>}>
      { (firebaseError || error) ? <>Oh no, there was an error</> : isLoading ? <Loader /> : scanStations.length ? (
      <>
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden ">
            <table
              className="min-w-full divide-y divide-slate-100 table-fixed dark:divide-slate-700"
              {...getTableProps}
            >
              <thead className="bg-slate-200 dark:bg-slate-700">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        scope="col"
                        className=" table-th "
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className="bg-white divide-y divide-slate-100 dark:bg-slate-800 dark:divide-slate-700"
                {...getTableBodyProps}
              >
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} className="table-td">
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="md:flex md:space-y-0 space-y-5 justify-between mt-6 items-center">
        <div className=" flex items-center space-x-3 rtl:space-x-reverse">
          <select
            className="form-control py-2 w-max"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <span className="text-sm font-medium text-slate-600 dark:text-slate-300">
            Page{" "}
            <span>
              {pageIndex + 1} of {pageOptions.length}
            </span>
          </span>
        </div>
        <ul className="flex items-center  space-x-3  rtl:space-x-reverse">
          <li className="text-xl leading-4 text-slate-900 dark:text-white rtl:rotate-180">
            <button
              className={` ${
                !canPreviousPage ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <Icon icon="heroicons:chevron-double-left-solid" className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined} />
            </button>
          </li>
          <li className="text-sm leading-4 text-slate-900 dark:text-white rtl:rotate-180">
            <button
              className={` ${
                !canPreviousPage ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Prev
            </button>
          </li>
          {pageOptions.map((page, pageIdx) => (
            <li key={pageIdx}>
              <button
                aria-current="page"
                className={` ${
                  pageIdx === pageIndex
                    ? "bg-slate-900 dark:bg-slate-600  dark:text-slate-200 text-white font-medium "
                    : "bg-slate-100 dark:bg-slate-700 dark:text-slate-400 text-slate-900  font-normal  "
                }    text-sm rounded leading-[16px] flex h-6 w-6 items-center justify-center transition-all duration-150`}
                onClick={() => gotoPage(pageIdx)}
              >
                {page + 1}
              </button>
            </li>
          ))}
          <li className="text-sm leading-4 text-slate-900 dark:text-white rtl:rotate-180">
            <button
              className={` ${
                !canNextPage ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
          </li>
          <li className="text-xl leading-4 text-slate-900 dark:text-white rtl:rotate-180">
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              className={` ${
                !canNextPage ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <Icon icon="heroicons:chevron-double-right-solid" className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined} />
            </button>
          </li>
        </ul>
      </div></>) : <div>No Scan Stations Present! Please Add!</div>}
    </Card>
  );
}

export default ScanStations;