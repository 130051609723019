import { baseApi } from "./BaseQuery";



const userRegisterSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({

    registerEvents: build.mutation({
      query: (payload) => ({
        url: `events/${payload.id}/registrations`,
        responseHandler: async (response) => {
          // Assuming the response is a blob (binary data)
          const blob = await response.blob();
          return blob;
        },
        method: "POST",
        body: payload.values
      }),
      invalidatesTags: ['Events']
    }),


    getRegistrDetails: build.query({
      query: (id) => ({
        url: `events/${id}/registrations`,
        method: "GET"
      }),
    }),

    getOneEvent: build.query({
      query: (id) => ({
        url: `events/${id}`,
        method: "GET"
      }),
    }),


  }),
});

export const {
  useRegisterEventsMutation,
  useGetRegistrDetailsQuery,
  useGetOneEventQuery
} = userRegisterSlice;
