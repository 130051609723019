import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "@/Components/Loader";
import { useGetEventsByIdQuery } from "@/Redux/Services/Events";

import EventWizard from "./EventWizard";
import { getFormDefaultValues } from "./createEventWizard.helpers";
import type { FormData } from './types';
import { STEPS } from "./createEventWizard.constants";

const CreateEventWizard = () => {
  const [defaultValues, setDefaultValues] = useState<FormData>()
  const [stepsValidatedArray, setStepsValidatedArray] = useState(Array(STEPS.length).fill(false));


  const params : any = useParams();
  // const { data: whatsappTemplates } = useGetWhatsappTempQuery("");
  const { data: templateData, isLoading, error } : any= useGetEventsByIdQuery( params.id, {
      skip: !params.id
  });


  const updateValidationArray = (index: number, value: boolean) => {
    const newArray = [...stepsValidatedArray];
    newArray[index] = value;
    setStepsValidatedArray(newArray);
  } 




  useEffect( () => {
    if(templateData) {
      setDefaultValues(getFormDefaultValues(templateData));
    }
  }, [templateData])
  
  return (
    error ? <>Oh no, there was an error</> : (params.id ? (defaultValues  ? <EventWizard stepsValidatedArray = {stepsValidatedArray} updateValidationArray = {updateValidationArray} defaultValues={defaultValues}/> : <Loader />) : <EventWizard updateValidationArray = {updateValidationArray} stepsValidatedArray = {stepsValidatedArray}/> )
  );
};

export default CreateEventWizard;
