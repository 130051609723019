const Fileinput = ({
  name,
  label = "Browse",
  onChange,
  placeholder = "Choose a file",
  multiple,
  preview,
  className = "",
  id,
  selectedFile,
  previewHeight = "",
  previewWidth = "",
  badge,
  selectedFiles,
  accept,
  register,
  error,
}) => {
  return (
    <div>
      <div className={`filegroup fromGroup ${error ? "has-error" : ""}`}>
        <label>
          <input
            type="file"
            onChange={onChange}
            {...register(name)}
            className="bg-red-400 w-full hidden"
            name={name}
            id={id}
            multiple={multiple}
            placeholder={placeholder}
            accept={accept}
          />
          <div
            className={`w-full h-[40px] file-control flex items-center ${className} p-0 form-control  `}
          >
            {!multiple && (
              <span className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                {selectedFile && (
                  <span
                    className={
                      badge ? " badge-title" : "text-slate-900 dark:text-white"
                    }
                  >
                    {selectedFile.name}
                  </span>
                )}
                {!selectedFile && (
                  <span className="text-slate-400 pl-4">{placeholder}</span>
                )}
              </span>
            )}

            {multiple && (
              <span className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                {selectedFiles.length > 0 && (
                  <span
                    className={
                      badge ? " badge-title" : "text-slate-900 dark:text-white"
                    }
                  >
                    {selectedFiles.length > 0
                      ? selectedFiles.length + " files selected"
                      : ""}
                  </span>
                )}
                {selectedFiles.length === 0 && (
                  <span className="text-slate-400">{placeholder}</span>
                )}
              </span>
            )}
            <span className="file-name flex-none cursor-pointer border-l px-4 border-slate-200 dark:border-slate-700 h-full inline-flex items-center bg-slate-100 dark:bg-slate-900 text-slate-600 dark:text-slate-400 text-base rounded-tr rounded-br font-normal">
              {label}
            </span>
          </div>
          {error && (
            <div
              className="mt-2 text-danger-500 block text-sm"
            >
              {error.message}
            </div>
          )}
          {!multiple && preview && selectedFile && (
            <div className={`mx-auto mt-6  `} style = {{width : previewWidth || "200px",height: previewHeight || "200px"}}>
              <img
                src={selectedFile ? selectedFile : ""}
                className="w-full  h-full block rounded object-contain border p-2  border-slate-200"
                alt={selectedFile?.name}
              />
            </div>
          )}
          {multiple && preview && selectedFiles.length > 0 && (
            <div className="flex flex-wrap space-x-5 rtl:space-x-reverse">
              {selectedFiles.map((file, index) => (
                <div
                  className="xl:w-1/5 md:w-1/3 w-1/2 rounded mt-6 border p-2  border-slate-200"
                  key={index}
                >
                  <img
                    src={file ? URL.createObjectURL(file) : ""}
                    className="object-cover w-full h-full rounded"
                    alt={file?.name}
                  />
                </div>
              ))}
            </div>
          )}
        </label>
      </div>
    </div>
  );
};

export default Fileinput;
