import React, { useState } from "react";
import Button from "@/Components/ui/Button";
import Checkbox from "@/Components/ui/Checkbox";
import Textinput from "@/Components/ui/Textinput";
import { useWatch, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useGetEmailVerificationStatusQuery, useUpdateEventsByidIsolatedMutation } from "@/Redux/Services/BaseQueryIsolated";
import { getCreateEventPayload } from "../createEventWizard.helpers";
import {  Tab, Tabs, Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import TooltipIcon from "@/Components/ui/Icon/TooltipIcon";
import Switch from "@/Components/ui/Switch";

type EmailConfigProps = {
    errors: any;
    register: any;
    control: any;
    dirtyFields?: any;
    getValues: any;
}

const EmailConfig = ({ errors, register, control, dirtyFields, getValues }: EmailConfigProps) => {

    const useCustomEmail = useWatch({
        control,
        name: "useCustomEmail",
    })
    const params: any = useParams();
    const isUpdate = params.id;

    const [activeTab, setActiveTab] = useState(0);
    const [updateEvent] = useUpdateEventsByidIsolatedMutation();



    const [triggerEmailVerification, setTriggerEmailVerification] = React.useState<Boolean>(false);

    const { data, error, isLoading, isSuccess, refetch } = useGetEmailVerificationStatusQuery(isUpdate, {
        skip: (!triggerEmailVerification),
    });




    // const { data: templateData, isLoading, error } : any= useGetEventsByIdQuery( isUpdate, {
    //     // skip: !params.id
    // });






    const handleFetchEmailVerification = async () => {

        if (isUpdate && ("customEmail" in dirtyFields || "domainName" in dirtyFields || "mailFromDomain" in dirtyFields)) {
            console.log("Resubmit form in this case")
            const formdata = getValues();
            let response: any;
            const payload = getCreateEventPayload(formdata);

            const updatePayload = {
                payload: payload,
                id: params.id,
            }

            response = await updateEvent(updatePayload)
        }


        if (!triggerEmailVerification) {
            setTriggerEmailVerification(true);
        } else {
            refetch()
        }
    };
    const renderStatusBadge = (status: any) => {
        return status ? <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{status}</span> : null;
    };
 

    return (
        <>
        <div className="flex" style = {{display:'none'}}>
        <Controller
          name="sendEmail"
          control={control}
          render={({ field }: any) => <Switch
            disabled prevIcon={undefined} nextIcon={undefined} id={undefined} badge={undefined} label="Email"
            {...field}
          />}
        />
        <Controller
          name="sendWhatsapp"
          control={control}
          render={({ field }: any) => <Switch
            disabled prevIcon={undefined} nextIcon={undefined} id={undefined} badge={undefined} label="Whatsapp"
            {...field}
          />}
        />
        </div>
        
            <Tabs
                value={activeTab}
                onChange={(_, newValue) => setActiveTab(newValue)}
            >
                <Tab label="Email" />
                <Tab label="Whatsapp" />
            </Tabs>
            {activeTab === 0 && (<div>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 pt-2.5">
                    <div className="lg:col-span-3 md:col-span-2 col-span-1">
                        <h4 className="text-base text-slate-800 dark:text-slate-300">
                            Enter Email Configuration
                        </h4>
                    </div>
                    <div className="lg:col-span-1 md:col-span-1 col-span-1"> </div>
                    <Textinput
                        label="Email Sender Name"
                        type="text"
                        placeholder="The name where email is sent from"
                        name="emailSenderName"
                        error={errors.emailSenderName}
                        register={register} readonly={undefined} value={undefined} icon={undefined} disabled={undefined} id={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} onChange={undefined} options={undefined} onFocus={undefined} defaultValue={undefined} />

                    <div className="lg:col-span-1 md:col-span-1 col-span-1"> </div>
                    <div className="lg:col-span-1 md:col-span-1 col-span-1"> </div>
                    <Controller
                        name="useCustomEmail"
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange, ref, ...props }, fieldState: { error } }) => (
                            <div className={`fromGroup ${error && 'has-error'}`}>
                                <Checkbox
                                    {...props}
                                    id={"customEmailCheckbox"}
                                    disabled={false}
                                    label="Use Custom Email"
                                    onChange={(e: any) => onChange(e.target.checked)}
                                    register={register}
                                    error={errors.useCustomEmail}
                                />
                                
                                {error && (<div className="mt-2 text-danger-500 block text-sm">{error.message}</div>)}
                                
                            </div>
                        )}
                    />
                    
                     
                    <div className="lg:col-span-1 md:col-span-1 col-span-1"><Tooltip title = "setup and send invites directly from their own domain.">
                    <Icon icon="heroicons:information-circle"
                      className={undefined} width={"20px"} rotate={undefined} hFlip={undefined} vFlip={undefined}/>
                    </Tooltip> </div>

                    {useCustomEmail && (<>
                        <div className="lg:col-span-1 md:col-span-1 col-span-1"> </div>

                        <Textinput
                            label="Send from email id"
                            type="text"
                            placeholder="Send From Email Id (e.g. testsender123)"
                            name="customEmail"
                            error={errors.customEmail}
                            register={register} readonly={undefined} value={undefined} icon={undefined} disabled={undefined} id={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} onChange={undefined} options={undefined} onFocus={undefined} defaultValue={undefined} />
                        <div className="lg:col-span-1 md:col-span-1 col-span-1">
                            <div className="lg:col-span-1 md:col-span-1 col-span-1 lg:mt-11">
                                {renderStatusBadge(data?.email_identity_status)}
                                {/* {data?.email_identity_status && (<span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{data?.email_identity_status}</span>)} */}
                            </div>
                        </div>
                        <div className="lg:col-span-1 md:col-span-1 col-span-1"> </div>
                        {/* <div> */}
                        {/* <Controller
                            name="domainValidation"
                            control={control}
                            defaultValue={undefined}
                            render={({ field: { onChange, ref, ...props }, fieldState: { error } }) => (
                                <div className={`fromGroup ${error && 'has-error'}`}>
                                    <Checkbox
                                        {...props}
                                        id={"domainValidation"}
                                        disabled={false}
                                        label="Domain Validation"
                                        onChange={(e: any) => onChange(e.target.checked)}
                                        register={register}
                                        error={errors.domainValidation}
                                    />
                                    {error && (<div className="mt-2 text-danger-500 block text-sm">{error.message}</div>)}
                                </div>
                            )}
                        /> */}
                        <Textinput
                            label="Email Domain Name"
                            type="text"
                            placeholder="Enter Domain Name (e.g. test123.in)"
                            name="domainName"
                            error={errors.domainName}
                            register={register} readonly={undefined} value={undefined} icon={undefined} disabled={undefined} id={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} onChange={undefined} options={undefined} onFocus={undefined} defaultValue={undefined} />
                        {/* </div> */}

                        <div className="lg:col-span-1 md:col-span-1 col-span-1"><div className="lg:col-span-1 md:col-span-1 col-span-1 lg:mt-11">
                            {renderStatusBadge(data?.domain_identity_status)}
                            {/* {data?.domain_identity_status && (<b>{data?.domain_identity_status}</b>)} */}
                        </div> </div>
                        <div className="lg:col-span-1 md:col-span-1 col-span-1"> </div>
                        <div>
                            <Textinput
                                label="Subdomain used for sending mail"
                                type="text"
                                placeholder="Subdomain used for sending mail (e.g. mail)"
                                name="mailFromDomain"
                                error={errors.mailFromDomain}
                                register={register} readonly={undefined} value={undefined} icon={undefined} disabled={undefined} id={undefined} horizontal={undefined} validate={undefined} isMask={undefined} msgTooltip={undefined} description={undefined} hasicon={undefined} onChange={undefined} options={undefined} onFocus={undefined} defaultValue={undefined} />
                            {isUpdate && (<Button
                                text={"Get Config"}
                                className="btn-secondary btn-sm mt-2 "
                                type="button" isLoading={isLoading} disabled={undefined} children={undefined} icon={undefined} link={undefined} onClick={handleFetchEmailVerification} div={undefined} />)}

                            {/* <Controller
                            name="domainValidation"
                            control={control}
                            defaultValue={undefined}
                            render={({ field: { onChange, ref, ...props }, fieldState: { error } }) => (
                                <div className={`fromGroup ${error && 'has-error'}`}>
                                    <Checkbox
                                        {...props}
                                        id={"domainValidation"}
                                        disabled={false}
                                        label="Domain Validation"
                                        onChange={(e: any) => onChange(e.target.checked)}
                                        register={register}
                                        error={errors.domainValidation}
                                    />
                                    {error && (<div className="mt-2 text-danger-500 block text-sm">{error.message}</div>)}
                                </div>
                            )}
                        /> */}
                        </div>
                        <div className="lg:col-span-1 md:col-span-1 col-span-1">
                            <div className="lg:col-span-1 md:col-span-1 col-span-1 lg:mt-11">
                                {renderStatusBadge(data?.mail_from_status)}
                                {/* {data?.mail_from_status && (<b>{data?.mail_from_status}</b>)} */}
                            </div>
                        </div>
                        <div className="lg:col-span-1 md:col-span-1 col-span-1"> </div>
                        <div className="lg:col-span-1 md:col-span-1 col-span-1">

                            {/* {data?.domain_verification_dns_record && (<label><b>Domain Verification Record:</b> <br/>{data?.domain_verification_dns_record}</label>)}
                        <br/>
                        {data?.mail_from_dns_records && (<label><b>Mail from Domain Record:</b><br/>{data?.mail_from_dns_records}</label>)} */}
                            {(data?.domain_verification_dns_record || data?.mail_from_dns_records) && (<div className="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 break-words" role="alert">
                                {data?.domain_verification_dns_record && (<><span className="font-medium">Domain Verification Record:</span><br /> {data?.domain_verification_dns_record}<br /></>)}
                                {data?.mail_from_dns_records && (<><span className="font-medium">Mail from Domain Record:</span><br /> {data?.mail_from_dns_records}<br /></>)}
                                {/* <span className="font-medium">Domain Verification Record:</span><br /> {data?.domain_verification_dns_record}<br/>
                            <span className="font-medium">Mail from Domain Record:</span><br /> {data?.mail_from_dns_records} */}
                            </div>)}

                            {/* {data?.mail_from_dns_records && (<div className="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 break-words" role="alert">
                            <span className="font-medium">Mail from Domain Record:</span><br /> {data?.mail_from_dns_records}
                        </div>)} */}
                        </div>
                    </>
                    )}
                </div>
            </div>)}





            
        </>
    )

}


export default EmailConfig;