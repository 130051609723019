import { DragCard } from "./DragCrad";
import "./GenarateForm.css";
import textsvg from "../../Assets/text.svg"
import ImageSvg from "../../Assets/Image.svg"

const Data = [
 {
  name:"Text Field",
  type:"text",
  icon:textsvg
 },
 {
  name:"Image",
  type:"image",
  icon:ImageSvg
 }
];

export function DragFile() {
  return (
    <div className="my-8 mx-8 rounded-xl border w-fit">
      <div className="m-4">
        <div>
          <p className=" font-bold text-center mb-1">Drag your Items</p>
        </div>
        <div className="row">
        {Data.map((e:any, i:any) => (
          <div key={i} className="cursor-pointer col-4 mb-2 ">
            <DragCard name={e} />
          </div>
        ))}
        </div>
      </div>
    </div>
  );
}
export default DragFile;
