import React from "react"
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import GenarateForm from "../../Components/Print2/GenarateForm";

const Print = () => {
    return (
        <div className="container-fluid bg-white">
            <DndProvider backend={HTML5Backend}>
                <GenarateForm />
            </DndProvider>
        </div>
    )
};

export default Print;
