import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from '@mui/material/Button/Button';
import Link from '@mui/material/Link';
import EyeTrue from "../../Assets/eyeactive.svg";
import EyeFalse from "../../Assets/eyeinactive.svg";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'react-toastify';
import { addDoc, collection } from "firebase/firestore";
import { auth, db } from '../../utils/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, OutlinedInput, Select, Theme, Tooltip, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useGetOneEventQuery } from '../../Redux/Services/userRegister';
import { usePostRolesMutation } from '../../Redux/Services/Usermanagement';


function AddRoles() {

    const initialValues = {
        role_name: "",
        checkin: false,
        print: false,
        spot_register: false,
        fields: []
    }

    const LoginSchema = Yup.object({
        role_name: Yup.string().required(`Role name is required`),
    })

    const navigate = useNavigate();
    const location = useLocation();
    const formId = location?.pathname?.split("/")[2] ?? ""
    const { data: data, error, isLoading } = useGetOneEventQuery(formId)

    const [postRoles] = usePostRolesMutation();
    const fieldOption = data?.fields?.map((res:any)=>res?.label)
    

    console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkk",location?.state?.edit);
    const editTrue = location?.state?.edit;
    const submitLogin = async (payloads: any) => {
        let payload ={
            id:formId,
            values:payloads
        }
        
        postRoles(payload)
        .unwrap()
        .then((res:any)=>{
            
            if(res){
                navigate(`/user-management/${formId}`,{state:{value:location?.state?.value}})
            }
        })
        .catch((error)=>{
            
        })
    };


    const theme = useTheme();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    function getStyles(name: string, personName: readonly string[], theme: Theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    return (
        <React.Fragment>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>

                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <div className='d-flex justify-content-between mb-3'>
                        <button className='backBtn'>
                            <Tooltip title="Back">
                                <ArrowBackIcon className='' onClick={() => navigate(`/user-management/${formId}`,{state:{value:location?.state?.value}})} />
                            </Tooltip>
                        </button>
                        <h4 className='text-center'>{editTrue ? "Edit Roles":"Add Roles"}</h4>
                        <div></div>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={LoginSchema}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) => {
                            submitLogin(values)
                        }}
                    >
                        {({
                            handleBlur,
                            handleChange,
                            values,
                        }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="campaignName"
                                            label="Role Name"
                                            placeholder='Enter your Role name'
                                            fullWidth
                                            variant="outlined"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.role_name}
                                            name="role_name"
                                            className='formInput'
                                        />
                                        <ErrorMessage name="role_name" component="div" className="error-msg" />
                                    </Grid>

                                    <Grid item xs={12}>

                                            <h6>Select Fields</h6>
                                            <Select
                                                multiple
                                                displayEmpty
                                                value={values?.fields}
                                                name='fields'
                                                onChange={handleChange}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <em>add fields</em>;
                                                    }

                                                    return selected.join(', ');
                                                }}
                                                MenuProps={MenuProps}
                                                style={{width:"100%"}}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem disabled value="">
                                                    <em>Placeholder</em>
                                                </MenuItem>
                                                {fieldOption?.map((name:string) => (
                                                    <MenuItem
                                                        key={name}
                                                        value={name}
                                                        style={getStyles(name, values?.fields, theme)}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <h6>Capabilities</h6>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={values.checkin}
                                                    onChange={handleChange}
                                                    name={"checkin"}
                                                    color="primary"
                                                />}
                                                label="Checkin"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={values.print}
                                                    onChange={handleChange}
                                                    name={'print'}
                                                    color="primary"
                                                />}
                                                label="Print"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={values.spot_register}
                                                    onChange={handleChange}
                                                    name={"spot_register"}
                                                    color="primary"
                                                />}
                                                label="Spot Registration"
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid container className='d-flex justify-content-center gap-5'>
                                        <Grid item>
                                            <Button
                                                type="reset"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2, ml: 3 }}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Container>
        </React.Fragment>
    );
}
export default AddRoles;