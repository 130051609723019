
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// const firebaseConfig ={
//     apiKey: " AIzaSyCEyPB9hBcT-bL4DCX6Y8PnbwFmLyZlUAk ",
//     authDomain: "rokpok-10a3f.firebaseapp.com",
//     databaseURL: "https://rokpok-10a3f.firebaseio.com",
//     projectId: "rokpok-10a3f",
//     storageBucket: "rokpok-10a3f.appspot.com",
//     messagingSenderId: "446389795177",
//     appId: "1:446389795177:web:ec8fc7f6728ca30b4365f8",
//   }

// const firebaseConfig = {
//   apiKey: "AIzaSyAjwgArxhmdZaAip_IZJ1F4Mat26h9McYk",
//   authDomain: "zeel1-a0b24.firebaseapp.com",
//   projectId: "zeel1-a0b24",
//   storageBucket: "zeel1-a0b24.appspot.com",
//   messagingSenderId: "739459855039",
//   appId: "1:739459855039:web:2f371a1061e57c712a8c0a"
// };

// Demo Account
// const firebaseConfig = {
//   apiKey: "AIzaSyDmVc8353epwmzbs5QagK-niPbOGXty7Ig",
//   authDomain: "demoapp-2ce60.firebaseapp.com",
//   projectId: "demoapp-2ce60",
//   storageBucket: "demoapp-2ce60.appspot.com",
//   messagingSenderId: "972957668120",
//   appId: "1:972957668120:web:3d321e039f0119d9f7446d",
//   measurementId: "G-BG37S8HXXE"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyB9MRqQuIeEWW-1yUJSiM62-AptiTsTkSg",
//   authDomain: "activ-d4124.firebaseapp.com",
//   projectId: "activ-d4124",
//   storageBucket: "activ-d4124.appspot.com",
//   messagingSenderId: "458415927217",
//   appId: "1:458415927217:web:6df9ec419e9c30dd821790"
// };

const firebaseConfig = {
  apiKey: "AIzaSyA-q6iUnroehDkH488PSJ7YTJyY-DfV9rs",
  authDomain: "activ-18e81.firebaseapp.com",
  projectId: "activ-18e81",
  storageBucket: "activ-18e81.appspot.com",
  messagingSenderId: "861157904802",
  appId: "1:861157904802:web:46b0f3e9efec9583e822fb",
  measurementId: "G-RFWV8TS4ZY"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// firebaseApps previously initialized using initializeApp()
// export const db = getFirestore();
// connectAuthEmulator(auth, 'http://127.0.0.1:9099');