import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { signOut } from 'firebase/auth';
import { menuItemsType } from "@/Constants/data";

import Icon from "@/Components/ui/Icon";
import Button from "@/Components/ui/Button";

import useMobileMenu from "@/hooks/useMobileMenu";
import { logOut } from '@/Redux/Store';
import { auth } from '@/utils/firebase';

type NavmenuProps = {
  menus: menuItemsType,
  eventId?: string,
};

const Navmenu = ({ menus, eventId }: NavmenuProps) => {
  const location = useLocation();
  const dispatch = useDispatch()
  const locationName = location.pathname.replace("/", "");
  const [mobileMenu, setMobileMenu] = useMobileMenu();

  const isLocationMatch = (targetLocation: string) => {
    return (
      locationName === targetLocation ||
      locationName.startsWith(`${targetLocation}/`)
    );
  };

  const handleNavAction = async (action: string) => {
    switch(action){
      case 'logout':{
        localStorage.removeItem("event-register-app-is-auth");
        dispatch(logOut());
        await signOut(auth);
        break;
      }
      default:
        break;
    }
    
  }

  useEffect(() => {
    document.title = `Activ Elements  | ${locationName}`;
    if (mobileMenu) {
      setMobileMenu(false);
    }
  }, [location]);

  return (
    <>
      <ul>
        {menus.map((item, i) => (
          <li
            key={i}
            className={` single-sidebar-menu 
              ${isLocationMatch(item.link as string) ? "menu-item-active bg-[#ef7f1a]" : ""}`}
          >
            {/* single menu with no childred*/}
            {!item.isHeader && item.link && (
              <NavLink className="menu-link" to={eventId ? `${item.link}/${eventId}` : item.link}>
                <span className="menu-icon flex-grow-0">
                  <Icon icon={item.icon} className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined} />
                </span>
                <div className="text-box flex-grow">{item.title}</div>
              </NavLink>
            )}
            {!item.isHeader && item.action && (
              <Button className="menu-link pr-0 py-0 pl-2.5 border-0" text={undefined} isLoading={undefined} disabled={undefined} icon={undefined} link={undefined} onClick={() => handleNavAction(item.action as string)} div={undefined}>
                <span className="menu-icon flex-grow-0">
                  <Icon icon={item.icon} className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined} />
                </span>
                <div className="text-box flex-grow">{item.title}</div>
              </Button>
            )}
            {/* only for menulabel */}
            {item.isHeader && (
              <div className="menulabel">{item.title}</div>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

export default Navmenu;
