import { Table, TableBody, TableContainer, TableHead, TableSortLabel, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react"
import { StyledTableCell, StyledTableRow } from "../../Constants/Utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { useGetMailersQuery, usePostSendMailerStatusMutation } from "../../Redux/Services/Usermanagement";
import PickMailerModal from "@/Components/Mailers/PickMailerModal";


const headCellsRoles = [
    {
        id: "s_no",
        label: "S.No",
    },
    {
        id: "name",
        label: "Template Name",
    },
    {
        id: "subject",
        label: "Subject",
    },
    {
        id: "body",
        label: "Body",
    },
    {
        id: "filter",
        label: "Filters",
    },
    {
        id: "on_registration",
        label: "On Registration",
    },
    {
        id: "action",
        label: "Action",
    },
];

function EnhancedTableHeadRoles(props: any) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: string) => (event: any) => {
        onRequestSort(event, property);
    };


    const [header, setHeader] = useState(headCellsRoles);

   


    return (
        <>
        <TableHead className="infoTableHead">
            <StyledTableRow>
                {header?.map((headCell: any, index: any) => {
                    return (
                        <StyledTableCell
                            key={index}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                <p className="infotable-headText">{headCell.label}</p>
                            </TableSortLabel>
                        </StyledTableCell>
                    );
                })}
            </StyledTableRow>
        </TableHead>
        </>
    );
}


const Mailers = () => {
    const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("first_name");
    const [searchArray, setSearchArray] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const formId = location?.pathname?.split("/")[2] ?? ""
    const { data: getMailers } = useGetMailersQuery(formId)

    const [postSendMailerStatus] = usePostSendMailerStatusMutation();

    const handleRequestSort = (
        event: any,
        property: React.SetStateAction<string>
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };


    const usersSelected = location?.state?.users


    useEffect(() => {
        setSearchArray(getMailers)
    }, [getMailers])

    console.log("searchArrayScanStation", getMailers);

    const handleClickRunTimeStamp = (mailerId: string) => {
        let payload = {
            formId: formId,
            mailerId: mailerId
        }
        postSendMailerStatus(payload)
            .unwrap()
            .then((res: any) => {
                console.log("sdddddddddddddddddddddddddd", res);

            })
            .catch((error: any) => {

            })



    
    }

    const handleAddNewMailer = () => {
        if(usersSelected){
            navigate(`/mailers/${formId}/add`, {
                state: { users: usersSelected },
              })
        }else{
            navigate(`/mailers/${formId}/add`)
        }
    }



    return (
        <div className="container table">
            <button className="createBtn mt-3 me-2" onClick={handleAddNewMailer}>Add New Mailer +</button>
            <button className="createBtn mt-3 me-2" onClick={() => setIsTemplatesModalOpen(true)}>Pick Existing Mailer</button>
            <PickMailerModal isOpen={isTemplatesModalOpen} onClose={()=>setIsTemplatesModalOpen(false)} />

            <TableContainer className="">
                <Table
                    className={"mt-4 table"}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                >
                    <EnhancedTableHeadRoles
                        classes={""}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={searchArray?.length}

                    />
                    <TableBody>
                        {
                            searchArray?.map((row: any, index: any) => {
                                console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjj", row);


                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <StyledTableRow hover key={index}>
                                        <StyledTableCell
                                            component="th"
                                            id={labelId}
                                            key={index}
                                            scope="row"
                                        >
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell className="cursor-pointer">{row?.name}</StyledTableCell>
                                        <StyledTableCell className="cursor-pointer">{row?.subject}</StyledTableCell>
                                        <StyledTableCell className="cursor-pointer">{row?.body?.length > 150 ? row?.body?.slice(0, 150) + "......" : row?.body}</StyledTableCell>
                                        <StyledTableCell className="cursor-pointer">
                                            {
                                                Object.keys(row?.filter || {})?.map((key, index) => {
                                                    let value = typeof row?.filter[key] === 'object' ? row?.filter[key]?.$oid : row?.filter[key]
                                                    return (
                                                        <div key={index} className="d-flex">
                                                            <p>
                                                                {key}:
                                                            </p>
                                                            <p >
                                                                {value}
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                            }</StyledTableCell>
                                        <StyledTableCell className="cursor-pointer">{row?.on_registration ? "Yes" : "No"}</StyledTableCell>
                                        <StyledTableCell>
                                            <Tooltip title="Edit">
                                                <EditIcon onClick={() => navigate(`/mailers/${formId}/edit`, {
                                                    state: {
                                                        data: row,
                                                        edit: true
                                                    }
                                                })} className="updatebutton" />
                                            </Tooltip>
                                            <Link to={`/mailers/${formId}/status/${row?._id?.$oid}`} className="ms-3">
                                                {"Status"}
                                            </Link>
                                            <button className="createBtn me-2" onClick={() => {console.log("Row is --",row);handleClickRunTimeStamp(row?.id)}}>Run</button>

                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
};

export default Mailers;
