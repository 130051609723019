import  { useState } from "react"
import {  Upload, UploadProps } from 'antd';
import { useUploadExcelMutation } from "../../Redux/Services/Events";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
const { Dragger } = Upload;

const UploadExcel = () => {
    const [fileList, setFileList] = useState<any>([]);

    const { id } = useParams();
    console.log("eventId", id)

    const [uploadExcelFile] = useUploadExcelMutation()
    const navigate = useNavigate()

    const props: UploadProps = {
        name: "file",
        multiple: false,
        fileList: fileList,
        accept: "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls, .xlsx",
        onRemove: (file) => {
            setFileList([]);
        },
        //showUploadList: false,
        beforeUpload: (file) => {
            // You can access the file here
            // Check file type

            // Check file size

            setFileList([file]);
            // Remove the multiple file selection capability
            props.multiple = false;
            return false;
        },
    };
    console.log("mmmmmmmmmmmmmmmmmmmmmmm", fileList[0]);

    const handleUploadExcel = () => {
        let formData = new FormData();
        formData.append("docfiles", fileList[0]);
        if (fileList?.length > 0) {
            if (id === undefined) {
                toast.error("Event id is missing")
                return
            }
            uploadExcelFile({ eventId: id, file: formData })
                .unwrap()
                .then((res: any) => {
                    console.log("hhhhhhhhhhhhhhhhhhhhhh", res);
                    toast.success("Registrations imported")

                })
                .catch((err: any) => {

                })
        } else {
            toast.error("Please choose excel file")
        }

    }
    return (
        <div className="container">
            <button className='backBtn'>
                <Tooltip title="Back">
                    <ArrowBackIcon className='' onClick={() => navigate(`/registered-details/${id}`)} />
                </Tooltip>
            </button>
            <div className="mt-3">
                <Dragger {...props} className="">
                    <p className="dropText mb-0">Drop your files here</p>
                    <p className="fromComputer mb-0">
                        Browse file<span className='browse'>from your computer</span>
                    </p>
                </Dragger>
                <button className="sendWhatsappBtn mt-3" onClick={handleUploadExcel}>Submit</button>
            </div>

        </div>
    )
};

export default UploadExcel;
