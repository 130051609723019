import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import type { FetchArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { loader } from "./StaticReducer";
import { toast } from "react-toastify";
import type { RootState } from "../Store";

const baseQuery = fetchBaseQuery({
  // baseUrl: "https://events.activ.in/api/v1/",
  baseUrl: "https://events.gaggle.online/api/v1",
  // baseUrl: "http://localhost:5001/api/v1",
  //baseUrl: "http://localhost:5001/api/v1",
  prepareHeaders: async (headers, { getState  }) => {
    // Add content-type if not set in headers
    // if (!headers.has("Content-type")) {
    //   headers.set("Content-type", "application/json");
    // }
    const state = getState() as RootState;
    const token = state?.auth?.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
})


const staggeredBaseQueryWithBailOut = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    api.dispatch(loader(true))
    let result = await baseQuery(args, api, extraOptions)
    if (result) {
      api.dispatch(loader(false))
      if (result?.error) {
        toast.error(result?.error?.status)
      }
    }
    return result
  },
  {
    maxRetries: 0,
  }
);

export const baseApi = createApi({
  reducerPath: "baseApiReducer",
  baseQuery: staggeredBaseQueryWithBailOut,
  tagTypes: ["Events", "Users", "Template"],
  endpoints: () => ({}),
});
