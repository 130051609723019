import React from 'react';
import { Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FormControl, FormHelperText } from '@mui/material';
import './controlledTextEditor.css'



const customIcons = {
    clean: '<span style="font-size: 12px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Clear Formatting</span>'
    // clean: ' <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24"><path fill="currentColor" d="M6 4h14v2H6zm3 4h8v2H9zm-5 4h14v2H4zm5 4h8v2H9zm-5 4h14v2H4z" /><line x1="2" y1="2" x2="22" y2="22" stroke="currentColor" strokeWidth="2" strokeLinecap="round" /></svg>', // Custom "clear formatting" icon
};

ReactQuill.Quill.import('ui/icons').clean = customIcons.clean;

const ControlledQuill = ({ name, control, label, rules, defaultValue = '', ...rest }) => {


const fontSizeArr = ['8px','9px','10px','12px','14px','16px','20px','24px','32px','42px','54px','68px','84px','98px'];

var fontSizeStyle = ReactQuill.Quill.import('attributors/style/size');
fontSizeStyle.whitelist = fontSizeArr
// const Size = ReactQuill.Quill.import("attributors/style/size")



    // Register custom size options if needed
// const Size = ReactQuill.Quill.import('formats/size');
// Size.whitelist = ["extra-small", "small", "medium", "large"];
// const Font = ReactQuill.Quill.import('formats/font');


// Font.whitelist = [
//     "arial",
//     "comic-sans",
//     "courier-new",
//     "georgia",
//     "helvetica",
//     "lucida"
// ];

const FontAttributor = ReactQuill.Quill.import("attributors/class/font");
FontAttributor.whitelist = ["sans-serif","arial","comic-sans",'courier-new','georgia','helvetica','lucida',"playfair",
  "garamond",
  "merriweather",
  "montserrat",
  "raleway",
  "roboto",
  "lato",
  "poppins",
  "open-sans",
  "dancing-script",
  "great-vibes",
  "pacifico",
  "allura",
  "sacramento",
  "bebas-neue",
  "oswald",
  "anton",
  "fredoka-one",
  "baloo",
  "lobster",
  "creepster",
  "gloria-hallelujah",
  "mountains-of-christmas",
  "shadows-into-light"
];


// ReactQuill.Quill.register(Font, true);
// ReactQuill.Quill.register(Size, true);
ReactQuill.Quill.register(FontAttributor, true);
ReactQuill.Quill.register(fontSizeStyle, true);
    const modules = {
        toolbar: [
            [{font:FontAttributor.whitelist}],
            // [{ font: ["arial",
            // "comic-sans",
            // "courier-new",
            // "georgia",
            // "helvetica",
            // "lucida"] }], 
            [{ size: fontSizeArr  }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }], 
            [{ list: 'ordered' }, { list: 'bullet' }], 
            [{ align: [] }], 
            // ['link', 'image', 'video'], // Links and media
            ['clean'], // Remove formatting
        ],
    };

    const formats = [
        "font",
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'color',
        'background',
        'list',
        'bullet',
        'align',
        'link',
        // 'image',
        // 'video',
    ];


    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <FormControl fullWidth style={{ marginBottom: '24px' }} error={!!error}>
                    {label && <label style={{ marginBottom: '8px', display: 'block' }}>{label}</label>}
                    <div style={{ 
                        marginBottom: '24px'
                     }}>
                        <ReactQuill
                            value={value || ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            modules={modules}
                            formats={formats}
                            {...rest}
                        />
                    </div>
                    {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
            )}
        />
    );
};

export default ControlledQuill;