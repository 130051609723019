import React from 'react';
import './index.css';

const SuccessCard = ({message}) => {
  return (
    <div className='masterDiv'>
      <div className="card">
        <div style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: '0 auto' }}>
          <i className="checkmark">✓</i>
        </div>
        <h1 className='successHead'>Success</h1>
        {/* <p className='successdesc'>Show this QR code<br />at the registration counter <br /> to collect your badge</p> */}
        <div 
      dangerouslySetInnerHTML={{ __html: message }}
    />
      </div>
    </div>
  );
};

export default SuccessCard;
