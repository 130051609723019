import { defaultOnSiteRegistrationMessage, defaultRegistrationMessage } from '@/Constants/data';
import { TYPE_OPTIONS } from './createEventWizard.constants';
import type { FormData, Data } from './types';
import { replacePlaceholders } from '@/Constants/Utils';

export const getCreateEventPayload = (data: FormData) => {
  const updatePayload = data?.colors?.map((res) => {
    return { [res.key]: res?.value }
  })


  const updateFilter = updatePayload?.reduce((acc, obj) => {
      return { ...acc, ...obj };
  }, {});


  const colorsObj = {
    colors: updateFilter,
    "qr-color": {
      field: data?.qrColorField,
      colors: updateFilter
    }
}


  const email_config = {}
    email_config['sender_name'] = data?.emailSenderName;
    email_config['use_custom_email'] = data?.useCustomEmail;

    if(data?.useCustomEmail == true){
      email_config['email_identity'] = data?.customEmail;
      email_config['custom_mail_from_domain'] =  data?.mailFromDomain;
      email_config['domain_identity'] = data?.domainName;
    }


  if(data?.regConfirmationMessage === '<p><br></p>'){
    data.regConfirmationMessage = '';
  }

  if(data?.onSiteRegConfirmationMessage === '<p><br></p>'){
    data.onSiteRegConfirmationMessage = '';
  }


 return {
  ...colorsObj,
  num_params: 0,
  params: [],
  logo: data.logo,
  banner: data.bannerImage,
  send_email: data.sendEmail,
  send_whatsapp: data.sendWhatsapp,
  approvalRequired: data.approvalRequired,
  name: data.name,
  prefix: data.prefix,
  start_time: data.startDateTime,
  end_time: data.endDateTime,
  fields: data.fields,
  _additional_guests: data.additionalGuestsFields,
  additional_guests: data.additionalGuests,
  description: data.description,
  unique_identifier: data.uniqueIdentifier,
  qrFieldColor: data.qrColorField,
  qr_source: data.qrSource,
  qr_logic: data.prefixType,
  email_config:email_config,
  paymentRequired: data.paymentRequired,
  paymentUPI: data.paymentRequired ? data.paymentUPI : null,
  paymentAmount : data.paymentRequired ? data.paymentAmount : null,
  paymentQRImage : data.paymentRequired ? data.paymentQRImage : null,
  regConfirmationMessage: data.regConfirmationMessage,
  onSiteRegConfirmationMessage: data.onSiteRegConfirmationMessage,
 }
}

export const getFormDefaultValues = (payload: Data) => {
  const colors = payload?.colors;
  let finalColors: any = [];
  if(colors) {
    finalColors = Object.keys(colors).map((key: string) => {
      return {
          key: key,
          value: colors[key]
      };
    });
  }
  return {
    colors: finalColors,
    num_params: 0,
    params: [],
    logo: payload.logo,
    bannerImage: payload.banner,
    // sendEmail: payload.send_email,
    // sendWhatsapp: payload.send_whatsapp,
    sendEmail: true,
    sendWhatsapp: true,
    approvalRequired: payload.approvalRequired,
    name: payload.name,
    prefix: payload.prefix,
    startDateTime: payload.start_time,
    endDateTime: payload.end_time,
    fields: payload.fields,
    additionalGuestsFields: payload._additional_guests ,
    additionalGuests: payload.additional_guests,
    description: payload.description,
    uniqueIdentifier: payload.unique_identifier,
    qrColorField: payload.qrFieldColor,
    qrSource: payload.qr_source,
    prefixType: payload.qr_logic,
    emailSenderName: payload.email_config?.sender_name,
    customEmail: payload.email_config?.email_identity,
    useCustomEmail: payload.email_config?.use_custom_email,
    domainName: payload.email_config?.domain_identity,
    mailFromDomain: payload.email_config?.custom_mail_from_domain,
    paymentRequired: payload.paymentRequired,
    paymentUPI: payload.paymentUPI,
    paymentAmount: payload.paymentAmount,
    paymentQRImage: payload.paymentQRImage,
    regConfirmationMessage: payload.regConfirmationMessage ? payload.regConfirmationMessage :  defaultRegistrationMessage,
    onSiteRegConfirmationMessage: payload.onSiteRegConfirmationMessage ? payload.onSiteRegConfirmationMessage :  defaultOnSiteRegistrationMessage,
   }


}
