import Button from "@/Components/ui/Button";
import { STEPS } from './createEventWizard.constants';
import { getCreateEventPayload } from "./createEventWizard.helpers";
import { useUpdateEventsByidIsolatedMutation } from "@/Redux/Services/BaseQueryIsolated";
import { toast } from "react-toastify";

type StepsFooterProps = {
  stepNumber: number,
  handlePrev: Function,
  isUpdate: any,
  getValues: any,
  isValid: any,
  trigger: any,
  dirtyFields: any,
  updateEventDetails: Function,
};

const StepsFooter = ({stepNumber, handlePrev, isUpdate,getValues,isValid, trigger,dirtyFields,updateEventDetails}: StepsFooterProps) => {
  



  return (<div
    className={`${stepNumber > 0 ? "flex justify-between" : " text-right"
      } mt-10`}
  >
    {stepNumber !== 0 && (
      <Button
        text="prev"
        className="btn-dark"
        onClick={handlePrev} isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} div={undefined}                  />
    )}
    <div>
    {isUpdate && <Button
      text={"save"}
      className="btn-dark mr-2"
      type="button" isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} onClick={updateEventDetails} div={undefined} />}
    <Button
      text={stepNumber !== STEPS.length - 1 ? "next" : "submit"}
      className="btn-dark"
      type="submit" isLoading={undefined} disabled={undefined} children={undefined} icon={undefined} link={undefined} onClick={undefined} div={undefined}                />
    </div>
    
  </div>);
}
export default StepsFooter;