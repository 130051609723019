import { useEffect } from "react";
import { useGetOrderStatusQuery } from "../../Redux/Services/RegisterForm";
import { Card, Container, Paper } from "@mui/material";
import Success from "../../Assets/successPay.gif";
import "./RegisterForm.css"
import Failed from "../../Assets/failed.svg";
import { useNavigate } from "react-router-dom";

const CashFreeRedirect = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const order_id: string = queryParams.get("order_id") || "";
  const { data: orderStatus, error, isLoading } = useGetOrderStatusQuery(order_id);
  console.log("order_id", order_id);
  const navigate = useNavigate()

  useEffect(() => {
    console.log('orderStatus = ', orderStatus)

  }, [orderStatus])
  return (
    <div>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          {
            !isLoading && orderStatus?.order_status === "PAID" &&
              <div className="mx-auto">
                <img src={Success} className="successimage mx-auto d-block" />
                <p className="text-center successText">Payment Success</p>
                <p className="text-center successSubText">Congratulations! Your payment has been processed successfully.</p>
                <p className="text-center successTextOrder">
                  Your order is : {orderStatus?.order_status}
                </p>
                <button className="returnBtn" onClick={() => navigate("/home")}>Return to home</button>
              </div>
          }
           {
            !isLoading && orderStatus?.order_status !== "PAID" &&
              <div className="mx-auto">
                <img src={Failed} className="successimage mx-auto d-block" />
                <p className="text-center successText mt-4">Payment Failed</p>
                <p className="text-center successSubText mt-3">Please check your internet connection</p>
                <button className="returnBtn" onClick={() => navigate("/home")}>Return to home</button>
              </div>
          }
        </Paper>
      </Container>
    </div>
  )
};

export default CashFreeRedirect;
