import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { colors, hexToRGB } from "@/Constants/data";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const LineChart = () => {
  const data = {
    labels: [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    datasets: [
      {
        label: "Trend",
        data: [
          80, 150, 180, 270, 210, 160, 160, 202, 265, 210, 270, 255, 290
        ],
        fill: false,
        backgroundColor: hexToRGB(colors.primary, 1),
        borderColor: colors.primary,
        borderSkipped: "bottom",
        barThickness: 40,
        pointRadius: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 5,
        pointBorderColor: "transparent",
        lineTension: 0.5,
        pointStyle: "circle",
        pointShadowOffsetX: 1,
        pointShadowOffsetY: 1,
        pointShadowBlur: 5,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          color: false ? "#cbd5e1" : "#475569",
        },
      },
    },
    scales: {
      y: {
        stacked: true,
        grid: {
          color: false ? "#334155" : "#e2e8f0",
        },
        ticks: {
          color: false ? "#cbd5e1" : "#475569",
        },
      },
      x: {
        grid: {
          color: false ? "#334155" : "#e2e8f0",
        },

        ticks: {
          color: false ? "#cbd5e1" : "#475569",
        },
      },
    },
  };
  return (
    <div style={{ width: '200px' }}>
      <Line options={options} data={data} height={100} />
    </div>
  );
};

export default LineChart;
