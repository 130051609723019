import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Menu } from "@headlessui/react";
import { signOut } from 'firebase/auth';

import Dropdown from "@/Components/ui/Dropdown";
import Icon from "@/Components/ui/Icon";

import { logOut } from '@/Redux/Store';

import { auth } from '@/utils/firebase';

const profileLabel = (emailId: string) => {
  return (
    <div className="flex items-center">
      {/* <div className="flex-1 ltr:mr-[10px] rtl:ml-[10px]">
        <div className="lg:h-8 lg:w-8 h-7 w-7 rounded-full">
          {/* <img
            src={''}
            alt=""
            className="block w-full h-full object-cover rounded-full"
          />}
        </div>
      </div> */}
      <div className="flex-none text-slate-600 dark:text-white text-sm font-normal items-center md:flex hidden overflow-hidden text-ellipsis whitespace-nowrap">
        <span className="overflow-hidden text-ellipsis whitespace-nowrap block">
          {emailId}
        </span>
        <span className="text-base inline-block ltr:ml-[10px] rtl:mr-[10px]">
          <Icon icon="heroicons-outline:chevron-down" className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined}></Icon>
        </span>
      </div>
    </div>
  );
};

type ProfileProps = {
  emailId: string,
};
const Profile = ({emailId}: ProfileProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    localStorage.removeItem("event-register-app-is-auth");
    dispatch(logOut());
    await signOut(auth);
  }

  const ProfileMenu = [
    {
      label: "Logout",
      icon: "heroicons-outline:login",
      action: handleLogout,
    },
  ];

  return (
    <Dropdown label={profileLabel(emailId)} classMenuItems="w-[180px] top-[36px]">
      {ProfileMenu.map((item, index) => (
        <Menu.Item key={index}>
          {({ active }) => (
            <div
              onClick={() => item.action()}
              className={`${
                active
                  ? "bg-slate-100 text-slate-900 dark:bg-slate-600 dark:text-slate-300 dark:bg-opacity-50"
                  : "text-slate-600 dark:text-slate-300"
              } block`}
            >
              <div className={`block cursor-pointer px-4 py-2`}>
                <div className="flex items-center">
                  <span className="block text-xl ltr:mr-3 rtl:ml-3">
                    <Icon icon={item.icon} className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined} />
                  </span>
                  <span className="block text-sm">{item.label}</span>
                </div>
              </div>
            </div>
          )}
        </Menu.Item>
      ))}
    </Dropdown>
  );
};

export default Profile;
