import React from 'react'
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import GenerateFormEdit from '@/Components/Print2/GenerateFormEdit';

const Edit = () => {
  return (
    <div className="container-fluid bg-white">
            <DndProvider backend={HTML5Backend}>
                <GenerateFormEdit />
            </DndProvider>
    </div>
  )
}

export default Edit