import { Link } from "react-router-dom";

// import images
import Dummy from "@/Assets/dummy.png";

type SidebarProps = {
  menuHover: boolean,
}

const SidebarLogo = ({ menuHover }: SidebarProps) => {
  return (
    <div
      className={` logo-segment flex justify-between items-center bg-white dark:bg-slate-800 z-[9] py-6  px-4 
      ${menuHover ? "logo-hovered" : ""}
      border-none
      `}
    >
      <Link to="/home">
        <div className="flex items-center space-x-4">
          <div className="logo-icon">
            <img src={Dummy} alt="" className="logoImage" />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SidebarLogo;
