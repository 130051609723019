import React from "react";
import { Link } from "react-router-dom";
import LoginForm from "./LoginForm";
// image import
import bgImage from "@/Assets/images/page-bg.png";
import Dummy from "@/Assets/dummy.png";

const Login = () => {
  return (
    <div
      className="loginwrapper bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className="lg-inner-column">
        <div className="left-columns lg:w-1/2 lg:block hidden">
          <div className="logo-box-3">
            <Link to="/" className="">
              <img src={Dummy} alt="" className="logoImage" />
            </Link>
          </div>
        </div>
        <div className="lg:w-1/2 w-full flex flex-col items-center justify-center">
          <div className="auth-box-3">
            <div className="mobile-logo text-center mb-6 lg:hidden block">
              <Link to="/">
                <img
                  src={Dummy}
                  alt=""
                  className="mx-auto logoImage"
                />
              </Link>
            </div>
            <div className="text-center 2xl:mb-10 mb-5">
              <h4 className="font-medium">Sign In</h4>
            </div>
            <LoginForm />
            <div className="mx-auto font-normal text-slate-500 dark:text-slate-400 2xl:mt-12 mt-6 uppercase text-sm text-center">
              Don’t have an account?{" "}
              <Link
                to="/register"
                className="text-slate-900 dark:text-white font-medium hover:underline"
              >
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
