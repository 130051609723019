import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import type { FetchArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { toast } from "react-toastify";
import type { RootState } from "../Store";


const isolatedBaseQuery = fetchBaseQuery({
    baseUrl: "https://events.gaggle.online/api/v1",

    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state?.auth?.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });

  const queryWrapper = retry(
    async (args: string | FetchArgs, api, extraOptions) => {
      let result = await isolatedBaseQuery(args, api, extraOptions)
      if (result) {
        if (result?.error) {
          toast.error(`${result?.error?.status}`)
        }
      }
      return result
    },
    {
      maxRetries: 0,
    }
  );
export const baseQueryIsolated = createApi({
    reducerPath: "baseQueryIsolatedReducer",
    baseQuery: queryWrapper,
    keepUnusedDataFor:1,
    tagTypes: ["Events", "Users", "Template"],
    endpoints: (builder) => ({
      getEmailVerificationStatus: builder.query({
        query: (id) => ({
          url: `events/${id}/email`,
          method: "GET",
        }),
      }),
      registerEventsIsolated: builder.mutation({
        query: (payload) => ({
          url: `events/${payload.id}/registrations`,
          responseHandler: async (response) => {
            // Assuming the response is a blob (binary data)
            const blob = await response.blob();
            return blob;
          },
          method: "POST",
          body: payload.values
        }),
        invalidatesTags:  ['Events'],
      }),
      updateEventsByidIsolated: builder.mutation<any, any>({
        query: (updated) => ({
          url: `events/${updated?.id}`,
          method: "PUT",
          body: updated?.payload
        }),
        invalidatesTags:  ['Events']
      }),


      updateEventRegistrationByIdIsolated : builder.mutation<any, any>({
        query: (updated) => ({
          url: `events/${updated?.eventId}/registrations/${updated?.id}`,
          method: "PUT",
          body: updated?.payload
        }),
      }),




      createEventLabelIsolated: builder.mutation<any, any>({
        query: (payload) => ({
          url: `events/${payload?.eventId}/labels`,
          method: "POST",
          body: payload.data
        }),
      }),

      getAllMailers: builder.query({
        query: () => ({
          url: `events/mailers`,
          method: "GET"
        }),
      }),


      getEventLabelIsolated: builder.query<any, any>({
        query: (payload) => ({
          url: `events/${payload?.eventId}/labels/${payload?.labelId}`,
          method: "GET",
        }),
      }),

      postMailersIsolated: builder.mutation({
        query: (payload) => ({
          url: `events/${payload.id}/mailers`,
          method: "POST",
          body: payload.values
        }),
        invalidatesTags: ['Template']
      }),

      updateMailersIsolated: builder.mutation({
        query: (payload) => ({
          url: `events/${payload.id}/mailers`,
          method: "PUT",
          body: payload.values
        }),
      }),

      sendMailerIsolated : builder.mutation({
        query: (payload) => ({
          url: `mailers/${payload.id}/send`,
          method: "POST",
          body: payload.values
        }),
      }),


      exportRegisteredUsersDetail : builder.mutation({
        query: (payload) => ({
          url: `events/${payload.id}/export`,
          method: "POST",
          body: payload.values,
          // responseHandler: async (response) => {
          //   // Assuming the response is a blob (binary data)
          //   const blob = await response.blob();
          //   return blob;
          // },
        }),
      }),

      



    }),
  });


  export const { useGetEmailVerificationStatusQuery ,useUpdateEventsByidIsolatedMutation , useRegisterEventsIsolatedMutation, useUpdateEventRegistrationByIdIsolatedMutation , useCreateEventLabelIsolatedMutation, useGetEventLabelIsolatedQuery, useGetAllMailersQuery, usePostMailersIsolatedMutation, useUpdateMailersIsolatedMutation, useSendMailerIsolatedMutation, useExportRegisteredUsersDetailMutation } = baseQueryIsolated;