import { ErrorMessage } from 'formik';
import React, { useEffect, useRef } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import EditorToolbar, { modules, formats } from './EditorToolbar';
import SmartFieldModule from './SmartFieldModule';
import { Quill } from "react-quill";
(window as any).Quill = Quill;

Quill.register('modules/smartField', SmartFieldModule);


export const EmailEditor = ({ name, value ,onChange , fields}) => {

    console.log('OPTSMARTEmailEditor ', fields)

    const quillRef = useRef<any>(null);

 

    useEffect(() => {
        // Ensure SmartFieldModule is initialized whenever smartFields prop changes
        if (quillRef.current && fields) {
            const quill = quillRef.current.getEditor();
            const smartFieldModule = quill.getModule('smartField');
            if (smartFieldModule) {
                smartFieldModule.options.fields = fields;
            }
        }
    }, [fields]);

    function handleOnChange(content){
        onChange(content)
    }
    
    // const fields = [
    //     { label: 'Full Name', value: '{{full name}}' },
    //     { label: 'Email Address', value: '{{email}}' },
    //     { label: 'Phone Number', value: '{{phone}}' },
    //   ];


  return (
    <>
    <EditorToolbar editorRef={quillRef} fields = {fields} />
    <ReactQuill  ref={quillRef} theme='snow' value={value} onChange = {handleOnChange} modules= {modules} formats={formats}/>
    <ErrorMessage name = {name} component="div" className="error-msg"/>
    </>
  )
}
