import React, { useEffect, useRef, useState } from "react"
import "./userRegister.css";
import { ErrorMessage, FieldArray, Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { Button, Container, FormControl, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { useGetOneEventQuery, useRegisterEventsMutation } from "../../Redux/Services/userRegister";
import { useParams } from "react-router-dom";
import SuccessCard from "../../Components/userRegister/success";
import { toast } from "react-toastify";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import DeleteIcon from '@mui/icons-material/Delete';
import { useRegisterEventsIsolatedMutation } from "@/Redux/Services/BaseQueryIsolated";
import Fileinput from "@/Components/ui/Fileinput";
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";
import { storage } from "@/utils/firebase";
import { replacePlaceholders } from "@/Constants/Utils";

const UserRegister = () => {

    // const [register] = useRegisterEventsMutation();
    const [register] = useRegisterEventsIsolatedMutation();
    const pharams = useParams()?.id;
    const { data: data, error, isLoading } = useGetOneEventQuery(pharams)
    const [image, setImage] = useState("")

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState<string>("");
    const [onRegistrationSuccessMessage, setOnRegistrationMessageSuccess] = useState('')


    const [submitted, setSubmitted] = useState(false);

    const getInitialValues = (data: any) => {

        const initialValues: any = {};

        data?.fields?.forEach((res: any) => {
            initialValues[res?.label] = ''
        })
        initialValues["additional_guests"] = []
        // initialValues['mobile_number'] = ""
        return initialValues
    }






    useEffect(() => {
        const today = new Date();
        const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        if(data?.start_time){
            const receivedDate = new Date(data?.start_time);
            const receivedDateWithoutTime = new Date(receivedDate.getFullYear(), receivedDate.getMonth(), receivedDate.getDate());
            console.log("receivedDateWithoutTime",receivedDateWithoutTime)
            console.log("todayDate",todayDate)
            if (todayDate.getTime() === receivedDateWithoutTime.getTime()) {
                setOnRegistrationMessageSuccess(replacePlaceholders(data?.onSiteRegConfirmationMessage,{"Event Name" : data?.name}))
            } else {
                setOnRegistrationMessageSuccess(replacePlaceholders(data?.regConfirmationMessage,{"Event Name" : data?.name} ))
            }
        }
    }, [data])


    const uploader = (file: File, onComplete: (url: string) => void) => {
        const storageRef = ref(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Handle upload progress if needed
            },
            (error) => {
                alert(error.message);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    onComplete(downloadURL);
                });
            }
        );
    };

    const generateValidationSchema = (data: any) => {

        const validationSchema: any = {};

        data?.fields?.forEach((res: any) => {
            if (res?.mandatory) {
                validationSchema[res?.label] =
                    res?.type === 'whatsapp' ?
                        Yup.string().required(`${res?.label} is required`).min(7, `${res?.label} minimum 7 numbers`).max(15, `${res?.label} maximum 15 numbers`)
                        : res?.type === 'email' ?
                            Yup.string().required(`${res?.label} is required`).email(`Valid ${res?.label} is required`)
                            : Yup.string().required(`${res?.label} is required`)
            }
        })


        //Validation pending for payment fields 




        // validationSchema['mobile_number'] = Yup.string().required(`Mobile no is required`)
        return Yup.object(validationSchema);
    }

    const handleSubmitForm = (values: any) => {
        // values.event_id=pharams
        let payload = {
            values: values,
            id: pharams
        }

        register(payload)
            .unwrap()
            .then((res: any) => {
                setSubmitted(true)
                const url = URL.createObjectURL(res);

                // Create a temporary <a> element to initiate the download
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                document.body.appendChild(a);
                setImage(url)
                // Simulate click to trigger the download
                // a.click();
            })
            .catch((error: any) => {
                console.log("errrrrrrrrrr", error);
                toast.error(error?.data?.error)
            })
    }
    return (
        <div className="userRegister">
            <div className="row" >
                <div className="">
                    {
                        submitted ?
                            <>
                                {
                                    image &&
                                    <img src={image} alt="" className="qrcodeImg mt-4" />
                                }
                                <SuccessCard message={onRegistrationSuccessMessage} />
                            </>
                            :
                            <div className="formRightsidecon">
                                <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 2 } }}>
                                        {data?.banner && <div className="d-flex banner-image" style={{ backgroundImage: `url(${data?.banner})` }}>
                                        </div>}
                                        {/* {data?.logo && <img src={data?.logo} alt="logo" className="logo" />} */}
                                        {<p className="appName">{data?.name}</p>}
                                        <div className="ql-container">

                                        {<div id= "appDescr" className="appDescription ql-editor" dangerouslySetInnerHTML={{ __html: data?.description }}></div>}
                                        </div>

                                        <p className="text-center mt-3">Register</p>
                                        <Formik
                                            initialValues={getInitialValues(data)}
                                            validationSchema={generateValidationSchema(data)}
                                            onSubmit={(values: any, { resetForm }) => {
                                                handleSubmitForm(values)
                                            }}
                                            enableReinitialize
                                        >
                                            {({
                                                handleBlur,
                                                handleChange,
                                                values,
                                                errors,
                                                setFieldError,
                                                setFieldValue,
                                                touched
                                            }) => (
                                                <Form className="mt-3">
                                                    <>
                                                        {
                                                            console.log("values", values)
                                                        }
                                                        {
                                                            console.log("errorrrs", errors)

                                                        }
                                                    </>
                                                    {
                                                        data?.fields?.map((res: any, index: number) => {

                                                            if (res.type === 'whatsapp') {
                                                                return (
                                                                    <>
                                                                        <PhoneInput
                                                                            defaultCountry="IN"
                                                                            placeholder="Enter phone number"
                                                                            value={values[res?.label]}
                                                                            inputClass=""
                                                                            inputStyle={{
                                                                                border: "1 px solid black"
                                                                            }}
                                                                            onChange={(value: any) => {
                                                                                if (value) {

                                                                                    console.log("phone valid- ",isValidPhoneNumber(value))

                                                                                    if (isValidPhoneNumber(value)) {
                                                                                        setFieldValue(res?.label, value)
                                                                                        setFieldError(res?.label, '')

                                                                                    } else {
                                                                                        setFieldError(res?.label, `InValid ${res?.label}`)
                                                                                    }
                                                                                }
                                                                            }} />
                                                                        <ErrorMessage name={res?.label} component="span" className="error-msg" />
                                                                    </>
                                                                )
                                                            } if (res.type === 'dropdown') {
                                                                console.log("res", res);

                                                                return (
                                                                    <>

                                                                        <FormControl fullWidth
                                                                            style={{ marginTop: "17px" }}

                                                                        >
                                                                            <InputLabel id="demo-simple-select-label"><p>{res?.label}<span style={{ fontSize: "16px", color: "red" }}>{`${res?.mandatory ? '*' : ' '}`}</span></p> </InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                name={res?.label}
                                                                                value={values[res?.label]}
                                                                                onChange={handleChange}
                                                                                label={<p>{res?.label}<span style={{ fontSize: "16px", color: "red" }}>{`${res?.mandatory ? '*' : ' '}`}</span></p>}
                                                                            >
                                                                                {
                                                                                    res?.dropDownValue?.split(",")?.map((field: any, index: number) => <MenuItem key={index} value={field}>{field}</MenuItem>)
                                                                                }

                                                                            </Select>
                                                                        </FormControl>

                                                                        <ErrorMessage name={res?.label} component="span" className="error-msg" />
                                                                    </>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <div key={index} className="mt-3">
                                                                        <TextField
                                                                            label={<p>{res?.label}<span style={{ fontSize: "16px", color: "red" }}>{`${res?.mandatory ? '*' : ' '}`}</span></p>}
                                                                            placeholder={`Enter your ${res?.label}`}
                                                                            fullWidth
                                                                            type={res?.type === 'whatsapp' ? 'number' : res?.type}
                                                                            name={res?.label}
                                                                            value={values[res?.label]}
                                                                            onChange={handleChange}
                                                                            className="createrenderInput"
                                                                            autoComplete="off"
                                                                            InputLabelProps={{
                                                                                shrink: true
                                                                            }}
                                                                        />
                                                                        <ErrorMessage name={res?.label} component="span" className="error-msg" />


                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }

                                                    {/* Payment Section  if payment required  start*/}
                                                    {data?.paymentRequired && (
                                                        <div>

                                                            <div key={"paymentUPI"} className="mt-3">
                                                                <TextField
                                                                    label={<p>{"Payment UPI"}<span style={{ fontSize: "16px", color: "red" }}>{`${' '}`}</span></p>}
                                                                    // placeholder={`Enter your ${"Payment UPI"}`}
                                                                    fullWidth
                                                                    type={"text"}
                                                                    name={"paymentUPI"}
                                                                    value={data?.paymentUPI}
                                                                    onChange={handleChange}
                                                                    className="createrenderInput"
                                                                    autoComplete="off"
                                                                    disabled
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />
                                                            </div>

                                                            <div key={"paymentAmount"} className="mt-3">
                                                                <TextField
                                                                    label={<p>{"Payment Amount"}<span style={{ fontSize: "16px", color: "red" }}>{`${' '}`}</span></p>}
                                                                    // placeholder={`Enter your ${"Payment UPI"}`}
                                                                    fullWidth
                                                                    type={"number"}
                                                                    name={"paymentAmount"}
                                                                    value={data?.paymentAmount}
                                                                    onChange={handleChange}
                                                                    disabled
                                                                    className="createrenderInput"
                                                                    autoComplete="off"
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />
                                                            </div>
                                                            {/* Image source change to payment from event */}
                                                            {data?.paymentQRImage && (
                                                                <Grid
                                                                    container
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    style={{ height: "100%" }}
                                                                >
                                                                    <img src={data?.paymentQRImage} alt="payment QR Code" className="qrcodeImg" />
                                                                </Grid>
                                                            )}

                                                            <input
                                                                accept="image/jpeg, image/png, image/jpg"
                                                                type="file"
                                                                style={{ display: "none" }}
                                                                ref={fileInputRef}
                                                                onChange={(e) => {
                                                                    const file = e.target.files?.[0];
                                                                    if (file) {
                                                                        setFileName(file.name);
                                                                        uploader(file, (downloadURL) => {
                                                                            setFieldValue("paidScreenshot", downloadURL);
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                            {/* Display file name and upload button */}
                                                            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() => fileInputRef.current?.click()}
                                                                >
                                                                    Upload Payment Img
                                                                </Button>
                                                                {fileName && (
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        {fileName}
                                                                    </Typography>
                                                                )}
                                                                <input
                                                                    type="hidden"
                                                                    name="paidScreenshot"
                                                                    value={values.paidScreenshot || ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {/* Payment Section  if payment required  end*/}
                                                    {data?.additional_guests > 0 && <p className="mt-2">Additional Guests</p>}
                                                    

                                                    <FieldArray name="additional_guests">
                                                        {({ push, remove }) => {
                                                            return (
                                                                <div className="mt-7 myName">
                                                                    {values?.additional_guests?.map((guest: any, index: number) => (
                                                                        <Grid
                                                                            container
                                                                            spacing={0}
                                                                            className="align-items-center border p-2 mt-4 relative"
                                                                            key={index}
                                                                        >
                                                                            <Button
                                                                                onClick={() => remove(index)}
                                                                                className="deleteBtnPosition"
                                                                            >
                                                                                <DeleteIcon />
                                                                            </Button>

                                                                            {/* Iterate through _additional_fields */}
                                                                            {data?._additional_guests?.map((field: any, fieldIndex: number) => {
                                                                                if (field.type === 'whatsapp') {
                                                                                    return (
                                                                                        <Grid item xs={12} key={fieldIndex} className="pt-2">
                                                                                            <PhoneInput
                                                                                                defaultCountry="IN"
                                                                                                placeholder="Enter phone number"
                                                                                                value={guest[field.label]}
                                                                                                inputStyle={{ border: "1px solid black" }}
                                                                                                onChange={(value: any) => {
                                                                                                    if (value) {
                                                                                                        if (isValidPhoneNumber(value)) {
                                                                                                            setFieldValue(
                                                                                                                `additional_guests[${index}][${field.label}]`,
                                                                                                                value
                                                                                                            );
                                                                                                            setFieldError(
                                                                                                                `additional_guests[${index}][${field.label}]`,
                                                                                                                ''
                                                                                                            );
                                                                                                        } else {
                                                                                                            setFieldError(
                                                                                                                `additional_guests[${index}][${field.label}]`,
                                                                                                                `Invalid ${field.label}`
                                                                                                            );
                                                                                                        }
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                            <ErrorMessage
                                                                                                name={`additional_guests[${index}][${field.label}]`}
                                                                                                component="span"
                                                                                                className="error-msg"
                                                                                            />
                                                                                        </Grid>
                                                                                    );
                                                                                }

                                                                                else if (field.type === 'dropdown') {
                                                                                    return (
                                                                                        <Grid item xs={12} key={fieldIndex} className="pt-2">
                                                                                            <FormControl fullWidth style={{ marginTop: "17px" }}>
                                                                                                <InputLabel>
                                                                                                    <p>
                                                                                                        {field.label}
                                                                                                        <span style={{ fontSize: "16px", color: "red" }}>
                                                                                                            {field.mandatory ? '*' : ''}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </InputLabel>
                                                                                                <Select
                                                                                                    name={`additional_guests[${index}][${field.label}]`}
                                                                                                    value={guest[field.label]}
                                                                                                    onChange={handleChange}
                                                                                                    label={
                                                                                                        <p>
                                                                                                            {field.label}
                                                                                                            <span style={{ fontSize: "16px", color: "red" }}>
                                                                                                                {field.mandatory ? '*' : ''}
                                                                                                            </span>
                                                                                                        </p>
                                                                                                    }
                                                                                                >
                                                                                                    {field.dropDownValue
                                                                                                        ?.split(',')
                                                                                                        ?.map((option: string, optionIndex: number) => (
                                                                                                            <MenuItem key={optionIndex} value={option}>
                                                                                                                {option}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                </Select>
                                                                                            </FormControl>
                                                                                            <ErrorMessage
                                                                                                name={`additional_guests[${index}][${field.label}]`}
                                                                                                component="span"
                                                                                                className="error-msg"
                                                                                            />
                                                                                        </Grid>
                                                                                    );
                                                                                }


                                                                                //         return (
                                                                                //             <TextField
                                                                                //     label={<p>{res?.label}<span style={{ fontSize: "16px", color: "red" }}>{`${res?.mandatory ? '*' : ' '}`}</span></p>}
                                                                                //     placeholder={`Enter your ${res?.label}`}
                                                                                //     fullWidth
                                                                                //     type={res?.type === 'whatsapp' ? 'number' : res?.type}
                                                                                //     name={res?.label}
                                                                                //     value={values[res?.label]}
                                                                                //     onChange={handleChange}
                                                                                //     className="createrenderInput"
                                                                                //     autoComplete="off"
                                                                                // />
                                                                                //         )


                                                                                return (
                                                                                    <Grid item xs={12} className="pt-2" key={fieldIndex}>
                                                                                        <TextField
                                                                                            label={
                                                                                                <p>
                                                                                                    {field.label}
                                                                                                    <span style={{ fontSize: "16px", color: "red" }}>
                                                                                                        {field.mandatory ? '*' : ''}
                                                                                                    </span>
                                                                                                </p>
                                                                                            }
                                                                                            placeholder={`Enter ${field.label}`}
                                                                                            fullWidth
                                                                                            name={`additional_guests[${index}][${field.label}]`}
                                                                                            value={guest[field.label]}
                                                                                            type={field?.type === 'whatsapp' ? 'number' : field?.type}
                                                                                            onChange={handleChange}
                                                                                            autoComplete="off"
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            name={`additional_guests[${index}][${field.label}]`}
                                                                                            component="span"
                                                                                            className="error-msg"
                                                                                        />
                                                                                    </Grid>
                                                                                );
                                                                            })}
                                                                        </Grid>
                                                                    ))}
                                                                    {
                                                                        data?.additional_guests > 0 && data?.additional_guests > values?.additional_guests?.length &&
                                                                        <Button
                                                                            type="button"
                                                                            className="mt-2"
                                                                            variant="outlined"
                                                                            onClick={() =>
                                                                                push({
                                                                                    // first_name: '',
                                                                                    // last_name: '',
                                                                                    // Dynamically initialize fields based on _additional_fields
                                                                                    ...Object.fromEntries(
                                                                                        data?._additional_fields?.map((field: any) => [field.label, '']) || []
                                                                                    ),
                                                                                })
                                                                            }
                                                                        >
                                                                            Add Guest
                                                                        </Button>
                                                                    }

                                                                </div>
                                                            )
                                                        }


                                                        }
                                                    </FieldArray>

                                                    <Grid
                                                        container
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        spacing={2} // Adds spacing between Grid items
                                                    >
                                                        <Grid item>
                                                            <Button
                                                                type="reset"
                                                                fullWidth
                                                                variant="contained"
                                                                sx={{ mt: 3, mb: 2 }}
                                                            >
                                                                Reset
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                type="submit"
                                                                fullWidth
                                                                variant="contained"
                                                                sx={{ mt: 3, mb: 2 }}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    {data?.logo && (
                                                        <Grid
                                                            container
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            style={{ height: "100%" }}
                                                        >
                                                            <img src={data?.logo} alt="logo" className="logo" />
                                                        </Grid>
                                                    )}

                                                </Form>
                                            )}
                                        </Formik>

                                    </Paper>
                                </Container>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default UserRegister;
