
import { Link } from 'react-router-dom';

import Dummy from '@/Assets/dummy.png';
import Icon from "@/Components/ui/Icon";
import useWidth from "@/hooks/useWidth";
import useMobileMenu from "@/hooks/useMobileMenu";
import Profile from "./Tools/Profile";

type HeaderProps = {
  className: string,
  emailId: string,
};

const Header = ({ className = "custom-class", emailId }: HeaderProps) => {
  const { width, breakpoints } = useWidth();
  const [mobileMenu, setMobileMenu] = useMobileMenu(); // required

  const handleOpenMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };
  return (
    <header className={className + " relative sticky top-0 z-[999]"}>
      <div
        className={` app-header md:px-6 px-[15px]  dark:bg-slate-800 shadow-base dark:shadow-base3 bg-white
         dark:border-b dark:border-slate-700 dark:border-opacity-60 md:py-6 py-3
        `}
      >
        <div className="flex justify-between items-center h-full">
            <div className="flex items-center md:space-x-4 space-x-2 rtl:space-x-reverse">
              {width <= breakpoints.xl && <div>
                <Link to="/home">
                  <img src={Dummy} alt="" className="logoImage" />
                </Link>
              </div>}
              {/* open mobile menu handlaer*/}
              {width <= breakpoints.xl && width > breakpoints.md && (
                <div
                  className="cursor-pointer text-slate-900 dark:text-white text-2xl"
                  onClick={handleOpenMobileMenu}
                >
                  <Icon icon="heroicons-outline:menu-alt-3" className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined} />
                </div>
              )}
            </div>
          {/* Nav Tools  */}
          <div className="nav-tools flex items-center lg:space-x-6 space-x-3 rtl:space-x-reverse">
            {width >= breakpoints.md && (
              <Profile emailId={emailId}/>
            )}
            {width <= breakpoints.md && (
              <div
                className="cursor-pointer text-slate-900 dark:text-white text-2xl"
                onClick={handleOpenMobileMenu}
              >
                <Icon icon="heroicons-outline:menu-alt-3" className={undefined} width={undefined} rotate={undefined} hFlip={undefined} vFlip={undefined} />
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
